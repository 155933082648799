import { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Stack, Typography } from '@mui/material';
import { CustomSelect } from 'components/CustomSelect/CustomSelect';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import { CustomRolesDetailFormContext } from '../context';
import { DescriptionBox, HeaderContent, MainCnt } from './style';
import useCustomRoles from 'utils/CustomHooks/useCustomRoles';
import { getRoles } from 'store/selectors/roles';

const CustomRolesDetails = (props) => {
  const { isEdit } = props;
  const roles = useSelector(getRoles);
  const { list: rolesList } = roles;
  const { fetchCustomRoles } = useCustomRoles();

  const { state, updateState } = useContext(CustomRolesDetailFormContext);

  const { details } = state;

  const [titleErr, setTitleErr] = useState('');

  useEffect(() => {
    fetchCustomRoles({});
  }, []);

  const rolesDropdownData = useMemo(() => {
    return rolesList?.map((role) => {
      return {
        label: role?.title,
        value: role?.id,
      };
    });
  }, [rolesList]);

  const titleChangeHandler = (event) => {
    if (event?.target?.value?.length > 50) {
      setTitleErr('You cannot enter more than 50 characters in title');
      return;
    }
    setTitleErr('');
    updateState({
      details: {
        ...details,
        title: event?.target?.value,
      },
    });
  };

  const reportToChangeHandler = (event) => {
    updateState({
      details: {
        ...details,
        reportTo: event?.value,
      },
    });
  };

  const descriptionChangeHandler = (event) => {
    updateState({
      details: {
        ...details,
        description: event?.target?.value,
      },
    });
  };

  const selectedRole = useMemo(() => {
    return rolesDropdownData?.find(
      (option) => option?.value === details?.reportTo,
    );
  }, [details?.reportTo]);

  return (
    <MainCnt>
      <HeaderContent>
        <Typography className="top">
          {isEdit ? 'Step 1 of 2' : 'Step 1 of 3'}
        </Typography>
        <Typography className="bottom">Role Details</Typography>
      </HeaderContent>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Stack
            flexDirection="column"
            gap="8px"
            sx={{
              '& input': {
                fontWeight: 500,
                color: '#000000',
                fontSize: '14px !important',
                '&::placeholder': {
                  lineHeight: '19.12px',
                  color: '#616161',
                  fontWeight: 400,
                  fontSize: 14,
                  opacity: 1,
                },
              },
            }}
          >
            <Typography className="title">Role Title</Typography>
            <CustomInput
              variant="outlined"
              muiLabel={false}
              style={{ marginBottom: 0 }}
              error={titleErr}
              fieldProps={{
                name: 'roleTitle',
                placeholder: 'Enter role title',
                value: details?.title,
                onChange: titleChangeHandler,
                onBlur: () => {
                  setTitleErr('');
                },
                style: {
                  height: 44,
                  borderRadius: 6,
                  backgroundColor: 'white',
                },
              }}
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack
            flexDirection="column"
            gap="8px"
            sx={{
              '& .select-box__control--is-focused': {
                border: '2px solid rgba(104, 104, 254, 1) !important',
              },
              '& .select-box__control': {
                border: '2px solid #0000001f',
                borderRadius: '6px',
                height: 44,
                '&:hover': { border: '2px solid rgb(0 0 0 / 32%)' },
              },
              '& label': {
                fontWeight: 500,
                color: '#000000 !important',
                fontSize: '14px !important',
              },
            }}
          >
            <Typography className="title">Report To</Typography>
            <CustomSelect
              style={{
                marginBottom: 0,
              }}
              placeholder="Select Role"
              isSearchable={true}
              isMulti={false}
              closeMenuOnSelect={false}
              selectedOption={selectedRole}
              options={rolesDropdownData}
              onChange={reportToChangeHandler}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Typography className="title">Description</Typography>
          <DescriptionBox>
            <CustomInput
              muiLabel={false}
              fieldProps={{
                multiline: true,
                maxRows: 3,
                minRows: 3,
                placeholder: 'Enter role description',
                value: details?.description,
                onChange: descriptionChangeHandler,
                style: {
                  borderRadius: 6,
                  backgroundColor: 'white',
                },
              }}
            />
          </DescriptionBox>
        </Grid>
      </Grid>
    </MainCnt>
  );
};

export default CustomRolesDetails;
