// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

export const NotesAndSectionWrapper = styled(Box, {
  shouldForwardProp: (prop) =>
    !['borderonhover', 'procedure', 'error'].includes(prop),
})(({ borderonhover, procedure, error }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: error && !procedure ? '1px solid rgba(239, 83, 80, 1)' : 'none',
  borderRadius: 8,

  '&:hover': {
    ...(borderonhover &&
      !error && {
        border: `1px solid ${
          error && !procedure ? 'rgba(239, 83, 80, 1)' : '#4E48FA'
        }`,
        ...(!procedure && {
          '& .section-wrapper': {
            padding: '7px 11px',
          },
        }),
        '& .notes-wrapper': {
          padding: '8px 11px 7px 11px',
        },
        '& .procedure-item-type': {
          padding: '9px',
        },
        '& .corrective-tasks': {
          margin: '8px 15px',
        },
      }),

    '& div.completed-info': {
      display: 'none',
    },
    '& div.corrective-task': {
      display: 'flex',
    },
  },
}));

export const SectionWrapper = styled(Box, {
  shouldForwardProp: (prop) => !['rounded', 'border'].includes(prop),
})(({ rounded, border }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: rounded ? '6px' : '6px 6px 0px 0px',
  gap: 8,
  ...(border && {
    padding: '8px 12px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
  }),
}));

export const AttachmentsWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  marginTop: 8,
}));

export const AttachmentsText = styled(Typography)(({ theme }) => ({
  fontSize: '11px',
  fontWeight: '700',
  lineHeight: '14px',
  color: 'rgba(37, 35, 80, 1)',
}));

export const SectionName = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  fontWeight: '600',
  lineHeight: '32px',
  letterSpacing: '0.15px',
  color: 'rgba(0, 0, 0, 0.87)',
}));

export const NotesWrapper = styled(Box)(() => ({
  padding: '8px 12px',
  borderRadius: '0px 0px 6px 6px',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  borderTop: 'none',
}));
