import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { STEPS } from 'components/AllDialogs/CustomRoles/detailForm/constants';
import { groupBy, orderBy, reduce } from 'lodash';
import { setPermissions } from 'store/actions/permissionsActions';
import { setRoles } from 'store/actions/rolesActions';
import { getPermissionsForRoles } from 'store/selectors/permissions';
import { getRoles } from 'store/selectors/roles';
import { PERMISSIONS, PERMISSIONS_ENTITY_KEYWORDS } from 'utils/constants';
import {
  createCustomRoleCall,
  deleteCustomRoleCall,
  deleteUserFromCustomRoleCall,
  getCustomRoleDetailsCall,
  getCustomRolePermissionsCall,
  getCustomRolesCall,
  updateCustomRoleCall,
  updateUsersInCustomRoleCall,
} from 'api/customRoles';

const useCustomRoles = () => {
  const dispatch = useDispatch();
  const permissions = useSelector(getPermissionsForRoles);
  const roles = useSelector(getRoles);

  // Custom Roles Operations
  const getCustomRoles = async (data) => {
    const { payload, forceLoad = false } = data;
    if (forceLoad || !roles?.initialLoad) {
      const response = await getCustomRolesCall(payload);

      let sortedArray: any = [];
      if (response?.data?.length > 0) {
        sortedArray = orderBy(
          response?.data,
          [
            function (o) {
              return new Date(o.createdAt);
            },
          ],
          ['desc'],
        );
      }

      dispatch(setRoles(sortedArray ?? []));
    }
  };

  const updateCustomRoles = async (data) => {
    const { payload, roleId } = data;
    const response = await updateCustomRoleCall(payload, roleId);
    return response;
  };

  const deleteCustomRole = async (data) => {
    const { payload, roleId } = data;
    const response = await deleteCustomRoleCall(payload, roleId);
    return response;
  };

  const deleteUserFromCustomRole = async (data) => {
    const { payload, userId } = data;
    const response = await deleteUserFromCustomRoleCall(payload, userId);
    return response;
  };

  const getCustomRoleDetails = async (data) => {
    const { payload = {}, roleId } = data;
    const response = await getCustomRoleDetailsCall(payload, roleId);
    return response?.data;
  };

  const setUsersinCustomRole = async (data) => {
    const { payload = {}, roleId } = data;
    const response = await updateUsersInCustomRoleCall(payload, roleId);
    return response?.data;
  };

  // Permissions Operations
  const getPermissions = async (payload = {}) => {
    if (!permissions?.initialLoad) {
      const response = await getCustomRolePermissionsCall(payload);

      const permissionsOrder = Object.keys(PERMISSIONS).reduce(
        (acc, key, index) => {
          acc[PERMISSIONS[key]] = index;
          return acc;
        },
        {},
      );
      const sortedArray = orderBy(
        response?.data,
        [(item) => permissionsOrder?.[item?.title]],
        ['asc'],
      );

      const groupedPermissions = groupBy(sortedArray || [], 'group');
      const PERMISSIONS_LIST = reduce(
        PERMISSIONS_ENTITY_KEYWORDS,
        (result, _, key) => {
          result[key] = groupedPermissions[key] || [];
          return result;
        },
        {},
      );

      dispatch(setPermissions(PERMISSIONS_LIST));
    }
  };

  const createCustomRole = async (payload = {}) => {
    const response = await createCustomRoleCall(payload);
    return response;
  };

  const transformDetailsIntoFormData = (data) => {
    const permissions =
      data?.RolePermissions?.map((permission) => permission?.PermissionId) ??
      [];

    return {
      permissions,
      assignees: [],
      details: {
        title: data?.title || '',
        reportTo: data?.ReportTo,
        description: data?.description || '',
      },
      step: STEPS[0],
    };
  };

  const getRolesQuery = useMutation(getCustomRoles);
  const getRoleDetailsQuery = useMutation(getCustomRoleDetails);

  const fetchCustomRoles = (payload = {}) => {
    getRolesQuery.mutate(payload);
  };

  const fetchCustomRoleDetails = (payload = {}, roleId, onSuccessCB) => {
    getRoleDetailsQuery.mutate(
      {
        payload,
        roleId,
      },
      {
        onSuccess: onSuccessCB,
      },
    );
  };

  return {
    createCustomRole,
    getCustomRoles,
    updateCustomRoles,
    deleteCustomRole,
    setUsersinCustomRole,
    deleteUserFromCustomRole,
    getCustomRoleDetails,
    getPermissions,
    transformDetailsIntoFormData,
    fetchCustomRoles,
    fetchCustomRoleDetails,
  };
};

export default useCustomRoles;
