import { FC, useEffect, useMemo, useState, useCallback } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CrossIcon from 'components/Icons/crossIcon';
import CustomListItem from 'components/List/ListItem/ListItem';
import AddIcon from 'components/Icons/addIcon';
import LocationsGroupsList from './items';
import {
  CustomIconButton,
  MainCnt,
  SearchInputCnt,
  StyledSearchField,
} from './style';
import { setDialog } from 'store/actions/dialogActions';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { useDispatch } from 'react-redux';

// Define the structure of contentProps
interface ContentProps {
  isSearch?: boolean;
  style?: React.CSSProperties;
}

// Define the structure of the props for the container
interface IProps {
  data: any;
  selected: string[];
  changeHandler: (currentSelected: string, selectedList: string[]) => void;
  contentProps?: ContentProps;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  footerRenderer?: React.ReactNode;
  hideFooter?: boolean;
}

// Default Props
const defaultProps: ContentProps = {
  isSearch: true,
};

// Location Items container
const LocationsGroupsContainer: FC<IProps> = ({
  data = [],
  selected,
  changeHandler,
  contentProps = {},
  containerProps = {},
  footerRenderer,
  hideFooter = false,
}) => {
  const customProps = {
    ...defaultProps,
    ...contentProps,
  };
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedLocationsGroups, setSelectedLocationsGroups] = useState<
    string[]
  >([]);

  useEffect(() => {
    setSelectedLocationsGroups(selected);
  }, [selected]);

  const transformedData = useMemo(() => {
    return searchQuery
      ? data?.filter((group) =>
          group?.name?.toLowerCase().includes(searchQuery.toLowerCase()),
        )
      : data ?? [];
  }, [data, searchQuery]);

  const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  }, []);

  const selectAllHandler = useCallback(
    (isChecked: boolean) => {
      const updatedArray = isChecked
        ? transformedData.map((item) => item.id)
        : [];
      changeHandler('', updatedArray);
    },
    [transformedData, changeHandler],
  );

  const changeHandlerCallback = useCallback(
    (isChecked, selectedId) => {
      const updatedArray = isChecked
        ? [selectedId]
        : selectedLocationsGroups.filter((id) => id !== selectedId);
      changeHandler(selectedId, updatedArray);
    },
    [selectedLocationsGroups, changeHandler],
  );

  const isAllSelected = useMemo(() => {
    return transformedData?.every((item) => selected.includes(item.id));
  }, [selected, transformedData]);

  const createLocationGroup = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: 'createEditTask',
      }),
    );
    navigateWithWorkspaceUrl('/locations?tab=group');
  };

  return (
    <MainCnt {...containerProps}>
      {customProps.isSearch && (
        <SearchInputCnt>
          <StyledSearchField
            id="SearchInput"
            autoFocus
            startAdornment={<SearchIcon />}
            endAdornment={
              <CustomIconButton onClick={() => setSearchQuery('')}>
                <CrossIcon className="icon" />
              </CustomIconButton>
            }
            onChange={handleSearch}
            placeholder="Type to Search"
            value={searchQuery}
            fullWidth
          />
        </SearchInputCnt>
      )}

      <LocationsGroupsList
        data={transformedData}
        selected={selected}
        changeHandler={changeHandlerCallback}
        selectAllHandler={selectAllHandler}
        isAllSelected={isAllSelected}
        contentProps={customProps}
        searchQuery={searchQuery}
      />
      {hideFooter ? (
        <></>
      ) : (
        footerRenderer || (
          <CustomListItem
            checkbox={false}
            onClick={createLocationGroup}
            id={'addNewLocationGroupAction'}
            startIcon={<AddIcon sx={{ color: '#6868FE' }} />}
            className="addNewLocationGroup"
          >
            Create Location Group
          </CustomListItem>
        )
      )}
    </MainCnt>
  );
};

export default LocationsGroupsContainer;
