import { useContext, useEffect, useState } from 'react';
import { Stack, Checkbox, Typography, Tooltip } from '@mui/material';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import { CreateTaskContext } from 'components/AllDialogs/tasks/createTask/context/context';
import CustomDropdown from 'components/Dropdown/CustomDropdown';
import ConfigurationIcon from 'components/Icons/sidebarIcons/configurationIcon';
import InfoIcon from '@mui/icons-material/Info';

const SettingOption = ({ isChecked, label, onClickHandler, type }) => {
  return (
    <Stack
      p="8px 12px"
      alignItems={'center'}
      borderRadius={'4px'}
      direction={'row'}
      bgcolor={isChecked ? 'rgba(235, 234, 255, 1)' : '#fff'}
      borderColor={
        isChecked ? 'rgba(104, 104, 254, 1)' : 'rgba(224, 224, 224, 1)'
      }
      gap="8px"
      justifyContent={'space-between'}
      onClick={() => {
        onClickHandler(type);
      }}
    >
      <Typography
        fontSize={'14px'}
        fontWeight={isChecked ? '600' : '400'}
        color={isChecked ? 'rgba(104, 104, 254, 1)' : '#212121'}
      >
        {label}
      </Typography>
      {isChecked && (
        <DoneRoundedIcon sx={{ color: 'rgba(104, 104, 254, 1)' }} />
      )}
    </Stack>
  );
};

const BulkTaskCreationSetting = () => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const { updateTask, newTask } = useContext(CreateTaskContext);
  const [isSeparate, setIsSeparate] = useState(false);
  const [type, setType] = useState('tasksForAssignees');

  const createSeparateTasksHandler = (event) => {
    const checked = event?.target?.checked;
    setIsSeparate(checked);

    updateTask({
      isAutoTagLocation: checked,
      isMultiTasks: checked,
      ...(checked && {
        locationIds: null,
        AssetId: null,
      }),
    });
  };

  const handleAutoTagLocation = (event) => {
    const checked = event?.target?.checked;
    updateTask({
      isMultiTasks: checked,
      isAutoTagLocation: checked,
      ...(checked && {
        locationIds: null,
        AssetId: null,
      }),
    });
  };

  return (
    <Stack
      direction={'column'}
      alignItems={'start'}
      mt="12px"
      borderRadius="6px"
      padding="12px 0px 12px 10px"
      sx={{
        backgroundColor: '#F5F5F5',
        gap: '4px',
      }}
    >
      {newTask?.assignees?.length > 1 && (
        <Stack direction={'row'} alignItems={'center'} gap="8px">
          <Checkbox
            sx={{ padding: '0px' }}
            onChange={createSeparateTasksHandler}
            checked={
              newTask?.isMultiTasks ||
              (newTask?.assignees?.length && newTask?.isAutoTagLocation) ||
              (newTask?.assignees?.length && newTask?.isMultiTasks)
            }
          />
          <Typography
            fontSize="14px"
            fontWeight="500"
            color="#000000"
            lineHeight="19.12px"
          >
            Create separate tasks for each assignee
          </Typography>
        </Stack>
      )}
      <Stack direction={'row'} alignItems={'center'} gap="8px">
        <Checkbox
          sx={{ padding: '0px' }}
          onChange={handleAutoTagLocation}
          checked={newTask?.isAutoTagLocation || false}
        />
        <Typography
          fontSize="14px"
          fontWeight="500"
          color="#000000"
          lineHeight="19.12px"
        >
          Set location based on the assignee's default location
        </Typography>
      </Stack>
    </Stack>
  );
};

export default BulkTaskCreationSetting;
