// React
import { useState, Fragment } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import actions from 'store/actions';

// Custom components
import CustomDropdown from 'components/Dropdown/CustomDropdown';
import { HasPermission } from 'components/HasPermission';

// MUI
import { Divider } from '@mui/material';

// Styles
import {
  StyledButton,
  AddNewPopperWrapper,
  AddNewItemWrapper,
  AddNewItemTitle,
  AddNewIconButton,
} from './addNew.style';

// Icons
import AddIcon from 'components/common/jsxrender/messages/icons/AddIcon';
import AssetsIcon from 'components/Icons/sidebarIcons/assetsIcon';
import ChecklistIcon from 'components/Icons/sidebarIcons/checklistIcon';
import LocationIcon from 'components/Icons/sidebarIcons/locationIcon';
import TaskIconWithPlus from 'components/Icons/sidebarIcons/taskIconWithPlus';
import ChatIconWithPlus from 'components/Icons/sidebarIcons/chatIconWithPlus';

// Constants
import DIALOGS from 'utils/dialogIds';
import { PERMISSIONS } from 'utils/constants';

// Hooks
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import DocumentsIcon from 'components/Icons/documents';

interface AddNewPropTypes {
  sidebarOpen?: boolean;
}

const AddNew = (props: AddNewPropTypes) => {
  const { sidebarOpen } = props;
  const dispatch = useDispatch();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClickMenuItem = (
    itemType: 'task' | 'template' | 'newDoc' | 'chat' | 'location' | 'asset',
  ) => {
    setAnchorEl(null);

    switch (itemType) {
      case 'task':
        handleCreateTask();
        break;
      case 'template':
        handleCreateTemplate();
        break;
      case 'newDoc':
        handleNewDoc();
        break;
      case 'chat':
        handleCreateChat();
        break;
      case 'location':
        handleCreateLocation();
        break;
      case 'asset':
        handleCreateAsset();
        break;
      default:
        break;
    }
  };

  const handleCreateTask = () => {
    dispatch(
      actions.setDialog({
        open: true,
        dialogId: DIALOGS.CREATE_TASK,
      }),
    );
  };

  const handleCreateTemplate = () => {
    navigateWithWorkspaceUrl('/checklist/new');
  };

  const handleNewDoc = () => {
    dispatch(
      actions.setDialog({
        open: true,
        dialogId: DIALOGS?.CREATE_DOCUMENT_DIALOG,
        data: { isClose: false },
      }),
    );
  };

  const handleCreateChat = () => {
    navigateWithWorkspaceUrl('/messages');
    dispatch(actions.showCreateGroup(true));
  };

  const handleCreateLocation = () => {
    dispatch(
      actions.setDialog({
        open: true,
        dialogId: 'createLocation',
      }),
    );
  };

  const handleCreateAsset = () => {
    dispatch(
      actions.setDialog({
        open: true,
        dialogId: 'createAsset',
      }),
    );
  };

  return (
    <CustomDropdown
      onClickawayCallback={() => setAnchorEl(null)}
      popperProps={{
        anchorEl,
        open: Boolean(anchorEl),
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [sidebarOpen ? 0 : 100, 3],
            },
          },
        ],
        sx: {
          '& .MuiPaper-root': {
            width: 226,
            boxShadow: '0px 5px 16px 0px rgba(0,0,0,0.22)',
          },
        },
        content: (
          <AddNewPopperWrapper>
            <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_TASKS]}>
              <Fragment>
                <AddNewItemWrapper onClick={() => handleClickMenuItem('task')}>
                  <TaskIconWithPlus />
                  <AddNewItemTitle>New Task</AddNewItemTitle>
                </AddNewItemWrapper>
                <Divider />
              </Fragment>
            </HasPermission>
            <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_CHECKLIST]}>
              <Fragment>
                <AddNewItemWrapper
                  onClick={() => handleClickMenuItem('template')}
                >
                  <ChecklistIcon />
                  <AddNewItemTitle>New Template</AddNewItemTitle>
                </AddNewItemWrapper>
                <Divider />
              </Fragment>
            </HasPermission>
            <HasPermission permissions={[PERMISSIONS.CAN_VIEW_SOP_AND_FILES]}>
              <Fragment>
                <AddNewItemWrapper
                  onClick={() => handleClickMenuItem('newDoc')}
                >
                  <DocumentsIcon />
                  <AddNewItemTitle>New Doc</AddNewItemTitle>
                </AddNewItemWrapper>
                <Divider />
              </Fragment>
            </HasPermission>
            <HasPermission permissions={[PERMISSIONS.CAN_USE_CHAT]}>
              <Fragment>
                <AddNewItemWrapper onClick={() => handleClickMenuItem('chat')}>
                  <ChatIconWithPlus />
                  <AddNewItemTitle>New Chat</AddNewItemTitle>
                </AddNewItemWrapper>
                <Divider />
              </Fragment>
            </HasPermission>
            <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_LOCATIONS]}>
              <Fragment>
                <AddNewItemWrapper
                  onClick={() => handleClickMenuItem('location')}
                >
                  <LocationIcon />
                  <AddNewItemTitle>New Location</AddNewItemTitle>
                </AddNewItemWrapper>
                <Divider />
              </Fragment>
            </HasPermission>
            <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_ASSETS]}>
              <Fragment>
                <AddNewItemWrapper onClick={() => handleClickMenuItem('asset')}>
                  <AssetsIcon />
                  <AddNewItemTitle>New Asset</AddNewItemTitle>
                </AddNewItemWrapper>
              </Fragment>
            </HasPermission>
          </AddNewPopperWrapper>
        ),
      }}
      buttonRenderer={
        !sidebarOpen ? (
          <AddNewIconButton
            onClick={(event: React.MouseEvent<HTMLElement>) =>
              setAnchorEl(anchorEl ? null : event.currentTarget)
            }
          >
            <AddIcon />
          </AddNewIconButton>
        ) : (
          <StyledButton
            onClick={(event: React.MouseEvent<HTMLElement>) =>
              setAnchorEl(anchorEl ? null : event.currentTarget)
            }
            startIcon={<AddIcon />}
          >
            Create
          </StyledButton>
        )
      }
    />
  );
};

export default AddNew;
