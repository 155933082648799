import React, { useState } from 'react';
import { Typography, Stack, Grid } from '@mui/material';
import TimeListDropdown from '../components/TimeListDropdown';
import moment from 'moment';
import ScheduleDatePickerDropdown from 'components/Dropdown/ScheduleDatePicker/datePicker.cmp';
import { DatePickerDropdown } from 'components/Dropdown/DatePicker/datePicker.cmp';
import CalendarIconRoundedWithDots from 'components/Icons/calendarIconRoundedWithDots';

const OneOff = ({
  onDueTimeSelect,
  onStartTimeSelect,
  isDropdown = false,
  updateTask,
  newTask,
}) => {
  const onStartDateChange = (date) => {
    updateTask({
      startTime: date,
    });
  };
  const onDueDateChange = (date) => {
    updateTask({
      dueTime: date,
    });
  };

  const isStartDateDisabled = (day: Date) => {
    return newTask.dueTime
      ? moment(day).isAfter(newTask.dueTime, 'day')
      : false;
  };

  const isDueDateDisabled = (day: Date) => {
    return newTask.startTime
      ? moment(day).isBefore(newTask.startTime, 'day')
      : false;
  };

  return (
    <Grid container spacing="6px" alignItems={'center'}>
      <Grid item xs={12} md={isDropdown ? 12 : 2}>
        <Typography fontWeight={'500'} fontSize={'13px'} color="#212121">
          Date/Time:
        </Typography>
      </Grid>
      <Grid
        alignItems={'center'}
        gap="6px"
        display={'flex'}
        item
        xs={12}
        md={isDropdown ? 12 : 10}
        flexWrap={'wrap'}
      >
        <DatePickerDropdown
          selected={newTask?.startTime}
          onDateSelect={onStartDateChange}
          onTimeSelect={onStartTimeSelect}
          mode="dateTime"
          placeholder="Start"
          buttonProps={{
            buttonStyle: {
              height: '32px',
              padding: '0px 12px',
              color: '#000',
              fontSize: '13px',
            },
            buttonSx: {
              minWidth: '113px',
              borderRadius: '6px',
            },
            endIcon: <CalendarIconRoundedWithDots />,
          }}
          datePickerProps={{ disabled: isStartDateDisabled }}
        />
        <Typography fontWeight={'500'} fontSize={'13px'} color="#212121">
          -
        </Typography>
        <DatePickerDropdown
          selected={newTask?.dueTime}
          onDateSelect={onDueDateChange}
          onTimeSelect={onDueTimeSelect}
          mode="dateTime"
          placeholder="Due"
          buttonProps={{
            buttonStyle: {
              height: '32px',
              padding: '0px 12px',
              color: '#000',
              fontSize: '13px',
            },
            buttonSx: {
              minWidth: '113px',
              borderRadius: '6px',
            },
            endIcon: <CalendarIconRoundedWithDots />,
          }}
          datePickerProps={{ disabled: isDueDateDisabled }}
        />
      </Grid>
    </Grid>
  );
};

export default OneOff;
