import { createSvgIcon } from '@mui/material';

const ToolsIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M5.49992 5.50016L9.62492 9.62516M5.49992 5.50016H2.74992L1.83325 2.75016L2.74992 1.8335L5.49992 2.75016V5.50016ZM17.654 2.51276L15.2453 4.92141C14.8823 5.28442 14.7008 5.46593 14.6328 5.67523C14.573 5.85934 14.573 6.05766 14.6328 6.24176C14.7008 6.45106 14.8823 6.63257 15.2453 6.99558L15.4628 7.21307C15.8258 7.57609 16.0074 7.7576 16.2167 7.8256C16.4008 7.88542 16.5991 7.88542 16.7832 7.8256C16.9925 7.7576 17.174 7.57609 17.537 7.21307L19.7901 4.95999C20.0328 5.55047 20.1666 6.19719 20.1666 6.87516C20.1666 9.6596 17.9094 11.9168 15.1249 11.9168C14.7892 11.9168 14.4612 11.884 14.1439 11.8214C13.6982 11.7335 13.4754 11.6896 13.3403 11.7031C13.1967 11.7174 13.126 11.7389 12.9987 11.807C12.879 11.871 12.759 11.9911 12.5189 12.2312L5.95825 18.7918C5.19886 19.5512 3.96764 19.5512 3.20825 18.7918C2.44886 18.0324 2.44886 16.8012 3.20825 16.0418L9.76886 9.48122C10.009 9.2411 10.129 9.12105 10.1931 9.00136C10.2612 8.87412 10.2827 8.80334 10.297 8.65974C10.3105 8.52466 10.2665 8.30185 10.1786 7.85622C10.1161 7.53888 10.0833 7.21085 10.0833 6.87516C10.0833 4.09073 12.3405 1.8335 15.1249 1.8335C16.0466 1.8335 16.9106 2.08083 17.654 2.51276ZM11 13.7501L16.0416 18.7917C16.801 19.5511 18.0322 19.5511 18.7916 18.7917C19.551 18.0323 19.551 16.8011 18.7916 16.0417L14.644 11.8942C14.3503 11.8664 14.0641 11.8134 13.7873 11.7375C13.4307 11.6396 13.0395 11.7106 12.7779 11.9721L11 13.7501Z"
        stroke="#424242"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'ToolsIcon',
  );

  return <IconCmp viewBox={'0 0 22 22'} style={{ fill: 'none' }} {...props} />;
};
export default ToolsIcon;
