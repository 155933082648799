// React
import React, { useState, useEffect, memo } from 'react';

// Custom components
import TemplateItem from 'components/TemplateSubmissionPreview/TemplateItem';

// Styled
import { BodyWrapper } from './styled';

// Utils
import { SubmissionItemType } from 'pages/TemplateDashboard/Tabs/Submissions/context';
import { groupBy } from 'lodash';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import { Chip, Stack } from '@mui/material';
import { isEqual } from 'lodash';

const TemplateBody = ({
  logChecklistItems,
  templateLog,
  isPreview = false,
}: {
  logChecklistItems: SubmissionItemType[];
  templateLog?: any;
  isPreview?: boolean;
}) => {
  const [correctiveTasks, setCorrectiveTasks] = useState<any>();

  const createCorrectiveTasksObj = (logChecklistItems) => {
    const obj = {};
    logChecklistItems?.forEach((item: any) => {
      obj[item.id] = item.Tasks;
    });
    return obj;
  };

  const handleAddCorrectiveTask = (itemId: string, task: any) => {
    const _task = task?.length ? task[0] : task;
    setCorrectiveTasks((prevState) => ({
      ...prevState,
      [itemId]: [...prevState[itemId], _task],
    }));
  };

  useEffect(() => {
    if (logChecklistItems && logChecklistItems.length) {
      setCorrectiveTasks(createCorrectiveTasksObj(logChecklistItems));
    }
  }, [logChecklistItems]);

  const sectionItems = groupBy(logChecklistItems, 'sectionId');
  const nonSectionItems = sectionItems?.['undefined']?.filter(
    (item) => item.type !== 'header',
  );

  const sections = logChecklistItems
    ?.filter((item) => item.type === 'header')
    .map((item) => ({
      ...item,
      items: sectionItems[item.id],
    }));

  return (
    <BodyWrapper>
      {nonSectionItems?.map((item) => (
        <TemplateItem
          onCorrectiveTaskCreationCb={handleAddCorrectiveTask}
          templateLog={templateLog}
          key={item.id}
          item={item}
          correctiveTasks={correctiveTasks?.[item.id]}
          isPreview={isPreview}
        />
      ))}

      {sections?.map((section: any) => {
        const { progress, score } = section;

        return (
          <Accordion
            sx={{
              padding: '12px 0px !important',
              width: '100% !important',
              boxShadow: 'none',
              '&:before': {
                display: 'none',
              },
            }}
            TransitionProps={{ unmountOnExit: true }}
            defaultExpanded
            disableGutters
            square={false}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                background: '#EBEAFF',
                height: '49px !important',
                minHeight: 'initial !important',
                borderRadius: '8px',
              }}
            >
              <Stack
                width="100%"
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography fontWeight={600} fontSize="18px" color="#000000DE">
                  {section.description} ({progress?.completed} of{' '}
                  {progress?.total})
                </Typography>
                {templateLog?.Checklist?.isScoring && (
                  <Chip
                    label={`Score: ${score.percentage}% (${score.earned} of ${score.total} pts)`}
                    size="small"
                    sx={{
                      background: '#CCCBFF',
                      fontWeight: 700,
                      fontSize: '13px',
                    }}
                  />
                )}
              </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ px: 0, pb: 0 }}>
              <Stack spacing={2}>
                {section?.items?.map((item) => (
                  <TemplateItem
                    onCorrectiveTaskCreationCb={handleAddCorrectiveTask}
                    templateLog={templateLog}
                    key={item.id}
                    item={item}
                    correctiveTasks={correctiveTasks?.[item.id]}
                    isPreview={isPreview}
                  />
                ))}
              </Stack>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </BodyWrapper>
  );
};

export default memo(TemplateBody, (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
});
