import { useContext } from 'react';
import { Typography } from '@mui/material';
import CustomRolesSetAsigneesListing from 'components/CustomRoles/AssignRole';
import { CustomRolesDetailFormContext } from '../context';
import { HeaderContent, MainCnt } from './style';

/**
 * CustomRolesAssign component - renders the assign role step in the custom roles form.
 */
const CustomRolesAssign = () => {
  // Extracting state and updateState function from CustomRolesDetailFormContext
  const { state, updateState } = useContext(CustomRolesDetailFormContext);

  return (
    <MainCnt>
      <HeaderContent>
        <Typography className="top">Step 3 of 3</Typography>
        <Typography className="bottom">Assign Users</Typography>
      </HeaderContent>

      {/* CustomRolesSetAsigneesListing component for displaying and managing assignees */}
      <CustomRolesSetAsigneesListing
        selected={state?.assignees}
        onSelectedChange={(assignees) => {
          updateState({ assignees });
        }}
        isSearch
        height="calc(100vh - 275px)"
      />
    </MainCnt>
  );
};

export default CustomRolesAssign;
