// React
import { useState } from 'react';

// Mui
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { Collapse } from '@mui/material';

// Custom components
import Note from './Note';

import { NotesCollapseButton } from './styled';

// Types
import { TemplateNoteType } from 'pages/TemplateDashboard/context';

interface TemplateNotesPropTypes {
  notes: TemplateNoteType[];
}

const TemplateNotes = ({ notes }: TemplateNotesPropTypes) => {
  const [expanded, setExpanded] = useState(true);

  const toggleNotes = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <NotesCollapseButton onClick={(e) => toggleNotes()}>
        {expanded ? <ExpandLess /> : <ExpandMoreIcon />}
        {expanded ? 'Collapse' : `Notes (${notes.length})`}
      </NotesCollapseButton>
      <Collapse
        sx={{ marginTop: '8px' }}
        in={expanded}
        timeout={'auto'}
        unmountOnExit
      >
        {notes.map((note) => (
          <Note note={note} key={note.id} />
        ))}
      </Collapse>
    </>
  );
};

export default TemplateNotes;
