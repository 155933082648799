// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

// Custom components
import CustomButton from 'components/Button/CustomButton';

// Icons
import StarIcon from 'components/Icons/starIcon';

export const Wrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  padding: '6px',
  borderRadius: '6px',
  gap: '10px',
  background: 'rgba(255, 249, 196, 1)',
  height: '44px',
}));

export const IconAndDaysWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '7px',
}));

export const TrialDaysLeft = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '16.39px',
  color: 'rgba(33, 33, 33, 1)',
  letterSpacing: '0.46px',
  maxWidth: '63px',
}));

export const UpgradeButton = styled(CustomButton)(({ theme }) => ({
  backgroundColor: 'rgba(255, 191, 0, 1)',
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '22px',
  color: 'rgba(33, 33, 33, 1)',
  '&:hover': {
    backgroundColor: 'rgba(255, 191, 0, 1)',
    boxShadow: '1px 3px 34px -13px rgba(0,0,0,0.75)',
  },
}));

export const StyledStarIcon = styled(StarIcon)(({ theme }) => ({
  color: 'rgba(255, 191, 0, 1)',
}));
