// React
import React, { useMemo } from 'react';

// Custom components
import Header from 'components/TemplateSubmissionPreview/Header';
import TemplateBody from 'components/TemplateSubmissionPreview/TemplateBody';

// Styled
import { Wrapper, BodyWrapper } from './styled';

// Context
import { SubmissionReportType } from 'pages/TemplateDashboard/Tabs/Submissions/context';

// Utils
import { isEqual } from 'lodash';

// Types
import { LogFilterTypes } from 'components/TemplateDashboard/SubmissionTemplatePreview/types';

const TemplateSubmissionPreview = ({
  log,
  activeSubmission,
  activeSubmissionItemsFilter,
  handleFilterSubmissionLogItems,
}: {
  log?: SubmissionReportType;
  activeSubmission: any;
  handleFilterSubmissionLogItems: (filterName: LogFilterTypes) => void;
  activeSubmissionItemsFilter: LogFilterTypes;
}) => {
  const headerData = useMemo(() => {
    if (log)
      return {
        name: log?.name,
        attachments: log?.attachments,
      };
    return null;
  }, [log]);

  return (
    <Wrapper>
      {!!headerData && (
        <Header
          activeSubmission={activeSubmission}
          handleFilterSubmissionLogItems={handleFilterSubmissionLogItems}
          activeSubmissionItemsFilter={activeSubmissionItemsFilter}
          data={headerData}
        />
      )}
      {log && (
        <BodyWrapper>
          <TemplateBody
            isPreview
            templateLog={log}
            logChecklistItems={log.TaskChecklistItems.map((item) => ({
              ...item,
              ...(log?.anonymousId &&
                log?.submitterName && {
                  Updater: {
                    firstName: log?.submitterName,
                  },
                }),
            }))}
          />
        </BodyWrapper>
      )}
    </Wrapper>
  );
};

const arePropsEqual = (prevProps, nextProps) => isEqual(prevProps, nextProps);

export default React.memo(TemplateSubmissionPreview, arePropsEqual);
