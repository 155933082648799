import actionsTypes from 'store/actionTypes';
import { Action } from 'models/index.m';

export const getChecklistListApiCall = (data = {}): Action<any> => ({
  type: actionsTypes.GET_CHECKLIST_LIST,
  payload: data,
});

export const getChecklistCompleteList = (data = {}): Action<any> => ({
  type: actionsTypes.GET_CHECKLIST_COMPLETE_LIST,
  payload: data,
});

export const getPublicChecklists = (data = {}): Action<any> => ({
  type: actionsTypes.GET_PUBLIC_CHECKLISTS,
  payload: data,
});

export const getChecklistCategories = (): Action<any> => ({
  type: actionsTypes.GET_CHECKLIST_CATEGORIES,
  payload: {},
});

export const getChecklistIndustries = (): Action<any> => ({
  type: actionsTypes.GET_CHECKLIST_INDUSTRIES,
  payload: {},
});

export const getChecklistTypes = (): Action<any> => ({
  type: actionsTypes.GET_CHECKLIST_TYPES,
  payload: {},
});

export const setChecklistList = (data: any): Action<any> => ({
  type: actionsTypes.SET_CHECKLIST_LIST,
  payload: data,
});

export const setChecklistCompleteList = (data: any): Action<any> => ({
  type: actionsTypes.SET_CHECKLIST_COMPLETE_LIST,
  payload: data,
});

export const setPublicChecklists = (data: any): Action<any> => ({
  type: actionsTypes.SET_PUBLIC_CHECKLISTS,
  payload: data,
});

export const setChecklistCategories = (data: any): Action<any> => ({
  type: actionsTypes.SET_CHECKLIST_CATEGORIES,
  payload: data,
});

export const setChecklistIndustries = (data: any): Action<any> => ({
  type: actionsTypes.SET_CHECKLIST_INDUSTRIES,
  payload: data,
});

export const setChecklistTypes = (data: any): Action<any> => ({
  type: actionsTypes.SET_CHECKLIST_TYPES,
  payload: data,
});

export const createChecklistApiCall = (data: any): Action<any> => ({
  type: actionsTypes.CREATE_CHECKLIST,
  payload: data,
});

export const updateChecklistApiCall = (
  checklistId: any,
  showNotification: boolean,
  data: any,
): Action<any> => ({
  type: actionsTypes.UPDATE_CHECKLIST,
  payload: { checklistId, showNotification, data },
});

export const duplicateChecklistApiCall = (
  checklistId: any,
  saveToLibrary = false,
  disableToast = false,
  onboarding = false,
): Action<any> => ({
  type: actionsTypes.DUPLICATE_CHECKLIST,
  payload: { checklistId, saveToLibrary, disableToast, onboarding },
});

export const publishChecklist = (checklistId: any): Action<any> => ({
  type: actionsTypes.PUBLISH_CHECKLIST,
  payload: { checklistId },
});

export const archiveChecklistApiCall = (
  checklistId: any,
  selectedFolder?: any,
): Action<any> => ({
  type: actionsTypes.ARCHIVE_CHECKLIST,
  payload: { checklistId, selectedFolder },
});
export const removeChecklist = (checklistId: any): Action<any> => ({
  type: actionsTypes.DELETE_CHECKLIST,
  payload: { checklistId },
});

export const setChecklist = (data: any): Action<any> => ({
  type: actionsTypes.SET_CHECKLIST,
  payload: data,
});

export const uploadChecklistAttachments = (
  checklistId: any,
  attachments: any,
): Action<any> => ({
  type: actionsTypes.UPLOAD_CHECKLIST_ATTACHMENTS,
  payload: { checklistId, attachments },
});

export const setChecklistAttachments = (data: any): Action<any> => ({
  type: actionsTypes.SET_CHECKLIST_ATTACHMENTS,
  payload: data,
});

export const getChecklistApiCall = (checklistId: any): Action<any> => ({
  type: actionsTypes.GET_CHECKLIST,
  payload: { checklistId },
});

export const setChecklistItem = (
  data: any,
  checklistItemIndex?: number,
): Action<any> => ({
  type: actionsTypes.SET_CHECKLIST_ITEM,
  payload: { data, checklistItemIndex },
});

export const addChecklistItem = (data: any): Action<any> => ({
  type: actionsTypes.ADD_CHECKLIST_ITEM,
  payload: { data },
});

export const getChecklistItemsApiCall = (checklistId: any): Action<any> => ({
  type: actionsTypes.GET_CHECKLIST_ITEMS,
  payload: { checklistId },
});

export const setChecklistItems = (data: any): Action<any> => ({
  type: actionsTypes.SET_CHECKLIST_ITEMS,
  payload: data,
});

export const getChecklistLogs = (data: any): Action<any> => ({
  type: actionsTypes.GET_CHECKLIST_LOGS,
  payload: data,
});

export const getChecklistLog = (data: any): Action<any> => ({
  type: actionsTypes.GET_CHECKLIST_LOG,
  payload: data,
});

export const submitChecklistLog = (data: {
  hotelId: string;
  logId: string;
  onChecklistSubmitCb?: any;
}): Action<any> => ({
  type: actionsTypes.SUBMIT_CHECKLIST_LOG,
  payload: data,
});

export const createChecklistLog = (data: any): Action<any> => ({
  type: actionsTypes.CREATE_CHECKLIST_LOG,
  payload: data,
});

export const setChecklistLogs = (data: any): Action<any> => ({
  type: actionsTypes.SET_CHECKLIST_LOGS,
  payload: data,
});

export const setChecklistLog = (data: any): Action<any> => ({
  type: actionsTypes.SET_CHECKLIST_LOG,
  payload: data,
});

export const spliceChecklistItem = (
  checklistItemIndex: number,
): Action<any> => ({
  type: actionsTypes.SPLICE_CHECKLIST_ITEMS,
  payload: { checklistItemIndex },
});

export const updateChecklistItemApiCall = (
  checklistId: any,
  checklistItemId: any,
  checklistItemIndex: number,
  data: any,
): Action<any> => ({
  type: actionsTypes.MODIFY_CHECKLIST_ITEM,
  payload: {
    checklistId,
    checklistItemId,
    checklistItemIndex,
    data,
  },
});

export const deleteChecklistItemApiCall = (
  checklistId: any,
  checklistItemId: any,
  checklistItemIndex: number,
): Action<any> => ({
  type: actionsTypes.DELETE_CHECKLIST_ITEM,
  payload: { checklistId, checklistItemId, checklistItemIndex },
});

export const initializeNewChecklist = (): Action<any> => ({
  type: actionsTypes.INITIALIZE_CHECKLIST,
  payload: {},
});

export const uploadChecklistItemAttachments = (
  checklistItemIndex: any,
  attachments: any,
): Action<any> => ({
  type: actionsTypes.UPLOAD_CHECKLIST_ITEM_ATTACHMENTS,
  payload: { checklistItemIndex, attachments },
});

export const setChecklistItemAttachments = (
  checklistItemIndex: any,
  attachments: any,
): Action<any> => ({
  type: actionsTypes.SET_CHECKLIST_ITEM_ATTACHMENTS,
  payload: { checklistItemIndex, attachments },
});

export const deleteChecklistItemAttachment = (
  checklistItemIndex: any,
  attachmentIndex: any,
): Action<any> => ({
  type: actionsTypes.DELETE_CHECKLIST_ITEM_ATTACHMENT,
  payload: { checklistItemIndex, attachmentIndex },
});

export const setChecklistLogSubmitted = (data: any): Action<any> => ({
  type: actionsTypes.CHECKLIST_LOG_SUBMITTED,
  payload: data,
});

export const setPreviewChecklistData = (data: any): Action<any> => ({
  type: actionsTypes.SET_PREVIEW_CHECKLIST_DATA,
  payload: data,
});

export const setPreviewChecklistModalConfig = (data: {
  open?: boolean;
  activeTab?: 'preview' | 'logs' | 'log';
  checklistScope?: 'myTemplates' | 'publicTemplates';
  logId?: string;
  onArchiveCb?: () => void;
}): Action<any> => ({
  type: actionsTypes.SET_PREVIEW_CHECKLIST_MODAL_CONFIG,
  payload: data,
});

export const closePreviewChecklistModal = (): Action<any> => ({
  type: actionsTypes.CLOSE_PREVIEW_CHECKLIST_MODAL,
});

export const setChecklistViewType = (data: 'grid' | 'view'): Action<any> => ({
  type: actionsTypes.SET_CHECKLIST_VIEW_TYPE,
  payload: data,
});

export const setChecklistFilters = (data): Action<any> => ({
  type: actionsTypes.SET_CHECKLIST_FILTERS,
  payload: data,
});

export const fetchChecklistItemsAutomations = (
  workspaceId: string,
  checklistId: string,
): Action<any> => ({
  type: actionsTypes.FETCH_CHECKLIST_ITEMS_AUTOMATIONS,
  payload: { workspaceId, checklistId },
});
