import CustomInput from 'components/Form/TextField/TextField.cmp';
import React, { useContext } from 'react';
import { CreateTaskContext } from 'components/AllDialogs/tasks/editTask/context/context';

const TaskDescriptionInput = () => {
  const { updateTask, newTask } = useContext(CreateTaskContext);
  const inputValue = newTask.description || '';
  const handleInputChange = (e) => {
    const value = e.target.value;
    updateTask({ description: value });
  };
  return (
    <CustomInput
      topLabelInput={true}
      muiLabel={false}
      label="Description"
      fieldProps={{
        multiline: true,
        maxRows: 3,
        minRows: 3,
        placeholder: 'Enter a description here',
        onChange: handleInputChange,
        value: inputValue,
      }}
    />
  );
};

export default TaskDescriptionInput;
