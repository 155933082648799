import { createSvgIcon } from '@mui/material';

interface PropTypes {
  filled?: boolean;
}

const RequestsIcon = (props: PropTypes) => {
  const { filled } = props;
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M2.29159 10.9998H5.39172C6.01983 10.9998 6.59402 11.3547 6.87492 11.9165C7.15582 12.4783 7.73001 12.8332 8.35812 12.8332H13.6417C14.2698 12.8332 14.844 12.4783 15.1249 11.9165C15.4058 11.3547 15.98 10.9998 16.6081 10.9998H19.7083M8.21927 3.6665H13.7806C14.7677 3.6665 15.2613 3.6665 15.697 3.8168C16.0824 3.94972 16.4333 4.16663 16.7246 4.45185C17.0539 4.77438 17.2746 5.21585 17.716 6.09876L19.7021 10.0708C19.8753 10.4173 19.9619 10.5905 20.023 10.7721C20.0773 10.9333 20.1164 11.0993 20.14 11.2677C20.1666 11.4575 20.1666 11.6512 20.1666 12.0385V13.9332C20.1666 15.4733 20.1666 16.2434 19.8669 16.8316C19.6032 17.3491 19.1825 17.7698 18.6651 18.0334C18.0768 18.3332 17.3067 18.3332 15.7666 18.3332H6.23325C4.69311 18.3332 3.92304 18.3332 3.33478 18.0334C2.81733 17.7698 2.39664 17.3491 2.13298 16.8316C1.83325 16.2434 1.83325 15.4733 1.83325 13.9332V12.0385C1.83325 11.6512 1.83325 11.4575 1.85981 11.2677C1.8834 11.0993 1.92257 10.9333 1.97682 10.7721C2.03791 10.5905 2.12453 10.4173 2.29777 10.0708L4.28379 6.09876C4.72525 5.21585 4.94598 4.77439 5.27528 4.45185C5.5665 4.16663 5.91747 3.94972 6.30282 3.8168C6.73857 3.6665 7.23213 3.6665 8.21927 3.6665Z"
        stroke="#424242"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'RequestsIcon',
  );
  const FilledIconCmp = createSvgIcon(
    <>
      <path
        d="M8.07971 2.75006C7.22931 2.74949 6.58822 2.74905 6.00412 2.95052C5.49033 3.12774 5.02236 3.41696 4.63408 3.79726C4.19266 4.22959 3.90634 4.8032 3.52655 5.56406C3.06049 6.49621 2.30605 8.00794 1.79942 9.02338C1.62438 9.37421 1.53686 9.54962 1.55804 9.69237C1.57653 9.81702 1.64554 9.92857 1.74881 10.0008C1.86709 10.0834 2.06334 10.0834 2.45584 10.0834H5.3919C6.36721 10.0834 7.25882 10.6345 7.69499 11.5068C7.82061 11.7581 8.0774 11.9168 8.3583 11.9168H13.6419C13.9228 11.9168 14.1796 11.7581 14.3052 11.5068C14.7414 10.6345 15.633 10.0834 16.6083 10.0834H19.5444C19.9369 10.0834 20.1331 10.0834 20.2514 10.0008C20.3547 9.92857 20.4237 9.81702 20.4422 9.69237C20.4634 9.54962 20.3759 9.37421 20.2008 9.02338C19.6942 8.00795 18.9398 6.49622 18.4737 5.56408C18.0939 4.8032 17.8076 4.2296 17.3662 3.79726C16.9779 3.41696 16.5099 3.12774 15.9961 2.95052C15.412 2.74905 14.7709 2.74949 13.9205 2.75006H8.07971Z"
        fill="#4E48FA"
      />
      <path
        d="M21.0835 13.3831C21.0835 12.87 21.0836 12.6134 20.9836 12.4173C20.8958 12.2449 20.7555 12.1046 20.583 12.0167C20.387 11.9168 20.1303 11.9168 19.6171 11.9168H16.6083C16.3274 11.9168 16.0706 12.0755 15.945 12.3267C15.5088 13.1991 14.6172 13.7501 13.6419 13.7501H8.3583C7.38299 13.7501 6.49138 13.1991 6.05521 12.3267C5.92959 12.0755 5.6728 11.9168 5.3919 11.9168H2.38307C1.86988 11.9168 1.61328 11.9168 1.41719 12.0167C1.24473 12.1046 1.10444 12.2449 1.01658 12.4173C0.916679 12.6134 0.916703 12.87 0.91675 13.3831C0.916768 13.5792 0.916784 13.7752 0.916785 13.9713C0.916773 14.7092 0.916764 15.3182 0.957296 15.8143C0.999394 16.3296 1.08974 16.8032 1.31643 17.2481C1.66796 17.938 2.22889 18.4989 2.91882 18.8505C3.36371 19.0772 3.83736 19.1675 4.35261 19.2096C4.8487 19.2501 5.45769 19.2501 6.19557 19.2501H15.8047C16.5425 19.2501 17.1515 19.2501 17.6476 19.2096C18.1629 19.1675 18.6365 19.0772 19.0814 18.8505C19.7713 18.4989 20.3323 17.938 20.6838 17.2481C20.9105 16.8032 21.0008 16.3296 21.0429 15.8143C21.0835 15.3182 21.0835 14.7092 21.0835 13.9713C21.0835 13.7753 21.0835 13.5792 21.0835 13.3831Z"
        fill="#4E48FA"
      />
    </>,
    'RequestsIcon',
  );
  return filled ? (
    <FilledIconCmp viewBox={'0 0 22 22'} style={{ fill: 'none' }} {...props} />
  ) : (
    <IconCmp viewBox={'0 0 22 22'} style={{ fill: 'none' }} {...props} />
  );
};
export default RequestsIcon;
