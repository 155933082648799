import styled from '@mui/system/styled';
import { DialogActions, Stack, Switch, Typography } from '@mui/material';
import { colors } from 'components/theme/constants';
import CTypography from 'components/CTypography';
import CustomCheckbox from 'components/Form/Checkbox/Checkbox';

export const ProjectIconCnt = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  gap: '10px',

  '& svg': {
    height: 18,
  },

  '& .title': {
    color: '#424242',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '19.12.px',
  },
});

export const CheckListSeparateAssigneeText = styled(Typography)({
  fontWeight: 600,
  fontSize: 12,
  lineHeight: '16.39px',
  color: '#212121',
});

export const CheckListSeparateBox = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  gap: 7,
  marginTop: -12,

  '& .icon': {
    height: 16,
    width: 16,
    '& path': {
      color: '#0288D1',
    },
  },
});

export const FooterActionsStyled = styled(DialogActions)({
  justifyContent: 'space-between',
  height: 76,
  padding: '0 24px',
  boxShadow: '0px -1px 0px #E9E9ED',
});

export const AutoTagLocationContent = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  gap: 7,
  marginTop: -12,

  '& .icon': {
    height: 16,
    width: 16,
    '& path': {
      color: '#0288D1',
    },
  },
});

export const AutoTagLocationNote = styled(Typography)({
  fontWeight: 600,
  fontSize: 12,
  lineHeight: '16.39px',
  color: '#212121',
});

export const BannerCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 6,
  '& .infoIcon': {
    color: colors.white,
    display: 'inline',
  },
  '& .content': {
    display: 'inline',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '22px',
    letterSpacing: 0.15,
    color: colors.white,
  },
  '& .action': {
    display: 'inline',
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '22px',
    letterSpacing: '0.15px',
    color: colors.white,
  },
});

export const CreateProjectDialogHeader = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
});

export const CreateTaskDialogHeader = styled('div')({
  height: 90,
  display: 'flex',
  alignItems: 'center',
  padding: '0 24px',
});

export const AssetInfoCnt = styled('div')({
  color: '#1D2021',
  display: 'flex',
  alignItems: 'center',
  fontSize: 12,
});
export const AssigneeDropdownCnt = styled('div')({
  position: 'relative',
  width: '100%',
  marginTop: 5,

  '& .watchersCnt': {
    position: 'absolute',
    top: 39,
    right: 10,
  },
  '& label': {
    fontWeight: 600,
    fontSize: 15,
    lineHeight: '20.49px',
    color: '#000000',
  },
  '& .separator': {
    marginBottom: 10,
  },
});

export const TaskFormCnt = styled('div')(({ theme }) => ({
  padding: '0 24px 20px 24px',
  '& .iconBtnsDropdownCnt': {
    display: 'flex',
    alignItems: 'center',
    marginTop: 20,
    '& > div': {
      marginRight: 10,
      [theme.breakpoints.down('md')]: {
        flex: 1,
      },
      '& button': {
        [theme.breakpoints.down('md')]: {
          width: '100%',
        },
      },
    },
  },
  '& .selectBtnsDropdownCnt': {
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
    gap: 20,
    marginTop: 20,
  },
  '& .showLess': {
    display: 'none',
  },
}));
export const AddChecklistCnt = styled('div')({
  background: '#EBEAFF',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '10px 7px',
  marginTop: 20,
  marginBottom: 20,
  cursor: 'pointer',
  borderRadius: 8,
});
export const AddChecklistText = styled('span')({
  color: '#6868FE',
  fontSize: 15,
  fontWeight: 700,
  display: 'inline-block',
  marginLeft: 10,
});
export const AddAttachmentCnt = styled('div')(({ theme }) => ({
  background: '#EBEAFF',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '14px 7px',
  marginTop: 20,
  marginBottom: 20,
  cursor: 'pointer',
  borderRadius: 8,
  border: '1px dashed #6868FE',
  '& svg': {
    color: '#6868FE',
    fontSize: 23,
    [theme.breakpoints.down('sm')]: {
      fontSize: 34,
    },
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));
export const AddAttachmentText = styled('span')({
  color: '#6868FE',
  fontSize: 15,
  fontWeight: 700,
  display: 'inline-block',
  marginLeft: 10,
});
export const RepeatTaskToggleCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const RepeatTaskToggleLabel = styled('div')({
  color: '#252350',
  marginLeft: 8,
  display: 'inline-block',
  fontSize: 16,
  fontWeight: 600,
  '& svg': {
    color: '#6868FE',
  },
});
export const ShowMoreLessCnt = styled('div')({
  background: '#EBEAFF',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '5px 7px',
  marginTop: 20,
  borderRadius: 4,
  cursor: 'pointer',
  '& svg': {
    color: '#6868FE',
  },
});
export const ShowMoreLessText = styled('span')({
  color: '#6868FE',
  fontSize: 13,
  fontWeight: 700,
  display: 'inline-block',
  marginRight: 10,
});
export const ThumbsCnt = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
});
export const StyleImg = styled('img')({
  width: 28,
  height: 28,
  borderRadius: 4,
  border: '1px solid #e2e2e2',
});
export const FileIconCnt = styled('div')({
  width: 28,
});
export const SelectedChecklist = styled('div')({
  background: '#6868FE1A',
  display: 'flex',
  justifyContent: 'space-between',
  padding: 8,
  marginTop: 20,
  marginBottom: 20,
  '& .leftCnt': {
    display: 'flex',
  },
  '& .checklistName': {
    color: '#252350',
    fontSize: 16,
    fontWeight: 500,
  },
  '& .checklistItemCount': {
    color: '#7B79A1',
    fontSize: 14,
    fontWeight: 400,
  },
  '& .checklistNameCnt': {
    marginLeft: 16,
    display: 'flex',
    flexDirection: 'column',
  },
  '& .checklistIcon': {
    width: 36,
    height: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
  },
});
export const Thumb = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: 8,
  marginBottom: 8,
  border: '1px solid #E0E0E0',
  padding: 4,
  boxSizing: 'border-box',
  '&:first-child': {
    marginLeft: 0,
  },
  '&:nth-child(4n)': {
    marginLeft: 0,
  },
  '& .fileDetailsCnt': {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    flex: 1,
    marginLeft: 10,
    '& .fileName': {
      fontSize: 13,
      color: '#000',
      fontWeight: 400,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'block',
    },
    '& .fileSize': {
      fontSize: 12,
      fontWeight: 400,
    },
  },
  '& .removeIconBtn': {
    padding: 0,
  },
  '& .thumbInnerCnt': {
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    minWidth: 28,
    height: 28,
  },
  '& .removeIcon': {
    fontSize: 22,
    color: '#7571C5',
  },
});
export const RepeatTaskSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
    borderRadius: '50%',
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export const SubmissionAttachedCnt = styled('div')({
  backgroundColor: '#E8F5E9',
  display: 'flex',
  alignItems: 'center',
  padding: '8px 12px 8px 12px',
  borderRadius: 4,
  gap: 9,
  marginBottom: 20,
  position: 'relative',
  '& .leftCnt': {
    display: 'flex',
    marginRight: 10,
  },

  '& .linkCaption': {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '19px',
    letterSpacing: '1%',
    color: '#616161',
  },
  '& .submissionName': {
    color: '#212121',
    fontSize: 16,
    lineHeight: '22px',
    letterSpacing: '1%',
    fontWeight: 500,

    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '400px',
    display: 'block',
    textAlign: 'left',
  },
  '& .datetime': {
    color: '#616161',
    fontSize: 13,
    lineHeight: '17.76px',
    letterSpacing: '1%',
    fontWeight: 400,
    margin: '0px 5px',
  },
  '& .dot': {
    width: 3,
    height: 3,
    borderRadius: 5,
    background: '#616161',
    display: 'inline-block',
    margin: '0px 5px 2px 5px',
    opacity: 0.8,
  },
  '& .checklistIcon': {
    width: 36,
    height: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
  },
  '& .unLinkIcon': {
    position: 'absolute',
    right: 0,
    marginRight: 10,
  },
});

export const SubmissionUnLinkToolTip = styled(CTypography)({
  fontWeight: 400,
  fontSize: 12,
  lineHeight: '20px',
  letterSpacing: 0.4,
  color: colors.white,
});

export const Checkbox = styled(CustomCheckbox)({
  color: '#9E9E9E',
});
