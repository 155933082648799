import styled from '@mui/system/styled';
import CustomButton from 'components/Button/CustomButton';

export const CountCnt = styled(
  'div',
  {},
)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  '.detailText': {
    fontWeight: 600,
    fontSize: 16,
    margin: 0,
  },
  '.countsText': {
    fontWeight: 800,
    fontSize: 46,
    lineHeight: '66px',
    margin: 0,
    color: '#212121',
  },
}));
