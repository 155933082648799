import { useEffect, useMemo, useState } from 'react';
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import { useDispatch, useSelector } from 'react-redux';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import {
  LocationDropdownButton,
  AddLocationCnt,
  LocationWithParentLocationCnt,
  DottedContainer,
  DottedText,
} from 'components/Dropdown/Task/Location/taskLocationDropdown.style';
import LocationIcon from 'components/Icons/locationIcon';
import CustomListItem from 'components/List/ListItem/ListItem';
import AddIcon from '@mui/icons-material/Add';
import { useTaskEdit } from 'controller/useTaskEdit';
import CustomToolTip from 'components/Tooltip/tooltip';
import {
  getAuthorizedLocations,
  getLocationsState,
} from 'store/selectors/locations';
import { StyledEndIconCnt } from 'components/Dropdown/AssigneeDropdown/assigneeDropdownSelect.style';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CustomButton from 'components/Button/CustomButton';
import { setDialog } from 'store/actions/dialogActions';
import useResponsive from 'utils/CustomHooks/useResponsive';
import { HasPermission } from 'components/HasPermission';
import LocationItemsContainer from 'components/Dropdown/LocationsSelectItemsList/LocationItemsContainer';
import selectors from 'store/selectors';
import { isArray } from 'lodash';
import useLocationsUtils from 'utils/CustomHooks/useLocationsUtils';
import { Stack, Tooltip } from '@mui/material';
import ChildItemIcon from 'components/Icons/childItemIcon';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';

function LocationsList(props) {
  const locationsList = useSelector(getAuthorizedLocations);
  const locationsHash = useSelector(selectors.getLocationsHash);
  const dispatch = useDispatch();

  const { PERMISSIONS } = useCustomPermission();

  const {
    selectedOption,
    setSelectedOption,
    onAddLocationCallback,
    isMulti,
    closeAction,
    contentProps = {},
  } = props;

  const [isHideFooter, setIsHideFooter] = useState(false);

  const handleAddNewLocation = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: 'createLocation',
      }),
    );
    closeAction();
    onAddLocationCallback();
  };

  const searchQueryCallback = (data) => {
    setIsHideFooter(data?.isHideFooter);
  };

  return (
    <LocationItemsContainer
      data={locationsList}
      selected={selectedOption?.map((item) => item?.id) || []}
      searchQueryCallback={searchQueryCallback}
      changeHandler={(selectedId) => {
        const selectedLocation = locationsHash?.[selectedId];
        setSelectedOption(selectedLocation ? [selectedLocation] : []);
      }}
      containerProps={{
        style: {
          padding: '0px 10px',
        },
      }}
      contentProps={{
        isSearch: true,
        isSelectAll: false,
        isMulti: isMulti,
        addLocationByTag: true,
        style: {
          maxHeight: 300,
          overflow: 'auto',
        },
        ...contentProps,
      }}
      footerRenderer={
        isHideFooter ? (
          <></>
        ) : (
          <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_LOCATIONS]}>
            <AddLocationCnt>
              <CustomListItem
                checkbox={false}
                id={'addNewLocation-dropdown-button'}
                className={'addLocationBtn'}
                startIcon={<AddIcon sx={{ color: '#6868FE' }} />}
                onClick={handleAddNewLocation}
              >
                Add Location
              </CustomListItem>
            </AddLocationCnt>
          </HasPermission>
        )
      }
    />
  );
}
function LocationsDropdown(props) {
  const {
    popperProps = { style: {} },
    onAddLocationCallback,
    buttonProps = {},
    selected,
    taskData,
    editable = false,
    viewType = 'taskView',
    children,
    onChangeCallback,
    minOneSelected = false,
    isMulti,
    error = null,
    contentProps = {},
    dropdownBtnProps = {},
  } = props;

  const { getParentsNodes } = useLocationsUtils();
  const locationsHash = useSelector(selectors.getLocationsHash);
  const authLocationsHash = useSelector(selectors.getAuthLocationsHash);
  const allLocationsList = useSelector(getLocationsState);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState<any[]>([]);
  const { handleUpdateTask } = useTaskEdit(taskData);
  const { isMobileDeviceSm } = useResponsive();

  useEffect(() => {
    if (isArray(selected)) {
      let selectedDropdownOptions = selected?.map(
        (locationId) => locationsHash?.[locationId],
      );
      selectedDropdownOptions = selectedDropdownOptions?.filter(
        (value) => value !== null && value !== undefined,
      );
      setSelectedOption(selectedDropdownOptions);
    } else {
      const selectedDropdownOptions = locationsHash?.[selected];
      setSelectedOption(
        selectedDropdownOptions ? [selectedDropdownOptions] : [],
      );
    }
  }, [selected, allLocationsList]);

  const handleDropdownOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleDropdownClose = (event) => {
    setAnchorEl(null);
  };

  const handleChangeOption = async (values) => {
    if (!values.length && minOneSelected) return;

    if (selectedOption?.length <= 1 && minOneSelected) {
      setSelectedOption(values);
      onChangeCallback?.(values);
      editable &&
        (await handleUpdateTask({
          LocationId: values?.[0]?.id,
          Asset: null,
          AssetId: null,
          Location: null,
        }));
      return;
    }
    if (!minOneSelected) {
      //If same option is selected again, then deselect it
      const value = values?.[0]?.id == selectedOption?.[0]?.id ? null : values;
      setSelectedOption(value);
      onChangeCallback?.(value);
      editable &&
        (await handleUpdateTask({
          LocationId: values?.[0]?.id ?? null,
          Asset: null,
          AssetId: null,
          Location: null,
        }));
    }
  };

  const selectedText = useMemo(() => {
    if (selectedOption?.length === 1) {
      return selectedOption[0]?.name || selectedOption[0]?.label;
    }

    if (!selectedOption || selectedOption?.length == 0) {
      return taskData?.Location?.name;
    }

    return `${selectedOption?.length} Locations`;
  }, [selectedOption, taskData]);

  const parentLocationId = selectedOption?.[0]?.ParentId;

  const parentLocation = useMemo(() => {
    if (parentLocationId) {
      const parentNode = authLocationsHash?.[parentLocationId];
      return parentNode?.authorized ? parentNode : null;
    }

    return null;
  }, [parentLocationId]);

  const isSelected =
    selectedOption?.length || taskData?.Location?.name ? true : false;

  const allNodes = useMemo(() => {
    const locations = getParentsNodes(
      authLocationsHash,
      selectedOption?.[0] ?? [],
      true,
    );

    return locations;
  }, [selectedOption, authLocationsHash]);

  return (
    <ClickAwayListener
      onClickAway={handleDropdownClose}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <div>
        {viewType === 'taskView' && (
          <CustomToolTip title={'Location'}>
            <LocationDropdownButton
              variant="outlined"
              buttonType={isSelected ? 'grayWhite' : 'grayWhite-dashed'}
              onClick={handleDropdownOpen}
              startIcon={
                isSelected ? null : <LocationIcon sx={{ mr: '5px' }} />
              }
              isSelected={isSelected}
            >
              {isSelected ? selectedText : 'Location'}
            </LocationDropdownButton>
          </CustomToolTip>
        )}
        {viewType === 'tableView' && (
          <LocationDropdownButton
            variant={isSelected ? 'text' : 'outlined'}
            buttonType={isSelected ? 'gray' : 'grayWhite-dashed'}
            onClick={handleDropdownOpen}
            startIcon={isSelected ? null : <LocationIcon />}
            isSelected={isSelected}
            viewType="tableView"
          >
            {isSelected ? (
              <Tooltip
                arrow
                style={{
                  pointerEvents: allNodes?.length === 0 ? 'none' : 'all',
                }}
                PopperProps={{
                  sx: {
                    zIndex: 1300,
                    '& .MuiTooltip-tooltip': {
                      boxShadow:
                        'rgba(0, 0, 0, 0.2) 0px 10px 15px 3px, rgba(0, 0, 0, 0.2) 0px 4px 6px -2px',
                      minWidth: 100,
                      maxHeight: 300,
                      overflowY: 'auto',
                      borderRadius: '15px',
                    },
                  },
                }}
                title={
                  <DottedContainer>
                    {allNodes?.map((item, index) => {
                      if (index === 0) {
                        return <DottedText>{item?.name}</DottedText>;
                      }

                      return (
                        <Stack direction="row" alignItems="center" gap="10px">
                          <ChildItemIcon
                            sx={{
                              fontSize: 16,
                              color: 'white',
                              marginBottom: 1,
                            }}
                          />
                          <DottedText
                            sx={{
                              fontWeight:
                                index === allNodes?.length - 1 ? 700 : 500,
                            }}
                          >
                            {item?.name}
                          </DottedText>
                        </Stack>
                      );
                    })}
                  </DottedContainer>
                }
              >
                <LocationWithParentLocationCnt>
                  <span className="assetName"> {selectedText}</span>
                  {parentLocation && (
                    <span className="parentLocationName">
                      {parentLocation?.name}
                    </span>
                  )}
                </LocationWithParentLocationCnt>
              </Tooltip>
            ) : (
              'Add Location'
            )}
          </LocationDropdownButton>
        )}
        {viewType === 'select' && (
          <CustomButton
            variant="outlined"
            buttonType="grayWhite"
            onClick={handleDropdownOpen}
            endIcon={
              <StyledEndIconCnt>
                {/*commented for later use*/}
                {/*{selected && (*/}
                {/*  <IconButton onClick={handleClearOptions}>*/}
                {/*    <CloseIcon />*/}
                {/*  </IconButton>*/}
                {/*)}*/}
                <KeyboardArrowDownIcon style={{ fontSize: 24 }} />
              </StyledEndIconCnt>
            }
            style={{
              width: '100%',
              justifyContent: 'space-between',
              padding: '6px 17px 6px 10px',
              height: 40,
              borderRadius: 6,
              ...(error && { border: '1px solid #ef5350' }),
            }}
            {...dropdownBtnProps}
          >
            {isSelected ? selectedText : 'Location'}
          </CustomButton>
        )}

        <CustomDropdown
          paperProps={{
            sx: {
              overflow: 'hidden',
              paddingBottom: '0px',
              // maxHeight: 300,
              // overflowY: 'auto',
            },
          }}
          popperProps={{
            ...popperProps,
            content: (
              <LocationsList
                onAddLocationCallback={onAddLocationCallback}
                editable={editable}
                closeAction={handleDropdownClose}
                selectedOption={selectedOption}
                setSelectedOption={handleChangeOption}
                isMulti={isMulti}
                contentProps={contentProps}
              />
            ),
            style: {
              ...popperProps.style,
              zIndex: 9999,
              width: isMobileDeviceSm ? 320 : 400,
            },

            open: !!anchorEl,
            placement: 'bottom-start',
            anchorEl,
          }}
          buttonProps={buttonProps}
          clickaway={false}
        >
          {children}
        </CustomDropdown>
      </div>
    </ClickAwayListener>
  );
}

export default LocationsDropdown;
