import React, { useEffect, useRef, useState } from 'react';
import { hoursArr } from 'components/Dropdown/Task/DateRangePicker/constants';
import moment from 'moment';
import {
  DateInputCnt,
  DateTimeCnt,
  EndAddormentCnt,
  RepeatTaskCnt,
  TimeBtnsCnt,
  TimeCnt,
  TimeStamp,
} from 'components/Dropdown/Task/DateRangePicker/taskDateTimePickerDropdown.style';
import classNames from 'classnames';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import TimeIcon from '@mui/icons-material/AccessTime';
import CloseIcon from '@mui/icons-material/Close';
import RepeatTaskToggle from 'components/AllDialogs/tasks/editTask/repeatTaskToggle.cmp';
import CreateRepeatTaskToggle from 'components/AllDialogs/tasks/createTask/repeatTaskToggle.cmp';

import RepeatTaskView from 'components/AllDialogs/tasks/createTask/RepeatTaskView.cmp';
import { DatePicker } from 'components/Dropdown/DatePicker/datePicker.cmp';
import CustomButton from 'components/Button/CustomButton';
import useWorkingHours from 'utils/CustomHooks/useWorkingHours';

function CustomDateRangePicker({
  handleSetActiveDate,
  activeDate,
  startDate,
  dueDate,
  activeTime,
  setActiveTime,
  onStartDateChange,
  onDueDateChange,
  timePickerProps,
  onClearDueDate,
  onClearStartDate,
  taskTime,
  showRepeatTask,
  repeatTaskProps,
  editSeriesType,
  isCreate = false,
}) {
  const { Shift } = useWorkingHours();
  const { handleDueTimeChange, handleStartTimeChange } = timePickerProps;
  const timeCntRef: React.MutableRefObject<any> = useRef({});
  const [time, setTime] = useState<any>({ startTime: '', dueTime: '' });

  const startTime = time?.startTime;
  const dueTime = time?.dueTime;

  useEffect(() => {
    // Set the default scroll position to the bottom of the div element
    const elementHeight = 28.4;
    if (timeCntRef.current && activeDate == 'dueDate' && taskTime.dueTime) {
      const selectedTimeIndex = hoursArr.findIndex(
        (item) => item == taskTime?.dueTime,
      );
      timeCntRef.current.scrollTop = elementHeight * selectedTimeIndex;
    } else if (
      timeCntRef.current &&
      activeDate == 'startDate' &&
      taskTime.startTime
    ) {
      const selectedTimeIndex = hoursArr.findIndex(
        (item) => item == taskTime?.startTime,
      );
      timeCntRef.current.scrollTop = elementHeight * selectedTimeIndex;
    } else if (timeCntRef.current && (taskTime.startTime || taskTime.dueTime)) {
      const selectedTimeIndex = hoursArr.findIndex(
        (item) => item == Shift?.startTime,
      );
      timeCntRef.current.scrollTop = elementHeight * selectedTimeIndex;
    }
  }, [activeTime, activeDate, taskTime]);

  useEffect(() => {
    const preSelectedStartTime = taskTime.startTime;
    const preSelectedDueTime = taskTime.dueTime;
    setTime({
      startTime: preSelectedStartTime ? preSelectedStartTime : '',
      dueTime: preSelectedDueTime ? preSelectedDueTime : '',
    });
    if (preSelectedStartTime && activeDate == 'startDate') {
      handleSetActiveDate('startDate');
    } else if (preSelectedDueTime && activeDate == 'dueDate') {
      handleSetActiveDate('dueDate');
    }
  }, [taskTime]);

  const handleSelectTime = (value) => {
    const timeObj = {
      startTime: activeDate == 'startDate' ? value : startTime,
      dueTime: activeDate == 'dueDate' ? value : dueTime,
    };
    setTime(timeObj);
    activeDate == 'startDate' && handleStartTimeChange(value);
    activeDate == 'dueDate' && handleDueTimeChange(value);
  };
  const handleClearDate = (e, type) => {
    e.stopPropagation();
    if (type == 'startDate') {
      setTime({ ...time, startTime: '' });
      onClearStartDate();
    } else {
      setTime({ ...time, dueTime: '' });
      onClearDueDate();
    }
    //hide time selector on date clear
    setActiveTime(null);
  };
  const handleShowTimeSelection = (e, value) => {
    e.stopPropagation();
    setActiveTime(value == activeTime ? null : value);
    handleSetActiveDate(value == 'startTime' ? 'startDate' : 'dueDate');
    if (value == 'startTime') {
      const newStartDate = startDate ? startDate : new Date();
      handleStartDateSelect(newStartDate);
      setTime({ ...time, startTime: Shift?.startTime });
      handleStartTimeChange(Shift?.startTime);
      setActiveTime('startTime');
    }
    if (value == 'dueTime') {
      const newDueDate = dueDate ? dueDate : new Date();
      handleDueDateSelect(
        Shift?.isNightShift
          ? moment(newDueDate).add(1, 'day').toDate()
          : newDueDate,
      );
      setTime({ ...time, dueTime: Shift?.endTime });
      setActiveTime('dueTime');
      handleDueTimeChange(Shift?.endTime);
    }
  };

  const startTimeIndex = hoursArr?.indexOf(startTime);
  const dueTimeIndex = hoursArr?.indexOf(dueTime);
  const startDueDateDiff = moment(startDate).diff(dueDate, 'days');
  const isStartTimeDisabled = (i) => {
    return !!(
      startDueDateDiff == 0 &&
      dueTime &&
      dueTimeIndex &&
      i >= dueTimeIndex
    );
  };
  const isDueTimeDisabled = (i) => {
    return !!(
      startDueDateDiff == 0 &&
      startTime &&
      startTimeIndex &&
      i <= startTimeIndex
    );
  };
  const handleStartDateSelect = (value) => {
    onStartDateChange?.(value);
  };
  const handleDueDateSelect = (value) => {
    onDueDateChange?.(value);
  };

  const isStartDateDisabled = (day: Date) => {
    return dueDate ? moment(day).isAfter(dueDate, 'day') : false;
  };
  const isDueDateDisabled = (day: Date) => {
    return startDate ? moment(day).isBefore(startDate, 'day') : false;
  };
  return (
    <div>
      {!showRepeatTask ? (
        <DateTimeCnt isTimeActive={Boolean(activeTime)}>
          <div
            className={classNames({
              dateTimeInnerCnt: true,
              timeFieldActive: Boolean(activeTime),
            })}
          >
            <DateInputCnt activeDate={activeDate}>
              <CustomInput
                suppressErrorSpace={true}
                onClick={(e) => handleSetActiveDate('startDate')}
                labelProps={{
                  ...(activeDate == 'startDate' && { shrink: true }),
                }}
                label="Start date"
                fieldProps={{
                  readOnly: true,
                  placeholder: 'Start date',
                  ...(activeDate == 'startDate' && { notched: true }),
                  value: startDate
                    ? `${moment(startDate)?.format('MMM DD YY')} ${startTime}`
                    : '',
                  endAdornment: (
                    <EndAddormentCnt>
                      {startDate && (
                        <CloseIcon
                          sx={{ color: '#0000008A', fontSize: 16 }}
                          onClick={(e) => handleClearDate(e, 'startDate')}
                        />
                      )}
                    </EndAddormentCnt>
                  ),
                }}
                className="startDateInput"
              />
              <CustomInput
                suppressErrorSpace={true}
                labelProps={{
                  ...(activeDate == 'dueDate' && { shrink: true }),
                }}
                label="Due date"
                onClick={(e) => handleSetActiveDate('dueDate')}
                className="dueDateInput"
                style={{ marginRight: 0 }}
                fieldProps={{
                  readOnly: true,
                  ...(activeDate == 'dueDate' && { notched: true }),
                  placeholder: 'Due date',
                  value: dueDate
                    ? `${moment(dueDate).format('MMM DD YY')} ${dueTime}`
                    : '',
                  endAdornment: (
                    <EndAddormentCnt>
                      {dueDate && (
                        <CloseIcon
                          sx={{ color: '#0000008A', fontSize: 16 }}
                          onClick={(e) => handleClearDate(e, 'dueDate')}
                        />
                      )}
                    </EndAddormentCnt>
                  ),
                }}
              />
            </DateInputCnt>

            <TimeBtnsCnt>
              {!taskTime?.startTime ? (
                <CustomButton
                  variant={'text'}
                  color={'primary'}
                  onClick={(e) => handleShowTimeSelection(e, 'startTime')}
                  startIcon={
                    <TimeIcon sx={{ color: '#0000008A', fontSize: 16 }} />
                  }
                >
                  + Start Time
                </CustomButton>
              ) : (
                <div style={{ flex: 1, padding: '0 8px' }}></div>
              )}
              {!taskTime?.dueTime ? (
                <CustomButton
                  variant={'text'}
                  color={'primary'}
                  onClick={(e) => handleShowTimeSelection(e, 'dueTime')}
                  startIcon={
                    <TimeIcon sx={{ color: '#0000008A', fontSize: 16 }} />
                  }
                >
                  + Due Time
                </CustomButton>
              ) : (
                <div style={{ flex: 1, padding: '0 8px' }}></div>
              )}
            </TimeBtnsCnt>

            {activeDate == 'startDate' && (
              <DatePicker
                setSelectedDay={handleStartDateSelect}
                selectedDay={startDate}
                datePickerProps={{ disabled: isStartDateDisabled }}
              />
            )}
            {activeDate == 'dueDate' && (
              <DatePicker
                setSelectedDay={handleDueDateSelect}
                selectedDay={dueDate}
                datePickerProps={{ disabled: isDueDateDisabled }}
              />
            )}

            {isCreate && (
              <RepeatTaskCnt>
                <CreateRepeatTaskToggle {...repeatTaskProps} />
                <RepeatTaskView {...repeatTaskProps} />
              </RepeatTaskCnt>
            )}
          </div>
          {activeDate && activeTime ? (
            <TimeCnt>
              <div className="timeTypeHeading">
                {activeTime == 'startTime' ? 'Start time' : 'Due time'}
              </div>
              <div className="timeStampList" ref={timeCntRef}>
                {hoursArr.map((h, i) => {
                  const isTimeDisabled =
                    activeTime == 'startTime'
                      ? isStartTimeDisabled(i)
                      : isDueTimeDisabled(i);
                  return (
                    <TimeStamp
                      isSelected={
                        activeTime == 'startTime'
                          ? h == startTime
                          : h == dueTime
                      }
                      disabled={isTimeDisabled}
                      onClick={() => handleSelectTime(h)}
                    >
                      {h}
                    </TimeStamp>
                  );
                })}
              </div>
            </TimeCnt>
          ) : null}
        </DateTimeCnt>
      ) : showRepeatTask ? (
        <RepeatTaskCnt>
          {isCreate ? (
            <CreateRepeatTaskToggle {...repeatTaskProps} />
          ) : (
            <RepeatTaskToggle
              editSeriesType={editSeriesType}
              {...repeatTaskProps}
            />
          )}
        </RepeatTaskCnt>
      ) : null}
    </div>
  );
}

export default CustomDateRangePicker;
