// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '12px',
  width: '100%',
  borderRadius: '10px',
}));

export const MetaWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 6,
  width: '100%',
}));

export const AvatarAndDropdown = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'space-between',
}));

export const AvatarAndName = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: 8,
  maxWidth: 375,
}));

export const SubmitterNameAndEmail = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const SubmitterName = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  fontWeight: '600',
  letterSpacing: '0.15px',
  color: 'rgba(33, 33, 33, 1)',
}));

export const SubmitterEmail = styled(Typography)(({ theme }) => ({
  fontSize: '15px',
  fontWeight: '400',
  lineHeight: '21.45px',
  color: 'rgba(66, 66, 66, 1)',
  marginTop: '-4px',
}));

export const DateAndIdWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 16,
  marginLeft: 32,
}));

export const DateWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 6,
}));

export const DateDot = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: 5,
  height: 5,
  borderRadius: 5,
  background: 'rgba(117, 117, 117, 1)',
}));

export const GreyText = styled(Typography, {
  shouldForwardProp: (prop) => !['dark'].includes(prop),
})(({ theme, dark }) => ({
  fontSize: '14px',
  fontWeight: '600',
  letterSpacing: '0.1px',
  lineHeight: '21.98px',
  color: dark ? 'rgba(97,97,97,1)' : 'rgba(117, 117, 117, 1)',
}));

export const Divider = styled(Box)(({ theme }) => ({
  width: 1,
  height: '100%',
  background: 'rgba(0, 0, 0, 0.12)',
}));

export const WorkOrderId = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: '700',
  letterSpacing: '0.17px',
  lineHeight: '20.02px',
  color: 'rgba(104, 104, 254, 1)',
  textDecoration: 'underline',
  cursor: 'pointer',
}));

export const ReportStatusWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 32,
}));

export const ReportAndCreateWOCnt = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const CreateWorkOrder = styled(CustomButton)(({ theme }) => ({
  color: '#424242',
  fontWeight: 400,
  fontSize: 13,
  lineHeight: '18px',
  letterSpacing: '0.16px',
  border: '1px solid #E0E0E0',
  borderRadius: 16,
  padding: '6px 12px 6px 12px',
  '& .addTaskIcon': {
    color: '#757575',
  },
}));

export const StatusDot = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'color',
})(({ theme, color }) => ({
  display: 'flex',
  width: 10,
  height: 10,
  borderRadius: 10,
  background: color,
  marginRight: 8,
}));

export const AnonymousAvatar = styled(Box)(({ theme }) => ({
  height: 24,
  width: 24,
  borderRadius: 24,
  background: 'rgba(96, 125, 139, 1)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const AvatarWrapper = styled(Box)(({ theme }) => ({
  marginTop: 4,
}));
