import React, { FC, useState, useCallback } from 'react';
import Stack from '@mui/material/Stack';
import { Alert, DialogActions, Divider, IconButton } from '@mui/material';
import Cover from './components/cover';
import { getEntityPreview } from './../automation/utils/getEntityPreview';
import AddIcon from '@mui/icons-material/Add';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Content } from './styled';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import MultipleUserDropdown from 'components/Dropdown/MultipleUserDropdown/MultipleUserDropdown';

import EmailField from './components/emailField';
import CustomButton from 'components/Button/CustomButton';
import { CustomSelect } from 'components/CustomSelect/CustomSelect';
import { useDispatch } from 'react-redux';
import actions from 'store/actions';
import DialogIds from 'utils/dialogIds';
import xeniaApi from 'api/index';
import {
  getAnswerView,
  getConditionText,
} from 'components/common/jsxrender/checklistV2/builder/automations/previewItem';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

interface Automation {
  id: string;
  conditions: any[];
  actions: any[];
}

interface IProps {
  field: any;
  automation: Automation;
  onClose(): void;
  onSave(): any;
  checklistId?: any;
}

const sentOptions = [
  { key: 'immediate', value: 'immediate', label: 'Immediately' },
  { key: 'onSubmit', value: 'onSubmit', label: 'When Submitted' },
];

const DialogContent: FC<IProps> = (props) => {
  const { field, automation, onClose, onSave, checklistId } = props;
  const dispatch = useDispatch();
  const { workspaceId } = useWorkspaceHook();

  const [action] = automation?.actions || [];
  const [condition] = automation?.conditions || [];

  const [userOrTeamIds, setUserOrTeamIds] = useState<string[]>(
    action?.data?.push?.recipients || [],
  );
  const [emails, setEmails] = useState<string[]>(
    action?.data?.email?.recipients || [],
  );
  const [sentOption, setSentOption] = useState(
    sentOptions.find((o) => o.value === action?.data?.email?.when) ||
      sentOptions[0],
  );
  const [isSMSEnabled, setIsSMSEnabled] = useState(
    action?.data?.sms?.enabled || false,
  );
  const [isPushEnabled, setIsPushEnabled] = useState(
    action?.data?.push?.enabled || false,
  );
  const [isWhatsAppEnabled, setIsWhatsAppEnabled] = useState(
    action?.data?.whatsapp?.enabled || false,
  );
  const [addEmail, setAddEmail] = useState(false);

  const [isEmpty, setIsEmpty] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const saveAndClose = useCallback(async () => {
    setIsLoading(true);
    if (userOrTeamIds.length === 0 && emails.length === 0) {
      setIsEmpty(true);
      return;
    }

    const data = {
      sms: {
        enabled: isSMSEnabled,
        when: sentOption.value,
        recipients: userOrTeamIds,
      },
      push: {
        enabled: isPushEnabled,
        when: sentOption.value,
        recipients: userOrTeamIds,
      },
      whatsapp: {
        enabled: isWhatsAppEnabled,
        when: sentOption.value,
        recipients: userOrTeamIds,
      },
      email: {
        enabled: emails.length > 0,
        when: sentOption.value,
        recipients: emails,
      },
    };

    const newAction = { ...action, data };
    const updatedAutomation = {
      ...automation,
      actions: [newAction],
      ChecklistId: checklistId,
    };
    await xeniaApi.upsertAutomations({
      automations: [updatedAutomation],
    });

    if (workspaceId) {
      dispatch(
        actions.fetchChecklistItemsAutomations(workspaceId, field.checklistId),
      );
    }
    await onSave?.();
    dispatch(
      actions.setDialog({
        dialogId: DialogIds.NOTIFICATION_AUTOMATION,
        open: false,
        data: null,
      }),
    );
    setIsLoading(false);
  }, [
    isSMSEnabled,
    isPushEnabled,
    isWhatsAppEnabled,
    sentOption,
    userOrTeamIds,
    emails,
    automation,
    action,
    workspaceId,
  ]);

  return (
    <>
      <Content>
        <Stack spacing={1}>
          {isEmpty && (
            <Alert variant="filled" severity="error">
              Please select at least one user, team or add an email recipient
            </Alert>
          )}
          <Cover
            title={field.description}
            preview={getEntityPreview(field)}
            conditionText={getConditionText(condition.logic)}
            answerView={getAnswerView(field, condition)}
          />

          <Accordion
            sx={{
              width: '100%',
              boxShadow: 'none',
              '&:before': {
                display: 'none',
              },
            }}
            TransitionProps={{ unmountOnExit: true }}
            defaultExpanded
            disableGutters
            square={false}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                background: '#EEEEEE',
                height: '40px !important',
                minHeight: 'initial !important',
                borderRadius: '8px',
              }}
            >
              <Typography fontWeight={700} fontSize="14px" color="#212121">
                SMS and Push Notification
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ px: 0, pb: 0 }}>
              <Typography fontSize="14px" fontWeight={600}>
                Recipients
              </Typography>
              <MultipleUserDropdown
                handleChangeCallback={(options) => {
                  setUserOrTeamIds(options);
                }}
                selected={userOrTeamIds}
                renderChips
                showRoles={true}
                placeholder="Select users, teams or roles"
              />

              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      disableRipple
                      checked={isSMSEnabled}
                      onChange={(e) => {
                        setIsSMSEnabled(e.target.checked);
                      }}
                    />
                  }
                  label={<Typography fontSize="14px">SMS</Typography>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disableRipple
                      checked={isPushEnabled}
                      onChange={(e) => {
                        setIsPushEnabled(e.target.checked);
                      }}
                    />
                  }
                  label={
                    <Typography fontSize="14px">Push Notification</Typography>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disableRipple
                      checked={isWhatsAppEnabled}
                      onChange={(e) => {
                        setIsWhatsAppEnabled(e.target.checked);
                      }}
                    />
                  }
                  label={
                    <Typography fontSize="14px">
                      WhatsApp Notification
                    </Typography>
                  }
                />
              </FormGroup>
            </AccordionDetails>
          </Accordion>
          <Divider sx={{ width: '100%', my: 2 }} />
          <Accordion
            sx={{
              width: '100%',
              boxShadow: 'none',
              '&:before': {
                display: 'none',
              },
            }}
            TransitionProps={{ unmountOnExit: true }}
            defaultExpanded
            disableGutters
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
              sx={{
                background: '#EEEEEE',
                height: '40px !important',
                minHeight: 'initial !important',
                borderRadius: '8px',
              }}
            >
              <Typography fontWeight={700} fontSize="14px" color="#212121">
                Email Notifications
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ px: 0, pb: 0 }}>
              {emails.map((email, index) => (
                <>
                  <EmailField
                    value={email}
                    onDelete={() => {
                      setEmails((prevEmails) => {
                        const newEmails = [...prevEmails];
                        newEmails.splice(index, 1);
                        return newEmails;
                      });
                    }}
                    onSave={(value) => {
                      if (value) {
                        setEmails((prevEmails) => {
                          const newEmails = [...prevEmails];
                          newEmails[index] = value;
                          return newEmails;
                        });
                      }
                    }}
                  />
                  {emails.length - 1 !== index && <Divider />}
                </>
              ))}
              {!addEmail && (
                <CustomButton
                  onClick={() => setAddEmail(true)}
                  variant="text"
                  startIcon={<AddIcon />}
                >
                  Add Email Recipient
                </CustomButton>
              )}
              {addEmail && (
                <EmailField
                  value=""
                  onCancel={() => {
                    setAddEmail(false);
                  }}
                  onSave={(value) => {
                    if (value) {
                      setEmails((prevEmails) => [...prevEmails, value]);
                    }
                    setAddEmail(false);
                  }}
                />
              )}
            </AccordionDetails>
          </Accordion>

          <Divider sx={{ width: '100%', my: '12px' }} />
          <Stack
            direction="row"
            spacing={1}
            width="100%"
            alignItems="center"
            mb="6px"
          >
            <IconButton sx={{ background: '#F5F5F5' }}>
              <EventOutlinedIcon style={{ fontSize: '20px' }} />
            </IconButton>
            <Typography color="#212121" fontSize="16px" fontWeight={600}>
              Notification Timing
            </Typography>
          </Stack>
          <Typography
            width="100%"
            color="#424242"
            fontWeight={600}
            fontSize="14px"
            mb="4px"
          >
            Set when this notification should be sent
          </Typography>
          <CustomSelect
            style={{ width: '100%' }}
            options={sentOptions}
            selectedOption={sentOption}
            onChange={(option) => setSentOption(option)}
          />
        </Stack>
      </Content>
      <DialogActions
        sx={{ padding: '16px 22px', borderTop: '1px solid #eff0ef' }}
      >
        <CustomButton disabled={isLoading} variant="outlined" onClick={onClose}>
          Go Back
        </CustomButton>
        <CustomButton
          buttonLoader={isLoading}
          variant="contained"
          onClick={saveAndClose}
        >
          Save
        </CustomButton>
      </DialogActions>
    </>
  );
};

export default DialogContent;
