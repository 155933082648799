// React
import { useState, useEffect, FC } from 'react';

// Redux

// MUI components
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

// Custom components
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import CustomButton from 'components/Button/CustomButton';

// Custom icons
import { StyledEndIconCnt } from 'components/Dropdown/DatePicker/datePicker.style';
import CalendarIconRoundedWithDots from 'components/Icons/calendarIconRoundedWithDots';

// Utilities
import { DayPicker } from 'react-day-picker-latest';
import 'components/Dropdown/DatePicker/style.css';
import moment from 'moment/moment';
import useDateTime, { tzDateFormat } from 'utils/CustomHooks/useDateTime';
import Stack from '@mui/material/Stack';

interface PickerProps {
  setSelectedDay?: (selectedDate?: any) => void;
  selectedDay?: Date;
  datePickerProps?: any;
}

export function DatePicker({
  setSelectedDay,
  selectedDay,
  datePickerProps,
}: PickerProps) {
  const { disabled } = datePickerProps;
  return (
    <div className="customDatePicker">
      <DayPicker
        mode="single"
        selected={selectedDay}
        onSelect={setSelectedDay}
        disabled={disabled}
      />
    </div>
  );
}

interface IProps {
  selected: Date | null;
  children?: React.ReactNode;
  onDateSelect: (selectedDate?: any) => void;
  popperProps?: any;
  viewType?: 'taskView' | 'otherView';
  buttonProps?: any;
  disabled?: boolean;
  datePickerProps?: any;
  defaultLabel?: string;
}

const ScheduleDatePickerDropdown: FC<IProps> = (props) => {
  const {
    popperProps = { style: {} },
    buttonProps = {},
    children,
    onDateSelect,
    viewType = 'taskView',
    selected,
    datePickerProps = {},
    disabled = false,
    defaultLabel = 'Now',
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDay, setSelectedDay] = useState<any>();

  const { getUtcToTz, getDateInTz } = useDateTime();

  useEffect(() => {
    const date = selected
      ? moment(getUtcToTz(selected, tzDateFormat)).toDate()
      : null;

    setSelectedDay(date);
  }, [selected]);

  const handleDropdownOpen = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleDateSelect = (date) => {
    const dateInTz = getDateInTz(date).format();
    setSelectedDay(date);
    onDateSelect(dateInTz);
    setAnchorEl(null);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ClickAwayListener
        onClickAway={handleDropdownClose}
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
      >
        <div>
          {viewType === 'taskView' && (
            <CustomButton
              disabled={disabled}
              variant="outlined"
              buttonType="grayWhite"
              onClick={handleDropdownOpen}
              endIcon={
                <StyledEndIconCnt>
                  <CalendarIconRoundedWithDots style={{ fontSize: 18 }} />
                </StyledEndIconCnt>
              }
              style={{
                width: '100%',
                justifyContent: 'space-between',
                padding: '6px 17px 6px 10px',
                height: 42,
                color: !selectedDay ? '#9E9E9E' : 'unset',
              }}
              {...buttonProps}
            >
              {selectedDay
                ? moment(selectedDay).format('MM/DD/YYYY')
                : defaultLabel}
            </CustomButton>
          )}

          <CustomDropdown
            popperProps={{
              ...popperProps,
              content: (
                <Stack>
                  <Stack direction="column">
                    <div style={{ padding: '4px 10px 0px 10px' }}>
                      <CustomButton
                        variant="outlined"
                        sx={{ padding: '6px 16px', width: '100%' }}
                        onClick={() => {
                          onDateSelect(null);
                          handleDropdownClose();
                        }}
                      >
                        Now
                      </CustomButton>
                    </div>
                    <DatePicker
                      setSelectedDay={handleDateSelect}
                      selectedDay={selectedDay}
                      datePickerProps={datePickerProps}
                    />
                  </Stack>
                </Stack>
              ),
              style: {
                ...popperProps.style,
                zIndex: 9999,
              },
              open: !!anchorEl,
              placement: 'bottom-start',
              anchorEl,
            }}
            clickaway={false}
          >
            {children}
          </CustomDropdown>
        </div>
      </ClickAwayListener>
    </LocalizationProvider>
  );
};

export default ScheduleDatePickerDropdown;
