import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { Box, Grid, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CustomDialog from 'components/CustomDialog/customDialog';
import CustomButton from 'components/Button/CustomButton';
import { setDialog } from 'store/actions/dialogActions';
import selectors from 'store/selectors';
import DIALOGS from 'utils/dialogIds';
import useCustomRoles from 'utils/CustomHooks/useCustomRoles';
import CustomRolesSetPermissions from './setPermissions';
import CustomRolesAssign from './assignRoles';
import CustomRolesDetails from './roleDetails';
import { CustomRolesDetailFormContext } from './context';
import { SIDE_MENU_ITEMS, STEPS } from './constants';

import {
  ContentCnt,
  DialogHeader,
  Footer,
  LeftPanelContent,
  MenuItem,
  RightPanelContent,
  SelectedMenuItem,
  SideMenu,
} from './style';
import { getUserProfile } from 'store/selectors/auth';
import { isEqual } from 'lodash';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

const CustomRolesDetailFormView: React.FC = () => {
  const dispatch = useDispatch();
  const {
    createCustomRole,
    updateCustomRoles,
    getCustomRoles,
    transformDetailsIntoFormData,
  } = useCustomRoles();

  const { workspaceId } = useWorkspaceHook();

  const userProfileData: any = useSelector(getUserProfile);

  const { state, updateState } = useContext(CustomRolesDetailFormContext);
  const dialogState = useSelector(selectors.getDialogState);
  const { open, data } = dialogState[DIALOGS.CUSTOM_ROLES_SET_DETAILS] || {};
  const getRolesQuery = useMutation(getCustomRoles);

  const createRoleQuery = useMutation(createCustomRole, {
    onSuccess: (response) => {
      if (response?.data) {
        const isLoggedInUser = state?.assignees?.includes(userProfileData?.id);

        if (isLoggedInUser) {
          dispatch(
            setDialog({
              dialogId: DIALOGS.CONFIRMATION,
              open: true,
              data: {
                confirmButtonProps: {
                  style: {
                    backgroundColor: '#D32F2F',
                  },
                },
                hideCrossIcon: true,
                removeClickAwayClose: true,
                hideCancelBtn: true,
                ContentTemplate: PermissionsChangedTemplate,
                confirmationText: 'Reload',
                title: 'Your Role Changed',
                onConfirmCallback: () => {
                  window.location.href = `/ws/${workspaceId}/users-and-teams?tab=roles`;
                },
              },
            }),
          );
        } else {
          data?.onSuccessCB?.();
        }

        // Refresh roles list after successful role creation
        getRolesQuery.mutate({ payload: {}, forceLoad: true });
        handleClose();
      }
    },
    onError: (error) => {
      console.error('Error submitting form:', error);
    },
  });

  const PermissionsChangedTemplate = () => {
    return (
      <>
        <Typography fontSize="16px" fontWeight="500">
          Seems like your permissions has been updated, Please click on reload
          to apply it accordingly!
        </Typography>
      </>
    );
  };

  const updateRoleQuery = useMutation(updateCustomRoles, {
    onSuccess: (response) => {
      if (response) {
        const usersList =
          data?.role?.HotelUsers?.map((item) => item?.UserId) ?? [];

        const oldPermissionsList =
          data?.role?.RolePermissions?.map((item) => item?.PermissionId) ?? [];

        const newPermissions = state?.permissions;

        const isPermissionChanged = !isEqual(
          oldPermissionsList,
          newPermissions,
        );

        const isLoggedInUser = usersList?.includes(userProfileData?.id);

        if (isLoggedInUser && isPermissionChanged) {
          dispatch(
            setDialog({
              dialogId: DIALOGS.CONFIRMATION,
              open: true,
              data: {
                confirmButtonProps: {
                  style: {
                    backgroundColor: '#D32F2F',
                  },
                },
                hideCrossIcon: true,
                removeClickAwayClose: true,
                hideCancelBtn: true,
                ContentTemplate: PermissionsChangedTemplate,
                confirmationText: 'Reload',
                title: 'Your Permissions Changed',
                onConfirmCallback: () => {
                  window.location.href = `/ws/${workspaceId}/users-and-teams?tab=roles`;
                },
              },
            }),
          );
        } else {
          data?.onSuccessCB?.();
        }

        handleClose();
      }
    },
    onError: (error) => {
      console.error('Error submitting form:', error);
    },
  });

  useEffect(() => {
    if (data?.role) {
      // Transform data into form state format and update context state
      const transformedData = transformDetailsIntoFormData(data?.role);
      updateState({ ...transformedData });
    }
  }, [data]);

  const handleClose = useCallback(() => {
    dispatch(
      setDialog({ open: false, dialogId: DIALOGS.CUSTOM_ROLES_SET_DETAILS }),
    );
  }, [dispatch]);

  const createRole = useCallback(() => {
    // Prepare payload for role creation
    const payload = {
      permissions: state?.permissions,
      users: state?.assignees,
      title: state?.details?.title,
      description: state?.details?.description,
      reportTo: state?.details?.reportTo,
    };
    createRoleQuery.mutate(payload);
  }, [state, createRoleQuery]);

  const updateRole = useCallback(() => {
    // Prepare payload for role creation
    const payload = {
      permissions: state?.permissions,
      title: state?.details?.title,
      description: state?.details?.description,
      reportTo: state?.details?.reportTo,
    };

    updateRoleQuery.mutate({ payload, roleId: data?.role?.id });
  }, [state, data]);

  const handleSubmit = useCallback(() => {
    // Handle form steps and final submission

    switch (state?.step) {
      case STEPS[0]:
        updateState({ step: STEPS[1] });
        break;
      case STEPS[1]:
        data?.isEdit ? updateRole() : updateState({ step: STEPS[2] });
        break;
      case STEPS[2]:
        createRole();
        break;
      default:
        return;
    }
  }, [state]);

  const goBackStep = useCallback(() => {
    // Go back to the previous step
    const index = STEPS.indexOf(state?.step);
    updateState({ step: STEPS[index - 1] });
  }, [state]);

  const disableNextAction = useCallback(() => {
    // Disable next action button if required fields are not filled
    if (state?.step === STEPS[0]) {
      return !(state?.details?.title && state?.details?.description);
    }
    return false;
  }, [state]);

  const MENU_STEPS = useMemo(() => {
    return data?.isEdit ? STEPS.slice(0, 2) : STEPS;
  }, [STEPS]);

  return (
    <CustomDialog
      hideActions
      header={
        <DialogHeader
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography className="title">
            {data?.isEdit ? 'Edit Role' : 'Create New Role'}
          </Typography>
          <CloseIcon
            onClick={handleClose}
            sx={{ fontSize: '22px', cursor: 'pointer', color: '#1C1B1F' }}
          />
        </DialogHeader>
      }
      dialogProps={{
        open,
        maxWidth: false,
        onClose: handleClose,
        fullScreen: true,
        sx: { '& .MuiPaper-root': { borderRadius: 'unset' } },
      }}
    >
      <ContentCnt>
        <Grid container sx={{ height: '100%' }}>
          <Grid item xs={2}>
            <LeftPanelContent>
              <SideMenu>
                {MENU_STEPS.map((step, index) =>
                  step === state?.step ? (
                    <SelectedMenuItem
                      key={step}
                      alignItems="center"
                      direction="row"
                      gap="10px"
                    >
                      <Box className="numberCnt">{index + 1}</Box>
                      <Typography className="content">
                        {SIDE_MENU_ITEMS[step].menuLabel}
                      </Typography>
                    </SelectedMenuItem>
                  ) : (
                    <MenuItem
                      key={step}
                      alignItems="center"
                      direction="row"
                      gap="10px"
                    >
                      <Box className="numberCnt">{index + 1}</Box>
                      <Typography className="content">
                        {SIDE_MENU_ITEMS[step].menuLabel}
                      </Typography>
                    </MenuItem>
                  ),
                )}
              </SideMenu>
            </LeftPanelContent>
          </Grid>
          <Grid item xs={10}>
            <RightPanelContent>
              {state?.step === STEPS[0] && (
                <CustomRolesDetails isEdit={data?.isEdit} />
              )}
              {state?.step === STEPS[1] && (
                <CustomRolesSetPermissions isEdit={data?.isEdit} />
              )}
              {state?.step === STEPS[2] && <CustomRolesAssign />}
            </RightPanelContent>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Footer direction="row" alignItems="center">
            {state?.step === STEPS[0] ? (
              <CustomButton
                variant="outlined"
                sx={{ padding: '2px 16px' }}
                onClick={handleClose}
              >
                Cancel
              </CustomButton>
            ) : (
              <CustomButton
                variant="outlined"
                sx={{ padding: '2px 16px' }}
                onClick={goBackStep}
              >
                Go Back
              </CustomButton>
            )}
            <CustomButton
              variant="contained"
              sx={{ padding: '2px 16px' }}
              onClick={handleSubmit}
              buttonLoader={createRoleQuery.isLoading}
              disabled={disableNextAction()}
            >
              {state?.step === STEPS[1] && data?.isEdit
                ? 'Update Role'
                : SIDE_MENU_ITEMS[state?.step].nextActionLabel}
            </CustomButton>
          </Footer>
        </Grid>
      </ContentCnt>
    </CustomDialog>
  );
};

export default CustomRolesDetailFormView;
