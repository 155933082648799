import { useState, useCallback, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Avatar, Box, Stack, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CustomButton from 'components/Button/CustomButton';
import CustomRolesIcon from 'components/Icons/customRolesIcon';
import RolesAssigneeDropdown from 'components/Dropdown/RolesAssigneeDropdown/rolesList';
import RolesLocationsAssigneeDropdown from 'components/Dropdown/RolesAssigneeDropdown/locationsList';
import LocationIcon from 'components/Icons/locationIcon';
import MapMarkerIcon from 'components/Icons/mapMarkerIcon';

import {
  getAuthorizedLocations,
  getLocationsHash,
} from 'store/selectors/locations';
import { getRoles } from 'store/selectors/roles';
import { LocationChip } from './style';
import useLocationsUtils from 'utils/CustomHooks/useLocationsUtils';
import { getLocationGroupsState } from 'store/selectors/locationGroups';

interface SelectedState {
  roles: string[];
  allLocations: string[];
  locations: string[];
  locationGroups: string[];
}

const AssignToRoles = ({
  handleChangeCallback,
  currentSelected,
  rolesProps,
  customParentBtnRenderer,
  popperProps = {
    disablePortal: false,
    style: {
      zIndex: 1300,
      width: 605,
    },
  },
}: any) => {
  const rolesList = useSelector(getRoles)?.list || [];
  const authorizedLocations = useSelector(getAuthorizedLocations);
  const locationsHash = useSelector(getLocationsHash);

  const { getSublocationIdsById } = useLocationsUtils();

  const [selected, setSelected] = useState<SelectedState>({
    roles: [],
    allLocations: [],
    locations: [],
    locationGroups: [],
  });
  const groupList = useSelector(getLocationGroupsState);

  useEffect(() => {
    if (currentSelected) {
      let allLocations: string[] = [];
      for (let index = 0; index < currentSelected?.locations?.length; index++) {
        allLocations = [...allLocations, currentSelected?.locations?.[index]];

        const subLocations =
          getSublocationIdsById(
            currentSelected?.locations?.[index],
            locationsHash,
          ) ?? [];

        allLocations = [...allLocations, ...subLocations];
      }

      setSelected({ ...currentSelected, allLocations });
    }
  }, [locationsHash, currentSelected]);

  // Handle role change
  const handleChangeRole = useCallback(
    (selectedRole: string | null) => {
      const updatedSelected = {
        roles: selectedRole ? [selectedRole] : [],
        allLocations: [],
        locations: [],
        locationGroups: [],
      };
      setSelected(updatedSelected);
      handleChangeCallback?.(updatedSelected);
    },
    [handleChangeCallback],
  );

  // Handle role locations change
  const handleChangeRoleLocations = useCallback(
    (
      _: string | null,
      selectedLocations: string[],
      parentLocationIds: string[],
    ) => {
      const updatedSelected = {
        ...selected,
        allLocations: selectedLocations,
        locations: parentLocationIds,
        locationGroups: [],
      };
      setSelected(updatedSelected);
      handleChangeCallback?.(updatedSelected);
    },
    [selected, handleChangeCallback],
  );

  const handleChangeCallbackLocationsGroups = useCallback(
    (_: string | null, selectedLocationsGroups: string[]) => {
      const updatedSelected = {
        ...selected,
        allLocations: [],
        locations: [],
        locationGroups: selectedLocationsGroups,
      };
      setSelected(updatedSelected);
      handleChangeCallback?.(updatedSelected);
    },
    [selected, handleChangeCallback],
  );

  // Selected role and locations
  const selectedRole = useMemo(() => {
    return selected?.roles?.length > 0
      ? rolesList?.find((role) => role?.id === selected?.roles?.[0])
      : null;
  }, [selected?.roles, rolesList]);

  const selectedLocations = useMemo(() => {
    return selected?.locations?.map(
      (locationId) => locationsHash?.[locationId]?.name,
    );
  }, [selected?.locations]);

  const selectedLocationGroup = useMemo(() => {
    return groupList?.filter((group) => {
      if (group?.id === selected?.locationGroups?.[0]) {
        return true;
      }
      return false;
    });
  }, [selected?.locationGroups, groupList]);

  const renderCustomButton = (icon: JSX.Element, content: JSX.Element) => (
    <CustomButton
      variant="outlined"
      buttonType="grayWhite"
      endIcon={
        <Box className="endIconCnt">
          <KeyboardArrowDownIcon style={{ fontSize: 24, color: '#00000099' }} />
        </Box>
      }
      style={{
        width: '100%',
        justifyContent: 'space-between',
        padding: '6px 17px 6px 10px',
        height: 40,
        borderRadius: 6,
      }}
    >
      <Stack direction="row" gap="10px" alignItems="center">
        {icon}
        {content}
      </Stack>
    </CustomButton>
  );

  return (
    <>
      <RolesAssigneeDropdown
        buttonRenderer={
          customParentBtnRenderer ||
          renderCustomButton(
            selectedRole ? (
              <Avatar
                sx={{
                  height: '24px',
                  width: '24px',
                  backgroundColor: '#26A69A',
                  fontSize: '12px',
                }}
                alt={selectedRole?.title}
              >
                {selectedRole?.title?.[0]}
              </Avatar>
            ) : (
              <CustomRolesIcon
                sx={{
                  fill: 'none',
                  height: 18,
                  width: 18,
                  '& path': {
                    stroke: '#616161',
                  },
                }}
              />
            ),
            <Typography className="placeholder">
              {selectedRole?.title || 'Select role'}
            </Typography>,
          )
        }
        handleChangeCallback={handleChangeRole}
        selected={selected?.roles ?? []}
        listProps={{
          roles: {
            selected: selected.roles,
          },
        }}
        popperProps={popperProps}
      />

      {selectedRole && !rolesProps?.hideLocations && (
        <Box sx={{ marginTop: '8px' }}>
          <RolesLocationsAssigneeDropdown
            data={authorizedLocations}
            locationsGroupsData={groupList}
            selected={selected?.allLocations}
            selectedLocationsGroups={selected?.locationGroups}
            selectedParents={selected?.locations}
            showTabs={true}
            buttonRenderer={renderCustomButton(
              selectedLocationGroup?.length > 0 ? (
                <MapMarkerIcon
                  sx={{
                    fill: 'none',
                    height: 20,
                    width: 20,
                    '& path': {
                      stroke: '#616161',
                      strokeOpacity: 1,
                    },
                  }}
                />
              ) : (
                <LocationIcon
                  sx={{
                    fill: 'none',
                    height: 20,
                    width: 20,
                    '& path': {
                      stroke: '#616161',
                      strokeOpacity: 1,
                    },
                  }}
                />
              ),
              selectedLocations.length > 0 ? (
                <Stack direction="row" gap="6px">
                  {selectedLocations.slice(0, 3).map((location, index) => (
                    <LocationChip key={index}>
                      <Typography className="label">{location}</Typography>
                    </LocationChip>
                  ))}
                  {selectedLocations.length > 3 && (
                    <LocationChip>
                      <Typography className="label">
                        +{selectedLocations.length - 3}
                      </Typography>
                    </LocationChip>
                  )}
                </Stack>
              ) : selectedLocationGroup?.length > 0 ? (
                <LocationChip>
                  <Typography className="label">
                    {selectedLocationGroup?.[0]?.name}
                  </Typography>
                </LocationChip>
              ) : (
                <Typography className="placeholder">All Locations</Typography>
              ),
            )}
            handleChangeCallback={handleChangeRoleLocations}
            handleChangeCallbackLocationsGroups={
              handleChangeCallbackLocationsGroups
            }
            popperProps={{
              disablePortal: false,
              style: {
                zIndex: 1300,
                width: 605,
              },
            }}
          />
        </Box>
      )}
    </>
  );
};

export default AssignToRoles;
