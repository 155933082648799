// Constants representing the steps of the role creation process
export const STEPS = [
  'ROLE_DETAILS',
  'SET_PERMISSIONS',
  'ASSIGN_USERS',
] as const;

// Type to ensure SIDE_MENU_ITEMS keys match the STEPS array values
type Step = typeof STEPS[number];

// Object representing the side menu items and their associated labels for each step
export const SIDE_MENU_ITEMS: Record<
  Step,
  { menuLabel: string; nextActionLabel: string }
> = {
  ROLE_DETAILS: {
    menuLabel: 'Role Details',
    nextActionLabel: 'Next: Set Permissions',
  },
  SET_PERMISSIONS: {
    menuLabel: 'Set Permissions',
    nextActionLabel: 'Next: Assign Users',
  },
  ASSIGN_USERS: {
    menuLabel: 'Assign Users',
    nextActionLabel: 'Create Role',
  },
};
