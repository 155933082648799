// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const Column = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

export const PreviewWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px 0px',
  overflowY: 'auto',
  flex: 1,
}));
