// React
import React, { useMemo, useState } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import actions from 'store/actions';

// Custom components
import ActionDropdown from 'components/Dropdown/ActionDropdown/ActionDropdown';
import CustomToolTip from 'components/Tooltip';
import DownloadOptions from 'components/AllDialogs/logs/DownloadOptions';
import { VerifyFeatureAccess } from 'components/UpSellModal/VerifyFeatureAccess';

// Icons
import { MoreVertOutlined } from '@mui/icons-material';
import DownloadIcon2 from 'components/Icons/downloadIcon2';
import FileIconWithLines from 'components/Icons/fileIconWithLines';
import ArrowInCircleIcon from 'components/Icons/arrowInCircleIcon';
import AddTaskWorkOrderIcon from 'components/Icons/addTaskWorkorderIcon';

// Styled
import { Wrapper, SettingsIconWrapper, StyledButton } from './styled';

// Utils
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

// Constants
import { getDropdownOptions, DropdownOptionType } from './utils';
import DIALOGS from 'utils/dialogIds';

// Hooks
import useLogActions from 'pages/TemplateDashboard/Tabs/Submissions/hooks/useLogActions';
import { setDialog } from 'store/actions/dialogActions';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import FeatureLockIcon from 'components/Icons/featureLockIcon';

const ActionButtons = ({
  template,
  activeSubmission,
  handleArchiveLogs,
  taskId,
}) => {
  const dispatch = useDispatch();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const { workspaceId } = useWorkspaceHook();
  const { downloading, handleDownloadReportApi } = useLogActions();
  const { hasFeature, FEATURES } = useCustomPermission();

  const templateId = template?.id;

  const [showDownloadConfirmation, setShowDownloadConfirmation] =
    useState(false);

  const isLogSubmitted = useMemo(
    () => activeSubmission?.status !== 'In Progress',
    [activeSubmission],
  );

  const dropdownOptions = useMemo(
    () => getDropdownOptions(isLogSubmitted),
    [isLogSubmitted],
  );

  const handlePrintLog = () => {
    dispatch(
      actions.setDialog({
        open: true,
        dialogId: 'createTask',
        data: {
          taskData: {
            checklistId: templateId,
          },
        },
      }),
    );
  };

  const handleDownloadLog = async (option: any) => {
    activeSubmission?.id &&
      (await handleDownloadReportApi({
        logIds: [activeSubmission?.id],
        option: option.id,
        templateId,
        status: [],
      }));
    setShowDownloadConfirmation(false);
  };

  const handleArchive = async () => {
    activeSubmission && handleArchiveLogs([activeSubmission]);
  };

  const handleContinueLog = () => {
    navigateWithWorkspaceUrl(
      `/in-progress-checklist/${templateId}/?logId=${activeSubmission?.id}${
        taskId ? `&taskId=${taskId}` : ''
      }&prevUrl=${`/templates/${templateId}?tab=1`}`,
    );
  };

  const handleCreateWorkOrder = () => {
    const isFeatureEnable = hasFeature(
      FEATURES.CONVERT_SUBMISSION_TO_WORK_ORDER,
    );
    if (isFeatureEnable) {
      dispatch(
        setDialog({
          open: true,
          dialogId: 'createEditTask',
          data: {
            dialogState: {
              viewLess: false,
            },
            taskData: {
              AssociatedLog: {
                id: activeSubmission?.id,
                name: activeSubmission?.name,
                createdAt: activeSubmission?.createdAt,
                status: activeSubmission?.status,
                updatedAt: activeSubmission?.updatedAt,
                checklistId: activeSubmission?.checklistId,
              },
            },
          },
        }),
      );
    } else {
      const icon = <FeatureLockIcon />;
      const dialogId: string = DIALOGS.UPSELL_MODAL;
      dispatch(
        actions.setDialog({
          dialogId,
          open: true,
          data: {
            isEscapable: true,
            icon,
            title: 'You’ve discovered an Add-on Feature!',
            description: (
              <>
                <b>{FEATURES.CONVERT_SUBMISSION_TO_WORK_ORDER}</b> isn’t
                available on your current plan. Upgrade Now to access this
                feature.
              </>
            ),
            primaryButtonText: 'Upgrade Now',
            secondaryButtonText: 'Talk to sales',
            primaryButtonClickCB: () => {
              dispatch(
                actions.setDialog({
                  dialogId: DIALOGS.BILLING.PLANS_MODAL,
                  open: true,
                }),
              );
            },
            secondaryButtonClickCB: () => {
              window.open(
                `https://meetings.hubspot.com/kumeil`,
                '_blank',
                'noopener',
              );
            },
            buttonDisplayDirectionCol: true,
          },
        }),
      );
    }
  };

  const handleOpenConfirmation = () => {
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.CONFIRMATION,
        open: true,
        data: {
          title: 'Archive Submission',
          description:
            'Are you sure you want to archive the selected submission?',
          confirmButtonProps: {
            color: 'error',
          },
          onConfirmCallback: () => handleArchive(),
        },
      }),
    );
  };

  const handleViewReport = () => {
    window.open(
      `/ws/${workspaceId}/checklistlogs/${activeSubmission?.id}/report`,
      '_blank',
    );
  };

  const handleCopyLink = () => {
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.SUBMISSION_SHARE_DIALOG,
        open: true,
        data: {
          shareUrl: `${window.location.origin}/ws/${workspaceId}/${activeSubmission?.ChecklistId}/${activeSubmission?.id}`,
          templateName: template?.name,
        },
      }),
    );
  };

  const handleOptionSelect = (option: DropdownOptionType) => {
    switch (option.id) {
      case 'download':
        setShowDownloadConfirmation(true);
        break;
      case 'print':
        handlePrintLog();
        break;
      case 'viewReport':
        handleViewReport();
        break;
      case 'continue':
        handleContinueLog();
        break;
      case 'createWorkOrder':
        handleCreateWorkOrder();
        break;
      case 'archive':
        handleOpenConfirmation();
        break;
      case 'copyLink':
        handleCopyLink();
        break;
      default:
        break;
    }
  };

  return (
    <Wrapper>
      {showDownloadConfirmation && (
        <DownloadOptions
          open={showDownloadConfirmation}
          handleClose={() => setShowDownloadConfirmation(false)}
          multiple={false}
          onSelect={handleDownloadLog}
          loading={downloading}
        />
      )}
      {!!activeSubmission && (
        <React.Fragment>
          <VerifyFeatureAccess
            featureId={FEATURES.CONVERT_SUBMISSION_TO_WORK_ORDER}
          >
            <StyledButton
              onClick={handleCreateWorkOrder}
              startIcon={<AddTaskWorkOrderIcon />}
              variant="outlined"
              sx={{
                background: '#fff',
              }}
            >
              Follow Up Task
            </StyledButton>
          </VerifyFeatureAccess>
          <StyledButton
            isSolidBtn={true}
            onClick={isLogSubmitted ? handleViewReport : handleContinueLog}
            startIcon={
              isLogSubmitted ? (
                <FileIconWithLines sx={{ fill: 'none' }} />
              ) : (
                <ArrowInCircleIcon sx={{ fill: 'none' }} />
              )
            }
            variant="contained"
          >
            {isLogSubmitted ? 'View Report' : 'Continue'}
          </StyledButton>
          <CustomToolTip title="Download Report">
            <SettingsIconWrapper
              onClick={() => setShowDownloadConfirmation(true)}
            >
              <DownloadIcon2 sx={{ fill: 'none', fontSize: 18 }} />
            </SettingsIconWrapper>
          </CustomToolTip>
          <ActionDropdown
            options={dropdownOptions}
            buttonProps={{ sx: { ml: '-8px' } }}
            handleOptionSelect={handleOptionSelect}
            popperProps={{
              placement: 'bottom-end',
            }}
          >
            <MoreVertOutlined />
          </ActionDropdown>
        </React.Fragment>
      )}
    </Wrapper>
  );
};

export default ActionButtons;
