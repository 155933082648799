import React from 'react';

// Custom components
import ActiveSubmissionLog from 'components/TemplateDashboard/SubmissionTemplatePreview/ActiveSubmissionLog';

import { StyledDrawer } from './styled';

// Types
import { SubmissionReportType } from 'pages/TemplateDashboard/Tabs/Submissions/context';

interface LogDrawerPropTypes {
  log: SubmissionReportType;
  open: boolean;
  onClose: () => void;
  handleArchiveLog: (logIds: any[]) => void;
}

const LogDrawer = ({
  log,
  open,
  onClose,
  handleArchiveLog,
}: LogDrawerPropTypes) => {
  return (
    <StyledDrawer anchor="right" open={open} onClose={onClose}>
      <ActiveSubmissionLog
        handleArchiveLogs={handleArchiveLog}
        template={log?.Checklist}
        activeSubmission={log}
      />
    </StyledDrawer>
  );
};

export default LogDrawer;
