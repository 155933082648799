import { Box } from '@mui/material';
import styled from '@mui/system/styled';

export const HeaderContent = styled(Box)({
  marginBottom: 20,
  '& .top': {
    fontWeight: 600,
    fontSize: 13,
    lineHeight: '20px',
    color: '#424242',
    marginBottom: 4,
  },

  '& .bottom': {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '20px',
    color: '#000000',
  },
});

export const MainCnt = styled(Box)({
  margin: 'auto 140px',
});

export const DescriptionBox = styled(Box)({
  marginTop: 9,
  '& textarea': {
    padding: '7px 0px !important',
    fontWeight: 500,
    fontSize: '14px !important',
    lineHeight: '20px',
    letterSpacing: '0.14px',
    color: '#000000',
  },
});
