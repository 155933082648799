import React, { useEffect, useMemo, useRef, useState } from 'react';
import { generateAssetDropdownData } from '../../../../helper/task/dropdownData';
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import { useDispatch, useSelector } from 'react-redux';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import CustomListItem from 'components/List/ListItem/ListItem';
import AddIcon from '@mui/icons-material/Add';
import { useTaskEdit } from 'controller/useTaskEdit';
import CustomToolTip from 'components/Tooltip/tooltip';
import { StyledEndIconCnt } from 'components/Dropdown/AssigneeDropdown/assigneeDropdownSelect.style';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CustomButton from 'components/Button/CustomButton';
import AssetsSelectItemsList from 'components/Dropdown/AssetsSelectItemsList/AssetsSelectItemsList';
import { setDialog } from 'store/actions/dialogActions';
import { getAssetsState, getAuthAssetsState } from 'store/selectors/assets';
import AssetsIconPlain from 'components/Icons/assetsIconPlain';
import {
  AddAssetCnt,
  AssetsDropdownButton,
  AssetWithParentAssetCnt,
} from 'components/Dropdown/Task/Asset/taskAssetDropdown.style';
import useResponsive from 'utils/CustomHooks/useResponsive';
import { HasPermission } from 'components/HasPermission';
import { PERMISSIONS } from 'utils/constants';

function AssetsList(props) {
  const [isHideFooter, setIsHideFooter] = useState(false);
  const assetsList = useSelector(getAuthAssetsState);
  const dispatch = useDispatch();
  const {
    selectedOption,
    setSelectedOption,
    onAddAssetCallback,
    isMulti,
    minOneSelected,
    closeAction,
    location,
  } = props;

  const assetDropdownData = useMemo(() => {
    return generateAssetDropdownData(assetsList);
  }, [assetsList, location]);

  const searchQueryCallback = (data) => {
    setIsHideFooter(data?.isHideFooter);
  };

  const handleAddNewAsset = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: 'createAsset',
      }),
    );
    closeAction();
    onAddAssetCallback();
  };

  return (
    <AssetsSelectItemsList
      searchFieldProps={{ autoFocus: false }}
      selectedOptions={selectedOption}
      canSearch={true}
      isMulti={isMulti}
      minOneSelected={minOneSelected}
      location={location}
      searchQueryCallback={searchQueryCallback}
      footerRenderer={
        isHideFooter ? (
          <></>
        ) : (
          <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_ASSETS]}>
            <AddAssetCnt>
              <CustomListItem
                checkbox={false}
                id={'addNewLocation-dropdown-button'}
                className={'addLocationBtn'}
                startIcon={<AddIcon sx={{ color: '#6868FE' }} />}
                onClick={handleAddNewAsset}
              >
                Add Asset
              </CustomListItem>
            </AddAssetCnt>
          </HasPermission>
        )
      }
      options={assetDropdownData}
      handleChangeCallback={(values) => {
        setSelectedOption(values);
      }}
    />
  );
}
function AssetsDropdown(props) {
  const {
    popperProps = { style: {} },
    onAddAssetCallback,
    buttonProps = {},
    selected,
    taskData,
    editable = false,
    viewType = 'taskView',
    children,
    onChangeCallback,
    minOneSelected = false,
    isMulti,
    location,
    error = null,
    dropdownBtnProps = {},
  } = props;
  const assetsList = useSelector(getAssetsState);
  const { isMobileDeviceSm } = useResponsive();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState<any[]>([]);
  const { handleUpdateTask } = useTaskEdit(taskData);
  const assetDropdownData = useMemo(() => {
    return generateAssetDropdownData(assetsList);
  }, [assetsList, location]);
  useEffect(() => {
    const selectedDropdownOptions = assetDropdownData?.reduce((r, cv) => {
      const parentSelectedAsset = selected == cv?.id ? [cv] : [];
      const selectedChildAssets =
        cv?.child?.filter((l) => {
          return selected == l.id;
        }) || [];

      r = [...r, ...parentSelectedAsset, ...selectedChildAssets];
      return r;
    }, []);

    setSelectedOption(selectedDropdownOptions);
  }, [selected]);
  const handleDropdownOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleDropdownClose = (event) => {
    setAnchorEl(null);
  };

  const handleChangeOption = async (values) => {
    if (!values.length && minOneSelected) return;
    if (selectedOption?.length <= 1 && minOneSelected) {
      setSelectedOption(values);
      onChangeCallback?.(values);
      editable && (await handleUpdateTask({ AssetId: values?.[0]?.id }));
      return;
    }
    if (!minOneSelected) {
      const value = values?.[0]?.id == selectedOption?.[0]?.id ? null : values;
      setSelectedOption(value);
      onChangeCallback?.(value);
      editable && (await handleUpdateTask({ AssetId: value?.[0]?.id ?? null }));
    }
  };

  const selectedText =
    selectedOption?.length === 1
      ? selectedOption[0]?.label
      : `${selectedOption?.length} Assets`;
  const parentAssetId = selectedOption?.[0]?.obj?.ParentId;
  const parentAsset = assetsList?.find((l) => l.id == parentAssetId);
  const isSelected = selectedOption?.length ? true : false;
  return (
    <ClickAwayListener
      onClickAway={handleDropdownClose}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <div>
        {viewType === 'taskView' && (
          <CustomToolTip title={'Asset'}>
            <AssetsDropdownButton
              variant="outlined"
              buttonType={isSelected ? 'grayWhite' : 'grayWhite-dashed'}
              onClick={handleDropdownOpen}
              startIcon={
                isSelected ? null : <AssetsIconPlain sx={{ mr: '5px' }} />
              }
              isSelected={isSelected}
            >
              {selectedOption?.length ? selectedText : 'Asset'}
            </AssetsDropdownButton>
          </CustomToolTip>
        )}
        {viewType === 'tableView' && (
          <CustomToolTip title={'Asset'}>
            <AssetsDropdownButton
              variant={isSelected ? 'text' : 'outlined'}
              buttonType={isSelected ? 'gray' : 'grayWhite-dashed'}
              onClick={handleDropdownOpen}
              startIcon={
                isSelected ? null : <AssetsIconPlain sx={{ mr: '5px' }} />
              }
              isSelected={isSelected}
            >
              {selectedOption?.length ? (
                <AssetWithParentAssetCnt>
                  <span className="assetName"> {selectedText}</span>
                  {parentAsset && (
                    <span className="parentAssetName">{parentAsset?.name}</span>
                  )}
                </AssetWithParentAssetCnt>
              ) : (
                'Add Asset'
              )}
            </AssetsDropdownButton>
          </CustomToolTip>
          // <LocationText onClick={handleDropdownOpen}>
          //   {(selectedOption &&
          //     selectedOption.length &&
          //     selectedOption[0]?.label) || (
          //     <>
          //       <LocationIcon /> Select Asset
          //     </>
          //   )}
          // </LocationText>
        )}
        {viewType === 'select' && (
          <CustomButton
            variant="outlined"
            buttonType="grayWhite"
            onClick={handleDropdownOpen}
            endIcon={
              <StyledEndIconCnt>
                {/*commented for later use*/}
                {/*{selected && (*/}
                {/*  <IconButton onClick={handleClearOptions}>*/}
                {/*    <CloseIcon />*/}
                {/*  </IconButton>*/}
                {/*)}*/}
                <KeyboardArrowDownIcon style={{ fontSize: 24 }} />
              </StyledEndIconCnt>
            }
            style={{
              width: '100%',
              justifyContent: 'space-between',
              padding: '6px 17px 6px 10px',
              height: 40,
              ...(error && { border: '1px solid #ef5350' }),
            }}
            {...dropdownBtnProps}
          >
            {selectedOption?.length ? selectedText : 'Asset'}
          </CustomButton>
        )}

        <CustomDropdown
          paperProps={{
            sx: {
              overflow: 'hidden',
              paddingBottom: '0px',
            },
          }}
          popperProps={{
            ...popperProps,
            content: (
              <AssetsList
                location={location}
                onAddAssetCallback={onAddAssetCallback}
                editable={editable}
                closeAction={handleDropdownClose}
                selectedOption={selectedOption}
                setSelectedOption={handleChangeOption}
                isMulti={isMulti}
              />
            ),
            style: {
              ...popperProps.style,
              zIndex: 9999,
              width: isMobileDeviceSm ? 320 : 400,
            },
            open: !!anchorEl,
            placement: 'bottom-start',
            anchorEl,
          }}
          buttonProps={buttonProps}
          clickaway={false}
        >
          {children}
        </CustomDropdown>
      </div>
    </ClickAwayListener>
  );
}

export default AssetsDropdown;
