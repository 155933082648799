import { useContext } from 'react';
import { CreateTaskContext } from 'components/AllDialogs/tasks/createTask/context/context';
import LocationsDropdown from 'components/Dropdown/Task/Location/TaskLocationDropdown';
import { setDialog } from 'store/actions/dialogActions';
import { useDispatch } from 'react-redux';
import { DropdownWithLabelCnt } from 'components/AllDialogs/requests/createRequest/createRequest.style';

const TaskLocationDropdown = () => {
  const { newTask, setNewTaskObj, isProject } = useContext(CreateTaskContext);

  const dispatch = useDispatch();
  const closeAction = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: 'createEditTask',
      }),
    );
  };
  const handleUpdateLocationCreate = (values) => {
    const { AssetId, ...rest } = newTask;
    setNewTaskObj({ ...rest, locationIds: [values?.[0]?.id] });
  };

  return (
    <DropdownWithLabelCnt>
      <label>Location</label>
      <LocationsDropdown
        dropdownBtnProps={{
          disabled:
            newTask?.isAutoTagLocation ||
            isProject ||
            newTask?.assigneesRoles?.roles?.length > 0,
        }}
        onAddLocationCallback={closeAction}
        onChangeCallback={handleUpdateLocationCreate}
        popperProps={{ disablePortal: false }}
        dropdownProps={{ iconButton: true }}
        selected={
          newTask?.locationIds || newTask?.Location?.id || newTask?.LocationId
        }
        isMulti={false}
        viewType={'select'}
      />
    </DropdownWithLabelCnt>
  );
};

export default TaskLocationDropdown;
