import SearchIcon from '@mui/icons-material/Search';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import { CategoriesSearchInputCnt } from 'components/layout/navbar/CategoriesSearchInput/categoriesSearchInput.style';
import { debounce } from 'lodash';
function TaskSearchInput() {
  const handleInputChange = debounce(async (e) => {
    const value = e.target.value.trim();
    globalThis.CategoriesGrid.api.setQuickFilter(value);
  }, 300);
  return (
    <CategoriesSearchInputCnt>
      <CustomInput
        id="categoriesSearchInput"
        suppressErrorSpace={true}
        fieldProps={{
          onChange: handleInputChange,
          startAdornment: <SearchIcon />,
          placeholder: 'Search Categories',
        }}
        label=""
      />
    </CategoriesSearchInputCnt>
  );
}

export default TaskSearchInput;
