import styled from '@mui/system/styled';

export const RepeatTaskCnt = styled('div')({
  '& .MuiToggleButtonGroup-root': {
    marginBottom: 16,
    width: '100%',
    '& .MuiToggleButton-root': {
      flex: 1,
    },
  },
});
