import React, { useState } from 'react';
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import { DateRangePickerButton } from 'components/Dropdown/Task/DateRangePicker/taskDateTimePickerDropdown.style';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { DateRange, DayPicker } from 'react-day-picker-latest';
import 'react-day-picker-latest/dist/style.css';
import './datePickerStyle.css';
import CalendarIcon from 'components/Icons/calendarIcon';
import moment from 'moment/moment';
import CustomToolTip from 'components/Tooltip/tooltip';
import CustomDateRangePicker from 'components/Dropdown/Task/DateRangePicker/DateTimePicker';

function DateRangePickerDropdown(props) {
  const {
    popperProps = { style: {} },
    buttonProps = {},
    children,
    selectedStartDate,
    selectedDueDate,
    onStartDateChange,
    onDueDateChange,
    timePickerProps,
    onClearStartDate,
    onClearDueDate,
    taskTime,
    pickerBtnProps = {},
    showRepeatTask,
    repeatTaskProps,
    editSeriesType,
    isCreate = false,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeDate, setActiveDate] = useState<string | null>(
    selectedStartDate ? 'starDate' : 'dueDate',
  );
  const [activeTime, setActiveTime] = useState('');

  const handleSetActiveDate = (value) => {
    const isStartTimeActive =
      value == 'startDate' && selectedStartDate ? 'startTime' : '';
    const isDueTimeActive =
      value == 'dueDate' && selectedDueDate ? 'dueTime' : '';
    setActiveDate(value);
    setActiveTime(isStartTimeActive || isDueTimeActive);
  };
  const handleDropdownOpen = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleDropdownClose = (event) => {
    setAnchorEl(null);
  };

  const startDateText = selectedStartDate
    ? moment(selectedStartDate).format('MMM DD')
    : '';
  const dueDateText = selectedDueDate
    ? moment(selectedDueDate).format('MMM DD')
    : '';

  const isDateSelected = selectedStartDate || selectedDueDate;
  return (
    <ClickAwayListener
      onClickAway={handleDropdownClose}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <div>
        <CustomToolTip title="Dates">
          <DateRangePickerButton
            variant="outlined"
            buttonType={isDateSelected ? 'grayWhite' : 'grayWhite-dashed'}
            onClick={handleDropdownOpen}
            startIcon={
              isDateSelected ? null : <CalendarIcon sx={{ mr: '5px' }} />
            }
            isSelected={isDateSelected}
            {...pickerBtnProps}
          >
            {selectedStartDate && !selectedDueDate
              ? `Start Date: ${startDateText}`
              : null}
            {selectedDueDate && !selectedStartDate
              ? `Due Date: ${dueDateText}`
              : null}
            {selectedStartDate &&
              selectedDueDate &&
              `${startDateText} - ${dueDateText}`}
            {!selectedStartDate && !selectedDueDate && 'Due Date'}
            {/*{selectedOption?.length ? selectedText : null}*/}
          </DateRangePickerButton>
        </CustomToolTip>

        <CustomDropdown
          popperProps={{
            ...popperProps,
            content: (
              <CustomDateRangePicker
                handleSetActiveDate={handleSetActiveDate}
                activeDate={activeDate}
                startDate={selectedStartDate}
                dueDate={selectedDueDate}
                activeTime={activeTime}
                setActiveTime={setActiveTime}
                onStartDateChange={onStartDateChange}
                onDueDateChange={onDueDateChange}
                timePickerProps={timePickerProps}
                onClearDueDate={onClearDueDate}
                onClearStartDate={onClearStartDate}
                taskTime={taskTime}
                showRepeatTask={showRepeatTask}
                repeatTaskProps={repeatTaskProps}
                editSeriesType={editSeriesType}
                isCreate={isCreate}
              />
            ),
            style: {
              ...popperProps.style,
              zIndex: 9999,
              width: activeTime ? 490 : 320,
            },
            open: !!anchorEl,
            placement: 'bottom-start',
            anchorEl,
          }}
          buttonProps={buttonProps}
          clickaway={false}
        >
          {children}
        </CustomDropdown>
      </div>
    </ClickAwayListener>
  );
}

export default DateRangePickerDropdown;
