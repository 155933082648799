import { FC } from 'react';
import { useReactRouter } from 'controller/useReactRouter';
import Navbar from 'components/layout/navbar';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { AppBar } from 'components/layout/header/header.style';
import TopAlert from 'components/TopAlert';

const Header: FC<any> = ({ open }) => {
  const { handleGetPathName } = useReactRouter();
  const userRoletype: any = useSelector(selectors.getAuthRole);

  return (
    (!handleGetPathName().includes('/checklist/') &&
      !handleGetPathName().includes('/my-notifications') && (
        <AppBar position="fixed" open={open}>
          <TopAlert />
          <header
            className={`d-flex align-items-center main-header ${
              userRoletype === 'Hotel' ? 'blanknav' : ''
            }`}
          >
            <Navbar />
          </header>
        </AppBar>
      )) ||
    null
  );
};

export default Header;
