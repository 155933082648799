import React from 'react';
import { Radio, Stack, Typography, Divider } from '@mui/material';

type EmptyString = '';
type ScheduleTypes = 'oneOff' | 'daily' | 'weekly' | 'monthly';

interface RadioButtonProps {
  type: ScheduleTypes;
  label: string;
  checked: boolean;
  onChange: (type: ScheduleTypes | EmptyString) => void;
  children?: React.ReactNode;
}

const RadioButton: React.FC<RadioButtonProps> = (props) => {
  const { type, checked, label, children, onChange } = props;

  const handleClickRadio = () => {
    if (checked) {
      onChange('');
    } else {
      onChange(type);
    }
  };

  return (
    <Stack gap="8px">
      <Stack gap="8px" direction={'row'} alignItems={'center'}>
        <Radio
          sx={{ p: 0 }}
          value={type}
          checked={checked}
          onChange={handleClickRadio}
        />
        <Typography fontSize={'14px'} fontWeight={'400'}>
          {label}
        </Typography>
      </Stack>
      {checked && <>{children}</>}
      {checked && <Divider sx={{ mb: '8px' }} />}
    </Stack>
  );
};

export default RadioButton;
