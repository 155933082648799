import { useContext } from 'react';
import { Box, Divider } from '@mui/material';
import { AssigneeDropdownCnt } from 'components/AllDialogs/tasks/editTask/createTask.style';
import { CreateTaskContext } from 'components/AllDialogs/tasks/editTask/context/context';
import TaskAssignTo from 'components/TaskInputs/assignTo';
import TaskNotification from '../createTask/taskNotification';
import { cloneDeep, union } from 'lodash';

const TaskAssigneeForTemplates = (props) => {
  const { hideElements, updateWatchers } = props;
  const { updateTask, newTask } = useContext(CreateTaskContext);

  const getNewOverdueWatchers = (assignees) => {
    const OldOverdueWatchers = cloneDeep(
      newTask?.notification?.overdue?.recipients || [],
    );

    const CurrentAssignees = cloneDeep(assignees || []);
    const finaldata = union(OldOverdueWatchers, CurrentAssignees);
    return finaldata || [];
  };

  const handleUpdateAssignee = (assignees) => {
    updateTask({
      assigneesRoles: null,
      assignees,
      isMultiTasks:
        assignees?.length < 2 ? false : newTask?.isMultiTasks || false,
      notification: {
        ...newTask?.notification,
        overdue: {
          recipients: getNewOverdueWatchers(assignees),
        },
      },
    });
  };

  const handleUpdateRole = (assigneesRoles) => {
    updateTask({
      assigneesRoles,
      assignees: [],
    });
  };

  return (
    <AssigneeDropdownCnt>
      <label>Assignee</label>

      {!hideElements?.watchers && (
        <Box className="watchersCnt">
          <TaskNotification
            updateWatchers={(watchers) =>
              updateWatchers(watchers, 'statusChange')
            }
            watchers={newTask?.notification?.statusChange?.recipients || []}
          />
        </Box>
      )}

      <Divider className="separator" />

      <TaskAssignTo
        handleChangeUsersAndTeamsCallback={handleUpdateAssignee}
        handleChangeRolesCallback={handleUpdateRole}
        selectedUsersAndTeams={newTask?.assignees || []}
        selectedRole={newTask?.assigneesRoles}
      />
    </AssigneeDropdownCnt>
  );
};

export default TaskAssigneeForTemplates;
