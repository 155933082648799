import React, { useContext } from 'react';
import { CreateRequestContext } from 'components/AllDialogs/requests/createRequest/context/context';
import LocationsDropdown from 'components/Dropdown/Task/Location/TaskLocationDropdown';
import { DropdownWithLabelCnt } from 'components/AllDialogs/requests/createRequest/createRequest.style';

const TaskLocationDropdown = () => {
  const { updateRequest, newRequest, setNewRequestObj, editing } =
    useContext(CreateRequestContext);

  const handleUpdateLocationCreate = (values) => {
    const { AssetId, ...rest } = newRequest;
    setNewRequestObj({ ...rest, LocationId: values?.[0]?.id, AssetId: null });
  };

  const handleUpdateLocationEdit = (values) => {
    const { ...rest } = newRequest;
    setNewRequestObj({
      ...rest,
      LocationId: values?.[0]?.id || null,
      AssetId: null,
    });
  };

  return (
    <DropdownWithLabelCnt>
      <label>Location</label>
      <LocationsDropdown
        onChangeCallback={
          editing ? handleUpdateLocationEdit : handleUpdateLocationCreate
        }
        popperProps={{ disablePortal: false }}
        dropdownProps={{ iconButton: true }}
        selected={newRequest?.LocationId}
        isMulti={false}
        viewType={'select'}
      />
    </DropdownWithLabelCnt>
  );
};

export default TaskLocationDropdown;
