import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import CustomCheckbox from 'components/Form/Checkbox/Checkbox';
import {
  StyledListItemButton,
  StyledListItemIcon,
  StyledListItemIconStart,
} from 'components/List/ListItem/ListItem.styles';
// type CustomListItemProps = {
//   variant: 'contained' | 'outlined' | 'text';
//   children: JSX.Element;
//   buttonProps: any;
//   onClick: () => void;
// };
function CustomListItem(props) {
  const {
    selected,
    children,
    checkboxProps,
    styleSelected,
    startIcon,
    checkbox = true,
    endIcon,
    endIconProps = {},
    ...rest
  } = props;
  return (
    <div>
      <StyledListItemButton
        dense
        {...rest}
        style={{
          background: selected && styleSelected && '#e3e3ff',
          ...rest.style,
        }}
      >
        <ListItem disablePadding className="listitem">
          {checkbox && (
            <StyledListItemIcon style={{ marginLeft: 8 }}>
              <CustomCheckbox
                edge="start"
                checked={selected}
                tabIndex={-1}
                disableRipple
                alignItems={'flex-start'}
                {...checkboxProps}
              />
            </StyledListItemIcon>
          )}
          {startIcon && (
            <StyledListItemIconStart className="start-icon">
              {startIcon}
            </StyledListItemIconStart>
          )}
          <ListItemText className="itemLabel" primary={children} />
          {endIcon && (
            <StyledListItemIcon {...endIconProps}>{endIcon}</StyledListItemIcon>
          )}
        </ListItem>
      </StyledListItemButton>
    </div>
  );
}

export default CustomListItem;
