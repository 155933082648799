import { createSvgIcon } from '@mui/material';

const StarsIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M5.5 1.5L6.28446 3.06892C6.54995 3.5999 6.68269 3.86538 6.86003 4.09545C7.01739 4.29959 7.20041 4.48261 7.40455 4.63997C7.63462 4.81731 7.9001 4.95005 8.43108 5.21554L10 6L8.43108 6.78446C7.9001 7.04995 7.63462 7.18269 7.40455 7.36003C7.20041 7.51739 7.01739 7.70041 6.86003 7.90455C6.68269 8.13462 6.54995 8.4001 6.28446 8.93108L5.5 10.5L4.71554 8.93108C4.45005 8.4001 4.31731 8.13462 4.13997 7.90455C3.98261 7.70041 3.79959 7.51739 3.59545 7.36003C3.36538 7.18269 3.0999 7.04995 2.56892 6.78446L1 6L2.56892 5.21554C3.0999 4.95005 3.36538 4.81731 3.59545 4.63997C3.79959 4.48261 3.98261 4.29959 4.13997 4.09545C4.31731 3.86538 4.45005 3.5999 4.71554 3.06892L5.5 1.5Z"
        fill="currentColor"
      />
      <path
        d="M16 11.5L16.9511 13.4023C17.2166 13.9332 17.3494 14.1987 17.5267 14.4288C17.6841 14.6329 17.8671 14.8159 18.0712 14.9733C18.3013 15.1506 18.5668 15.2834 19.0977 15.5489L21 16.5L19.0977 17.4511C18.5668 17.7166 18.3013 17.8494 18.0712 18.0267C17.8671 18.1841 17.6841 18.3671 17.5267 18.5712C17.3494 18.8013 17.2166 19.0668 16.9511 19.5978L16 21.5L15.0489 19.5977C14.7834 19.0668 14.6506 18.8013 14.4733 18.5712C14.3159 18.3671 14.1329 18.1841 13.9288 18.0267C13.6987 17.8494 13.4332 17.7166 12.9023 17.4511L11 16.5L12.9023 15.5489C13.4332 15.2834 13.6987 15.1506 13.9288 14.9733C14.1329 14.8159 14.3159 14.6329 14.4733 14.4288C14.6506 14.1987 14.7834 13.9332 15.0489 13.4022L16 11.5Z"
        fill="currentColor"
      />
      <path
        d="M17.5 7.5V2.5M4.5 20.5V15.5M15 5H20M2 18H7M5.5 1.5L4.71554 3.06892C4.45005 3.59989 4.31731 3.86538 4.13997 4.09545C3.98261 4.29959 3.79959 4.48261 3.59545 4.63997C3.36538 4.81731 3.0999 4.95005 2.56892 5.21554L1 6L2.56892 6.78446C3.0999 7.04995 3.36538 7.18269 3.59545 7.36003C3.79959 7.51739 3.98261 7.70041 4.13997 7.90455C4.31731 8.13462 4.45005 8.4001 4.71554 8.93108L5.5 10.5L6.28446 8.93108C6.54995 8.4001 6.68269 8.13462 6.86003 7.90455C7.01739 7.70041 7.20041 7.51739 7.40455 7.36003C7.63462 7.18269 7.9001 7.04995 8.43108 6.78446L10 6L8.43108 5.21554C7.9001 4.95005 7.63462 4.81731 7.40455 4.63997C7.20041 4.48261 7.01739 4.29959 6.86003 4.09545C6.68269 3.86538 6.54995 3.5999 6.28446 3.06892L5.5 1.5ZM16 11.5L15.0489 13.4022C14.7834 13.9332 14.6506 14.1987 14.4733 14.4288C14.3159 14.6329 14.1329 14.8159 13.9288 14.9733C13.6987 15.1506 13.4332 15.2834 12.9023 15.5489L11 16.5L12.9023 17.4511C13.4332 17.7166 13.6987 17.8494 13.9288 18.0267C14.1329 18.1841 14.3159 18.3671 14.4733 18.5712C14.6506 18.8013 14.7834 19.0668 15.0489 19.5977L16 21.5L16.9511 19.5978C17.2166 19.0668 17.3494 18.8013 17.5267 18.5712C17.6841 18.3671 17.8671 18.1841 18.0712 18.0267C18.3013 17.8494 18.5668 17.7166 19.0977 17.4511L21 16.5L19.0977 15.5489C18.5668 15.2834 18.3013 15.1506 18.0712 14.9733C17.8671 14.8159 17.6841 14.6329 17.5267 14.4288C17.3494 14.1987 17.2166 13.9332 16.9511 13.4023L16 11.5Z"
        stroke="#5555FF"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'StarsIcon',
  );
  return (
    <IconCmp
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      {...props}
    />
  );
};
export default StarsIcon;
