import React from 'react';
import { Stack, Typography } from '@mui/material';
import CustomDropdown from 'components/Dropdown/CustomDropdown';
import { useState } from 'react';
import { TimePickerList } from 'components/Dropdown/TimePicker/timePicker.cmp';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';

interface TimeListDropdownProps {
  time: string;
  icon?: React.ReactNode;
  label: string;
  buttonProps?: any;
  onChange: (time: string) => void;
  setTime: (time: string) => void;
  isTimeDisabled: (time: string) => boolean;
  handleClearTime: () => void;
}

const TimeListDropdown: React.FC<TimeListDropdownProps> = (props) => {
  const {
    onChange,
    setTime,
    time,
    isTimeDisabled,
    label,
    icon,
    handleClearTime,
    buttonProps = {},
  } = props;

  const [anchorEl, setAnchorEl] = useState<any>(null);

  return (
    <CustomDropdown
      onClickawayCallback={() => setAnchorEl(null)}
      popperProps={{
        anchorEl,
        open: Boolean(anchorEl),
        onClose: () => setAnchorEl(null),
        sx: {
          zIndex: 99999,
        },
        content: (
          <TimePickerList
            onChange={onChange}
            setTime={setTime}
            time={time}
            handleDropdownClose={() => setAnchorEl(null)}
            isTimeDisabled={(i) => isTimeDisabled(i)}
          />
        ),
      }}
      buttonRenderer={
        <Stack
          border="1px solid #E0E0E0"
          borderRadius={'6px'}
          height="32px"
          p="0px 8px"
          onClick={(e) => setAnchorEl(e.currentTarget)}
          justifyContent={'space-between'}
          alignItems={'center'}
          bgcolor={'#fff'}
          justifyItems={'center'}
          direction={'row'}
          sx={{
            cursor: 'pointer',
          }}
          {...buttonProps}
          gap="4px"
        >
          <Typography color="#616161" fontSize="13px" fontWeight={'400'}>
            {time || label}
          </Typography>
          {time ? (
            <CloseRoundedIcon
              sx={{ color: '#757575', fontSize: '18px' }}
              onClick={handleClearTime}
            />
          ) : (
            icon || (
              <AccessTimeRoundedIcon
                sx={{ color: '#757575', fontSize: '18px' }}
              />
            )
          )}
        </Stack>
      }
    ></CustomDropdown>
  );
};

export default TimeListDropdown;
