// React
import { useMemo } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import actions from 'store/actions';

// Hooks
import useBilling from 'controller/useBilling';

// Utilities
import {
  Wrapper,
  IconAndDaysWrapper,
  TrialDaysLeft,
  UpgradeButton,
  StyledStarIcon,
} from './styled';
import DIALOGS from 'utils/dialogIds';

const TrialDuration = () => {
  const dispatch = useDispatch();
  const { billingData } = useBilling();

  const handleClickUpgrade = () => {
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.BILLING.PLANS_MODAL,
        open: true,
        data: {
          origin: 'yellowUpgradeNowButton',
        },
      }),
    );
  };

  const text = useMemo(() => {
    return billingData.isTrial
      ? `Trial ends in ${billingData?.daysLeft} days`
      : billingData.isCanceledAndActive
      ? `${billingData?.daysLeft} days left`
      : '';
  }, [billingData]);

  return (
    <Wrapper>
      <IconAndDaysWrapper>
        <StyledStarIcon />
        <TrialDaysLeft>{text}</TrialDaysLeft>
      </IconAndDaysWrapper>
      <UpgradeButton onClick={handleClickUpgrade}>Upgrade Now</UpgradeButton>
    </Wrapper>
  );
};

export default TrialDuration;
