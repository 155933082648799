import RepeatTask from 'components/RepeatTask';
import useWorkingHours from 'utils/CustomHooks/useWorkingHours';
import moment from 'moment-timezone';
import { weeklyInitState } from 'utils/schedules';

export const oneOffInitState = {
  recurringByEvery: '',
  recurringTask: true,
  requestThisTask: '',
  intervalWeek: [],
  editInstance: 'One',
  endDate: '',
  instanceCadence: {
    value: 0,
    unit: null,
    field: null,
  },
};
export const dailyInitState = {
  recurringByEvery: 'Week1',
  recurringTask: true,
  requestThisTask: 'Weekly',
  intervalWeek: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
  editInstance: 'One',
  endDate: '',
  instanceCadence: {
    value: 0,
    unit: null,
    field: null,
  },
};
export const monthlyInitState = {
  recurringByEvery: '1 Month',
  recurringTask: true,
  requestThisTask: 'Monthly',
  editInstance: 'One',
  recurringOnDay: '1',
  endDate: '',
  instanceCadence: {
    value: 0,
    unit: null,
    field: null,
  },
};
function RepeatTaskView(props) {
  const { setTaskTimeChange } = props;
  const {
    updateTask,
    newTask,
    showLess,
    showRepeatTask,
    setNewTaskObj,
    taskTime,
    setTaskTime,
    isWeekTimeEdit,
    setIsWeekTimeEdit,
    isMonthTimeEdit,
    setIsMonthTimeEdit,
    isDropdown = false,
    recurType,
    setRecurType,
    updateWatchers,
    isProject = false,
    template,
  } = props;

  const {
    recurringByEvery,
    recurringTask,
    requestThisTask,
    intervalWeek,
    recurringOnDay,
    endDate,
    startTime,
    dueTime,
    scheduledDateTime,
  } = newTask;

  const { Shift } = useWorkingHours();

  const onRepeatTypeChange = (value) => {
    const obj = { ...newTask };
    const setShift = value !== 'oneOff';
    setRecurType(value);
    if (value == 'weekly') {
      const { recurringOnDay, ...rest } = obj;
      setNewTaskObj({ ...rest, ...weeklyInitState });
    } else if (value == 'oneOff') {
      const { recurringOnDay, ...rest } = obj;
      setNewTaskObj({ ...rest, ...oneOffInitState });
      setTaskTime({ startTime: '', dueTime: '' });
    } else if (value == 'daily') {
      const { recurringOnDay, ...rest } = obj;
      setNewTaskObj({ ...rest, ...dailyInitState });
    } else {
      const { intervalWeek, ...rest } = obj;
      setNewTaskObj({ ...rest, ...monthlyInitState });
    }
    if (setShift) {
      setTaskTime((prevState) => ({
        ...prevState,
        dueTime: Shift?.endTime,
        startTime: Shift?.startTime,
      }));
    }
  };
  const onDaysSelect = (value) => {
    updateTask({ intervalWeek: value });
  };
  const onWeekSelect = (value) => {
    updateTask({ recurringByEvery: value?.id });
  };
  const onRecurUntilSelect = (value) => {
    updateTask({ endDate: value || '' });
  };
  const onMonthDaySelect = (value) => {
    updateTask({ recurringOnDay: value?.id });
  };
  const onMonthSelect = (value) => {
    updateTask({ recurringByEvery: value?.id });
  };
  const onStartDateSelect = (value) => {
    updateTask({ startTime: value });
  };

  const scheduleDateTimeSelect = (value = null) => {
    let endDateToUpdate = endDate;

    if (value && endDate) {
      const valueMoment = moment(value);
      const endDateMoment = moment(endDate);

      if (valueMoment.isAfter(endDateMoment)) {
        endDateToUpdate = '';
      }
    }
    updateTask({ scheduledDateTime: value, endDate: endDateToUpdate });
  };

  const onDueTimeSelect = (value) => {
    setTaskTime((prevState) => ({ ...prevState, dueTime: value }));
    setTaskTimeChange(true);
  };
  const onStartTimeSelect = (value) => {
    setTaskTime((prevState) => ({ ...prevState, startTime: value }));
    setTaskTimeChange(true);
  };
  const resetTimes = () => {
    setTaskTime({ startTime: '', dueTime: '' });
    setTaskTimeChange(true);
  };

  const repeatTaskProps = {
    onDaysSelect,
    onWeekSelect,
    onRecurUntilSelect,
    onRepeatTypeChange,
    onMonthDaySelect,
    onMonthSelect,
    onStartDateSelect,
    onDueTimeSelect,
    onStartTimeSelect,
    scheduleDateTimeSelect,
  };

  return (
    <>
      {showRepeatTask && (template ?? !showLess) && (
        <>
          <RepeatTask
            hiddenButtons={isProject ? ['oneOff'] : []}
            newTask={newTask}
            updateTask={updateTask}
            resetTimes={resetTimes}
            recurType={recurType}
            updateWatchers={updateWatchers}
            {...repeatTaskProps}
            selectedRepeatType={requestThisTask}
            isDropdown={isDropdown}
            repeatOneOffSelectedValues={{
              recurringByEvery,
              recurringTask,
              requestThisTask,
              intervalWeek,
              recurringOnDay,
              endDate,
              startDate: startTime,
              dueTime: dueTime,
              taskTime,
              isWeekTimeEdit,
              setIsWeekTimeEdit,
              scheduledDateTime,
            }}
            repeatDailySelectedValues={{
              recurringByEvery,
              recurringTask,
              requestThisTask,
              intervalWeek,
              recurringOnDay,
              endDate,
              startDate: startTime,
              dueTime: dueTime,
              taskTime,
              isWeekTimeEdit,
              setIsWeekTimeEdit,
              scheduledDateTime,
            }}
            repeatWeeklySelectedValues={{
              recurringByEvery,
              recurringTask,
              requestThisTask,
              intervalWeek,
              recurringOnDay,
              endDate,
              startDate: startTime,
              dueTime: dueTime,
              taskTime,
              isWeekTimeEdit,
              setIsWeekTimeEdit,
              scheduledDateTime,
            }}
            repeatMonthlySelectedValues={{
              recurringByEvery,
              requestThisTask,
              recurringOnDay,
              endDate,
              startDate: startTime,
              dueTime: dueTime,
              taskTime,
              isMonthTimeEdit,
              setIsMonthTimeEdit,
              scheduledDateTime,
            }}
          />
          {/* <ScheduleTaskCreation updateTask={updateTask} newTask={newTask} /> */}
        </>
      )}
    </>
  );
}

export default RepeatTaskView;
