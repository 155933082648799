import { StepCnt } from 'components/AllDialogs/templates/templateCopy/templateCopy.style';
import CustomCheckbox from 'components/Form/Checkbox/Checkbox';
import {
  PermissionOptionsList,
  PermissionOptionsListItem,
} from 'components/AllDialogs/templates/templateCopy/SharingPermission/SharingPermission.style';
import { useContext } from 'react';
import { TemplateCopyContext } from 'components/AllDialogs/templates/templateCopy/TemplateCopyForm/context/context';
import CustomButton from 'components/Button/CustomButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import { useDispatch } from 'react-redux';
import actions from 'store/actions';
const optionsData = [
  {
    title: 'This Workspace',
    description: 'Duplicate this template in this workspace',
    id: '1',
  },
  {
    title: 'Other Workspace(s)',
    description:
      'Share as a copy of this template that the workspace can edit.tsx but these edits will not reflect the template in other workspaces.',
    id: '2',
  },
];
function SharingPermission() {
  const { updateState, state, checklist } = useContext(TemplateCopyContext);
  const { selectedPermission, step } = state;
  const dispatch = useDispatch();
  const handleUpdateStep = () => {
    updateState({ step: 1 });
  };
  const goToNextStep = () => {
    updateState({ step: 2 });
  };
  const selectedPermissionOption = optionsData.find(
    (option) => option.id === selectedPermission,
  );
  const handleCopyTemplateToThisWorkspace = () => {
    dispatch(actions.duplicateChecklistApiCall(checklist?.id, false));
    closeDialog();
  };
  const closeDialog = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS.TEMPLATE_COPY,
      }),
    );
  };
  return (
    <StepCnt expanded={step === 1}>
      <div className={'stepHeaderCnt'} onClick={handleUpdateStep}>
        <div className={'leftCnt'}>
          {step == 2 || step == null ? (
            <CheckCircleIcon sx={{ color: '#4CAF50' }} />
          ) : (
            <span className="stepCount">1</span>
          )}
          <span className="stepTitle">Copy To</span>
        </div>
        {step == 2 || step == null ? (
          <div className={'rightCnt'}>
            <span>{selectedPermissionOption?.title}</span>
          </div>
        ) : null}
      </div>

      {step === 1 ? (
        <div className={'stepContentCnt'}>
          <PermissionOptionsList>
            {optionsData.map((option) => {
              return (
                <PermissionOptionsListItem
                  key={option.id}
                  selected={option.id === selectedPermission}
                  onClick={(event: any) => {
                    updateState({ selectedPermission: option.id });
                  }}
                >
                  <CustomCheckbox
                    edge="start"
                    checked={option.id === selectedPermission}
                    disableRipple
                    alignItems={'flex-start'}
                    radio={true}
                    onChange={(event: any) => {
                      updateState({ selectedPermission: option.id });
                    }}
                  />
                  <div className={'permissionOptionInfoCnt'}>
                    <span className={'permissionOptionHeading'}>
                      {option.title}
                    </span>
                    <span className={'permissionOptionDesc'}>
                      {option.description}
                    </span>
                  </div>
                </PermissionOptionsListItem>
              );
            })}
          </PermissionOptionsList>
          <div className={'actionBtnCnt'}>
            <CustomButton variant={'text'} onClick={closeDialog}>
              Cancel
            </CustomButton>
            {selectedPermission == '2' ? (
              <CustomButton variant={'contained'} onClick={goToNextStep}>
                Next
              </CustomButton>
            ) : (
              <CustomButton
                variant={'contained'}
                onClick={handleCopyTemplateToThisWorkspace}
              >
                Copy
              </CustomButton>
            )}
          </div>
        </div>
      ) : null}
    </StepCnt>
  );
}

export default SharingPermission;
