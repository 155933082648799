import { FC } from 'react';
import Stack from '@mui/material/Stack';
import { Chip, Typography } from '@mui/material';
import { colors } from 'components/theme/constants';

import ImageIcon from '@mui/icons-material/Image';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import TaskIcon from '@mui/icons-material/TaskOutlined';
import NumberTypePreview from 'components/common/jsxrender/checklistV2/builder/automations/numberTypePreview';
import LocationTypePreview from 'components/common/jsxrender/checklistV2/builder/automations/locationTypePreview';
import CustomButton from 'components/Button/CustomButton';
import { useDispatch } from 'react-redux';
import actions from 'store/actions';
import DialogIds from 'utils/dialogIds';
import { AUTOMATION_ACTIONS } from 'components/AllDialogs/automation/utils/constants';
import moment from 'moment';
import { upsertAutomations } from 'api/automations';
import TaskTemplateIcon from 'components/Icons/taskTemplateIcon';
import FlagIconRounded from 'components/Icons/flagIconRounded';

interface IProps {
  automation: any;
  field: any;
  onEdit(): void;
  fetchAndSetChecklistAutomations: () => void;
  checklistId?: any;
}

const getActionPreview = (
  actionType: string,
  { onClick, handleClickTaskFromTemplate, handleFlagCatergory },
) => {
  switch (actionType) {
    case AUTOMATION_ACTIONS.AUTO_CREATE_TASK_FROM_TEMPLATE:
      return (
        <Typography
          color={colors.primary.main}
          fontWeight={600}
          fontSize="12px"
          display="flex"
          alignItems="center"
          columnGap="4px"
        >
          <TaskTemplateIcon
            sx={{
              fill: 'none',
              height: 20,
              width: 20,
              '& path': {
                stroke: 'rgba(104,104,254,1)',
              },
            }}
          />{' '}
          Auto-Create Task from Template
          <CustomButton
            variant="outlined"
            onClick={handleClickTaskFromTemplate}
            style={{ padding: '0px 8px', fontSize: '8px', minWidth: 0 }}
          >
            View
          </CustomButton>
        </Typography>
      );

    case AUTOMATION_ACTIONS.REQUITE_TASK:
      return (
        <Typography
          color={colors.primary.main}
          fontWeight={600}
          fontSize="12px"
          display="flex"
          alignItems="center"
          columnGap="4px"
        >
          <TaskIcon style={{ fontSize: '18px' }} /> Require Corrective Task
        </Typography>
      );

    case AUTOMATION_ACTIONS.REQUIRE_IMAGE:
      return (
        <Typography
          color={colors.primary.main}
          fontWeight={600}
          fontSize="12px"
          display="flex"
          alignItems="center"
          columnGap="4px"
        >
          <ImageIcon style={{ fontSize: '18px' }} /> Require Image Capture
        </Typography>
      );

    case AUTOMATION_ACTIONS.FOLLOWUP_QUESTIONS:
      return (
        <Typography
          color={colors.primary.main}
          fontWeight={600}
          fontSize="12px"
          display="flex"
          alignItems="center"
          columnGap="4px"
        >
          <ChatBubbleOutlineIcon style={{ fontSize: '18px' }} /> Ask Follow-Up
          Questions
        </Typography>
      );

    case AUTOMATION_ACTIONS.FLAG_RESPONSE:
      return (
        <Typography
          color={colors.primary.main}
          fontWeight={600}
          fontSize="12px"
          display="flex"
          alignItems="center"
          columnGap="4px"
        >
          <FlagIconRounded
            sx={{
              '& path': {
                fill: colors.primary.main,
              },
              fontSize: '18px',
            }}
          />{' '}
          Flag Response
          <CustomButton
            variant="outlined"
            onClick={handleFlagCatergory}
            style={{ padding: '0px 8px', fontSize: '8px', minWidth: 0 }}
          >
            View
          </CustomButton>
        </Typography>
      );

    case AUTOMATION_ACTIONS.SEND_NOTIFICATION:
      return (
        <Typography
          color={colors.primary.main}
          fontWeight={600}
          fontSize="12px"
          display="flex"
          alignItems="center"
          columnGap="4px"
        >
          <NotificationsNoneIcon style={{ fontSize: '18px' }} /> Send
          Notification
          <CustomButton
            variant="outlined"
            onClick={onClick}
            style={{ padding: '0px 8px', fontSize: '8px', minWidth: 0 }}
          >
            View
          </CustomButton>
        </Typography>
      );
  }
};

export const getConditionText = (condition) => {
  switch (condition) {
    case 'eq':
      return 'is equal to';
    case 'not':
      return 'is not equal to';
    case 'gt':
      return 'is greater than';
    case 'lt':
      return 'is less than';
    case 'gte':
      return 'is greater than or equal to';
    case 'lte':
      return 'is less than or equal to';
    case 'btw':
      return 'is between';
    case 'nbtw':
      return 'is not between';
  }
};
export const getAnswerView = (field, condition) => {
  switch (field?.type) {
    case 'multipleChoice':
    case 'dropdown': {
      const answerOption = field?.options?.find(
        (op) => op.id === condition?.value,
      );

      return answerOption ? (
        <Chip
          size="small"
          variant={field.type === 'multipleChoice' ? 'outlined' : 'filled'}
          label={answerOption.label}
          sx={{
            color:
              field.type === 'multipleChoice' ? answerOption.color : '#fff',
            borderColor: answerOption.color,
            backgroundColor:
              field.type === 'multipleChoice' ? '#fff' : answerOption.color,
            p: '0px',
          }}
        />
      ) : null;
    }
    case 'passFail': {
      const answerOption = field.options.find(
        (op) => op.value === condition?.value,
      );

      return answerOption ? (
        <Chip
          size="small"
          variant="outlined"
          label={answerOption.label}
          color={answerOption.type}
          sx={{
            p: '0px',
            '& .MuiChip-label': {
              p: '0px 10px',
              fontWeight: 500,
              fontSize: '13px',
            },
          }}
        />
      ) : null;
    }
    case 'textField': {
      return (
        <Typography
          color={colors.primary.main}
          fontSize="12px"
          fontWeight={600}
        >
          {condition?.value}
        </Typography>
      );
    }

    case 'number':
      return <NumberTypePreview condition={condition} field={field} />;
    case 'temperature':
      return <NumberTypePreview condition={condition} field={field} />;
    case 'cost':
      return <NumberTypePreview condition={condition} field={field} />;
    case 'procedure':
      return (
        <Typography
          color={colors.primary.main}
          fontSize="12px"
          fontWeight={600}
        >
          Checked
        </Typography>
      );

    case 'dateTime':
      return (
        <Typography
          color={colors.primary.main}
          fontSize="12px"
          fontWeight={600}
        >
          {moment(condition?.value).format(
            field.unit === 'date' ? 'MM/DD/YYYY' : 'MM/DD/YYYY - hh:mm A',
          )}
        </Typography>
      );

    case 'location':
      return <LocationTypePreview condition={condition} />;
  }
};
const PreviewItem: FC<IProps> = (props) => {
  const {
    automation,
    field,
    onEdit,
    fetchAndSetChecklistAutomations,
    checklistId,
  } = props;
  const dispatch = useDispatch();

  const [action] = automation?.actions || [];
  const [condition] = automation?.conditions || [];

  const handleClick = (e) => {
    e?.stopPropagation();
    dispatch(
      actions.setDialog({
        dialogId: DialogIds.NOTIFICATION_AUTOMATION,
        open: true,
        data: {
          field,
          automation,
          onSave: fetchAndSetChecklistAutomations,
          checklistId,
        },
      }),
    );
  };

  // Bind Task from Template
  const autoCreateTaskFromTemplate = async (data, automation) => {
    const [action] = automation?.actions || [];
    const newAction = { ...action, data };
    const updatedAutomation = {
      ...automation,
      actions: [newAction],
      ChecklistId: checklistId,
    };

    await upsertAutomations({
      automations: [updatedAutomation],
    });

    fetchAndSetChecklistAutomations?.();
  };

  const handleClickTaskFromTemplate = (e) => {
    e?.stopPropagation();
    dispatch(
      actions.setDialog({
        dialogId: DialogIds.ATTACH_TASK_TEMPLATE_MODAL,
        open: true,
        data: {
          templateId: automation?.actions?.[0]?.data?.taskTemplateId,
          onConfirm: (data) => autoCreateTaskFromTemplate(data, automation),
          whenTrigger: automation?.actions?.[0]?.data?.when,
        },
      }),
    );
  };
  // select flag category for automation
  const handleFlagCatergory = (e) => {
    e?.stopPropagation();
    dispatch(
      actions.setDialog({
        dialogId: DialogIds.FLAGGED_CATEGORY_SELECTION_AUTOMATION,
        open: true,
        data: {
          field,
          automation,
          onSave: fetchAndSetChecklistAutomations,
          checklistId,
        },
      }),
    );
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      borderRadius="5px"
      paddingY="2px"
      paddingX="2px"
      spacing={1}
      border="1px solid transparent"
      sx={{
        cursor: 'pointer',
        '&:hover': {
          borderColor: colors.primary.main,
        },
      }}
      onClick={onEdit}
    >
      <Typography fontWeight={600} fontSize="12px" color="#424242">
        If answer {getConditionText(condition?.logic)}{' '}
      </Typography>

      {getAnswerView(field, condition)}
      <Typography fontWeight={600} fontSize="12px" color="#424242">
        then
      </Typography>

      {getActionPreview(action?.type, {
        onClick: handleClick,
        handleClickTaskFromTemplate,
        handleFlagCatergory,
      })}
    </Stack>
  );
};

export default PreviewItem;
