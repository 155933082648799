import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';

const drawerWidth = 250;
export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  boxShadow: '1px 0px 0px 1px #E0E0E0',
  marginLeft: 76,
  width: `calc(100% - 70px)`,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  '& .page-title': {
    color: 'rgba(0,0,0, 0.87)',
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const HeaderBreadCrumbCnt = styled('div')({
  '& .mainViewTitle': {
    fontWeight: 800,
    fontSize: 20,
    lineHeight: '32px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  '& .childViewTitle': {
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '32px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  '& .greaterThanSign': {
    fontWeight: 400,
  },
});
