import { styled } from '@mui/material/styles';
import { Button, Box, Typography } from '@mui/material';

export const StyledButton = styled(Button)({
  borderRadius: 8,
  background: '#6868FE',
  color: '#fff',
  width: '100%',
  margin: '8px 0px 16px 0px',
  fontWeight: 600,
  letterSpacing: 0.4,
  fontSize: 13,
  height: 36,
  '& svg > path': {
    stroke: '#fff',
    strokeWidth: 2,
    strokeOpacity: 1,
  },
  '&:hover': {
    background: '#4E48FA',
    color: '#fff',
  },
});

export const AddNewPopperWrapper = styled(Box)({
  padding: '12px 16px',
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  borderRadius: 16,
});

export const AddNewItemWrapper = styled(Box)({
  padding: '6px 4px',
  display: 'flex',
  gap: 12,
  alignItems: 'center',
  borderRadius: 4,
  cursor: 'pointer',
  transition: '0.2s all',
  '&:hover': {
    background: '#EEE',
  },
  '& svg': {
    fontSize: 22,
  },
  '& svg > path': {
    stroke: 'rgba(97, 97, 97, 1)',
  },
});

export const AddNewItemTitle = styled(Typography)({
  fontSize: 14,
  fontWeight: 600,
  color: '#212121',
  letterSpacing: '-0.1px',
});

export const AddNewIconButton = styled(Box)({
  background: 'rgba(104, 104, 254, 1)',
  transition: '0.2s all',
  color: '#fff',
  width: 28,
  height: 28,
  borderRadius: 28,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: 'auto',
  marginBottom: 16,
  cursor: 'pointer',
  '&:hover': {
    background: '#4E48FA',
  },
  '& svg > path': {
    stroke: '#fff',
    strokeWidth: 3,
    strokeOpacity: 1,
  },
});
