import styled from '@mui/system/styled';
import List from '@mui/material/List';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Button, Typography } from '@mui/material';

export const WorkspaceDropdownCnt = styled('div', {
  shouldForwardProp: (prop) => prop !== 'shrinked',
})(({ shrinked }) => ({
  borderRadius: 6,
  transition: '0.2s all',
  marginBottom: 4,
  '& .workspaceDropdownBtnShrinked': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '& .workspaceDropdownBtn': {
    display: 'flex',
    cursor: 'pointer',
    // margin: shrinked ? '' : '4px 12px 8px 12px',
    padding: '8px 6px',
    borderRadius: 8,
    alignItems: 'center',
    '& svg': {
      color: '#616161',
      fontSize: 20,
    },
    '& .selectedWorkspaceTextCnt': {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      marginLeft: 6,
      overflow: 'hidden',
      '& .workspaceLabel': {
        fontSize: '12px',
        lineHeight: 'normal',
        color: '##757575',
        fontWeight: 600,
      },
      '& .workspaceName': {
        fontSize: 14,
        lineHeight: 'normal',
        fontWeight: 700,
        letterSpacing: '0.4px',
        color: '#212121',
        maxWidth: 140,
        whiteSpace: 'pre',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
  '&:hover': {
    background: 'rgba(238, 238, 238, 1)',
  },
}));

export const DropdownContentCnt = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: 16,
  gap: 8,
  '& .addIcon': {
    fontSize: 24,
    color: '#0000008A',
  },
  '& .doneIcon': {
    fontSize: 22,
    color: '#6868FE',
  },
});

export const StyledList = styled(List)({
  padding: '0',
  maxHeight: 300,
  overflowY: 'auto',
});

export const StyledSearchField = styled(OutlinedInput)({
  '& input': {
    padding: '6.5px 14px 6.5px 7px',
  },
  '& svg': {
    color: '#0000008A',
  },
  //will be removed once bootstrap is removed from app
  '& legend': {
    width: 0,
  },
});

export const GreyText = styled(Typography)({
  fontSize: '13px',
  fontWeight: 500,
  lineHeight: 'normal',
  color: 'rgba(97, 97, 97, 1)',
  letterSpacing: 0.15,
});

export const AddNewButton = styled(Button)({
  borderRadius: 6,
  background: '#6868FE',
  color: '#fff',
  width: '100%',
  fontWeight: 600,
  letterSpacing: 0.4,
  fontSize: 13,
  height: 36,
  '& svg > path': {
    stroke: '#fff',
    strokeWidth: 2,
    strokeOpacity: 1,
  },
  '&:hover': {
    background: '#4E48FA',
    color: '#fff',
  },
});
