import { useContext } from 'react';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import { CreateTaskContext } from 'components/AllDialogs/tasks/createTask/context/context';

const CreateTaskInput = ({ placeholder = 'Name your task or work order' }) => {
  const { updateTask, newTask, errors, setErrors } =
    useContext(CreateTaskContext);
  const inputValue = newTask.title || '';
  const handleInputChange = (e) => {
    const { title, ...rest } = errors;
    if (errors.title) {
      setErrors({ rest });
    }
    const value = e.target.value;
    updateTask({ title: value });
  };
  return (
    <CustomInput
      underlineInput={true}
      suppressErrorSpace={true}
      error={errors?.title}
      fieldProps={{
        placeholder,
        inputProps: { style: { fontSize: 24, padding: '5px 0' } },
        onChange: handleInputChange,
        value: inputValue,
      }}
    />
  );
};

export default CreateTaskInput;
