import React, { useContext } from 'react';
import { CreateRequestContext } from 'pages/PublicRequesterSubmission/requests/createRequest/context/context';
import LocationsDropdown from 'components/Dropdown/Task/Location/TaskLocationDropdown';
import { setDialog } from 'store/actions/dialogActions';
import { useDispatch } from 'react-redux';
import AssetsDropdown from 'components/Dropdown/Task/Asset/TaskAssetDropdown';
import { DropdownWithLabelCnt } from './createRequest.style';

const TaskAssetDropdown = () => {
  const { updateRequest, newRequest, errors } =
    useContext(CreateRequestContext);
  const dispatch = useDispatch();

  const handleUpdateAsset = (values) => {
    const option = values?.[0];
    updateRequest({
      AssetId: option?.id || null,
      ...(option?.obj?.LocationId
        ? { LocationId: option?.obj?.LocationId }
        : { LocationId: null }),
    });
  };
  return (
    <DropdownWithLabelCnt>
      <label>Asset</label>
      <AssetsDropdown
        onChangeCallback={handleUpdateAsset}
        popperProps={{ disablePortal: false }}
        dropdownProps={{ iconButton: true }}
        selected={newRequest?.AssetId}
        isMulti={false}
        location={newRequest?.LocationId}
        viewType={'select'}
        error={errors?.AssetId}
      />
      {errors?.AssetId && <span className="error-label">{errors.AssetId}</span>}
    </DropdownWithLabelCnt>
  );
};

export default TaskAssetDropdown;
