import React, { useContext, useEffect, useRef, useState } from 'react';
import CustomDialog from 'components/CustomDialog/customDialog';
import {
  CreateRequestDialogHeader,
  TaskFormCnt,
} from 'components/AllDialogs/requests/createRequest/createRequest.style';
import CreateRequestInput from 'components/AllDialogs/requests/createRequest/createRequestInput.cmp';
import TaskPriorityDropdown from 'components/AllDialogs/requests/createRequest/priorityDropdown.cmp';
import TaskCategoryDropdown from 'components/AllDialogs/requests/createRequest/categoryDropdown.cmp';
import TaskDescriptionInput from 'components/AllDialogs/requests/createRequest/requestDescriptionInput.cmp';
import TaskAttachment from 'components/AllDialogs/requests/createRequest/taskAttachment.cmp';
import { useDispatch, useSelector } from 'react-redux';
import { getDialogState } from 'store/selectors/dialogSelectors';
import { setDialog } from 'store/actions/dialogActions';
import { VALIDATION_ERROR_MESSAGES } from 'utils/globalVariable';
import xeniaApi from 'api/index';
import TaskLocationDropdown from 'components/AllDialogs/requests/createRequest/locationDropdown.cmp';
import moment from 'moment-timezone';
import classNames from 'classnames';
import actions from 'store/actions';
import useDateTime from 'utils/CustomHooks/useDateTime';
import { useTaskEdit } from 'controller/useTaskEdit';
import TaskAssetDropdown from 'components/AllDialogs/requests/createRequest/assetDropdown.cmp';
import AssetInfo from 'components/AllDialogs/requests/createRequest/assetsInfo.cmp';
import { isEmpty, isEqual } from 'lodash';
import useCreateRequest from 'utils/CustomHooks/useCreateRequest';
import { CreateRequestContext } from 'components/AllDialogs/requests/createRequest/context/context';
import DIALOGS from 'utils/dialogIds';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Grid } from '@mui/material';

const CreateRequestHeader = () => {
  return (
    <CreateRequestDialogHeader>
      <CreateRequestInput />
    </CreateRequestDialogHeader>
  );
};

function CreateRequest(props) {
  const { createRequest } = useCreateRequest();
  const [newRequest, setNewRequest] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [uploadsChange, setUploadsChange] = useState<boolean>(false);
  const [taskTimeChange, setTaskTimeChange] = useState<boolean>(false);
  const [taskTime, setTaskTime] = useState<any>({ startTime: '', dueTime: '' });
  const [showRepeatTask, setShowRepeatTask] = useState<boolean>(false);
  const [attachments, setAttachments] = useState<[]>([]);
  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('md'));

  const { handleUpdateTask } = useTaskEdit(newRequest);

  const [submitButtonLoading, setSubmitButtonLoading] =
    useState<boolean>(false);

  const dialogState = useSelector(getDialogState);
  const initialData = useRef(
    dialogState?.[DIALOGS.CREATE_EDIT_REQUEST]?.data?.requestData,
  );

  const dispatch = useDispatch();

  //Function to reset task form
  const resetTaskForm = () => {
    setNewRequestObj({});
    setErrors({});
    setShowRepeatTask(false);
    setAttachments([]);
  };

  //Reset task form on unmount
  useEffect(() => {
    return () => {
      resetTaskForm();
    };
  }, []);

  // Create Task
  const createNewRequest = async (data = {}) => {
    const postObj = {
      ...newRequest,
      ...data,
    };

    const requestResponse = await createRequest(postObj);
    if (requestResponse) {
      dialogState?.[DIALOGS.CREATE_EDIT_REQUEST]?.data?.onSuccessCallBack?.(
        requestResponse,
      );
      dispatch(
        setDialog({
          open: false,
          dialogId: DIALOGS.CREATE_EDIT_REQUEST,
        }),
      );
    } else {
      setSubmitButtonLoading(false);
    }
  };

  const editTask = async (data = {}) => {
    const { AssignId, title, checklistId } = newRequest;

    //In case of show less only minimized version of task should be created, other selected values will not be considered
    const postObj =
      // showLess
      // ? {
      //     ...(AssignId && { AssignId }),
      //     title,
      //     editInstance: editSeriesType,
      //     ...(checklistId && { checklistId }),
      //   }
      // :
      {
        ...newRequest,
        ...data,
      };

    const taskResponse = await handleUpdateTask(postObj);
    if (taskResponse) {
      dialogState?.[DIALOGS.CREATE_EDIT_REQUEST]?.data?.onSuccessCallBack?.();
      dispatch(actions.bulkUpdateTasks(taskResponse?.data));
      dispatch(
        setDialog({
          open: false,
          dialogId: 'createEditTask',
        }),
      );
    } else {
      setSubmitButtonLoading(false);
    }
  };

  //Task creation with attachments
  const createEditRequestWithAttachment = async () => {
    const response = await xeniaApi.CreateTaskAttchmentFilesApi(attachments);
    if (response) {
      const attachedList = editing
        ? [
            ...newRequest.attachment,
            ...response.filePath.map((item) => response.baseUrl + item),
          ]
        : response?.filePath;

      editing
        ? editTask({ attachment: attachedList })
        : createNewRequest({ attachments: attachedList });
    }
  };
  //Function passed to the context to update the root state of new create task
  const updateRequest: any = (value) => {
    setNewRequest({ ...newRequest, ...value });
  };
  //Function passed to the context to replace the new task object in state
  const setNewRequestObj: any = (value) => {
    setNewRequest(value);
  };
  //Submit create form
  const handleRequestFormSubmit = () => {
    //Title field is required, show error if title field is empty
    if (!newRequest.title) {
      setErrors({
        ...errors,
        title: VALIDATION_ERROR_MESSAGES.REQUEST_TITLE_REQUIRED,
      });
      return;
    }
    setSubmitButtonLoading(true);
    if (attachments?.length) {
      createEditRequestWithAttachment();
    } else {
      editing ? editTask() : createNewRequest();
    }
  };
  const closeAction = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS.CREATE_EDIT_REQUEST,
      }),
    );
  };

  const editing = dialogState?.[DIALOGS.CREATE_EDIT_REQUEST]?.data?.editing;
  const disableRecurringEdit = editing && newRequest?.recurringTask;

  const state: any = {
    updateRequest,
    newRequest,
    setNewRequest,
    showRepeatTask,
    setShowRepeatTask,
    attachments,
    setAttachments,
    errors,
    setErrors,
    setNewRequestObj,
    taskTime,
    setTaskTime,
    editing,
    disableRecurringEdit,
  };
  const isStateChanged =
    dialogState?.[DIALOGS.CREATE_EDIT_REQUEST]?.data?.isStateChanged;
  return (
    <CreateRequestContext.Provider value={state}>
      <CustomDialog
        actionButtonCntProps={{
          primaryBtnProps: {
            onClick: handleRequestFormSubmit,
            buttonLoader: submitButtonLoading,
            disabled:
              editing &&
              isEqual(initialData?.current, newRequest) &&
              !uploadsChange &&
              !isStateChanged,
          },
          secondaryBtnProps: { onClick: closeAction },
        }}
        primaryBtnText={editing ? 'Save' : 'Create'}
        header={<CreateRequestHeader />}
        dialogProps={{
          open: dialogState?.[DIALOGS.CREATE_EDIT_REQUEST]?.open,
          fullWidth: true,
          maxWidth: 'sm',
          fullScreen: isMobileDevice,
          onClose: closeAction,
        }}
      >
        <TaskFormCnt>
          <div className="iconBtnsDropdownCnt">
            <TaskPriorityDropdown />
            <TaskCategoryDropdown />
          </div>
          <div
            className={classNames({
              selectBtnsDropdownCnt: true,
            })}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TaskLocationDropdown />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TaskAssetDropdown />
              </Grid>
            </Grid>
          </div>
          <div
            className={classNames({
              iconBtnsDropdownCnt: true,
            })}
          >
            {!newRequest?.LocationId && newRequest?.AssetId ? (
              <AssetInfo />
            ) : null}
          </div>
          <div>
            <TaskDescriptionInput />
            <TaskAttachment setUploadsChange={setUploadsChange} />
          </div>
        </TaskFormCnt>
      </CustomDialog>
    </CreateRequestContext.Provider>
  );
}

export default CreateRequest;
