// Redux
import { useDispatch, useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { setMenuState } from 'store/actions';
import actions from 'store/actions';

// MUI
import { Box } from '@mui/material';
import Divider from '@mui/material/Divider';

// Custom components
import CustomToolTip from 'components/Tooltip/tooltip';
import ProfileDropdown from 'components/layout/sidebar/profileDropdown/profileDropdown';
import WorkspaceDropdown from 'components/Dropdown/WorkspaceDropdown';
import AddNew from 'components/layout/sidebar/addNew/AddNew';
import CustomMenu from 'components/layout/sidebar/customMenu/CustomMenu';
import { HasPermission } from 'components/HasPermission';

// Icons
import XeniaLogo from 'public/assets/img/xenia-logo-blue.svg';
import XeniaLogoSymbol from 'public/assets/img/xenia-logo-symbol.svg';
import SidebarToggleIcon from 'components/Icons/sidebarIcons/sidebarToggleIcon';
import StarsIcon from 'components/Icons/starsIcon';

// Styles
import { Drawer, DrawerHeader } from 'components/layout/sidebar/sidebar.style';
import {
  SidebarWrapper,
  WorkspaceAndToggleWrapper,
  BillingCTA,
  BillingCTACollapsed,
  BillingCTATextWrapper,
  ToggleButtonWrapper,
} from './sidebar.style';

// Utils
import DIALOGS from 'utils/dialogIds';

// Hooks
import useBilling from 'controller/useBilling';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';

export default function Sidebar({ open }) {
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const dispatch = useDispatch();
  const { PERMISSIONS } = useCustomPermission();

  const messagesUnreadCount: any = useSelector(
    selectors.getMessagesUnreadCount,
  );

  const { isActive } = useBilling();

  //Handle Navigate to path
  const handleNavClick = (path) => {
    navigateWithWorkspaceUrl(path);
  };
  const commonTooltipProps = { placement: 'right', disableTooltip: open };

  const handleToggleSidebar = () => {
    dispatch(setMenuState(!open));
  };

  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{
        width: open ? 250 : 70,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: open ? 250 : 70,
          boxSizing: 'border-box',
        },
      }}
    >
      <DrawerHeader open={open}>
        {open ? <img src={XeniaLogo} /> : <img src={XeniaLogoSymbol} />}

        {open && (
          <CustomToolTip
            title={open ? 'Collapse Sidebar' : 'Expand Sidebar'}
            placement="right"
          >
            <ToggleButtonWrapper onClick={handleToggleSidebar}>
              <SidebarToggleIcon open={open} />
            </ToggleButtonWrapper>
          </CustomToolTip>
        )}
      </DrawerHeader>
      <Divider />
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          height: 'calc(100% - 109px)',
          overflowY: 'auto',
          overflowX: 'hidden',
          padding: !open ? '12px 15px 0px 15px' : '4px 12px 0px 12px',
          overscrollBehavior: 'contain',
        }}
      >
        <SidebarWrapper>
          <Box>
            {open ? (
              <WorkspaceDropdown shrinked={!open} />
            ) : (
              <WorkspaceAndToggleWrapper>
                <CustomToolTip
                  title={open ? 'Collapse Sidebar' : 'Expand Sidebar'}
                  placement="right"
                >
                  <ToggleButtonWrapper onClick={handleToggleSidebar}>
                    <SidebarToggleIcon open={open} />
                  </ToggleButtonWrapper>
                </CustomToolTip>
                <WorkspaceDropdown shrinked={!open} />
              </WorkspaceAndToggleWrapper>
            )}

            {open && <AddNew sidebarOpen={open} />}
            {!open && <Divider sx={{ m: '2px 0px 14px 0px' }} />}
            {!open && (
              <Box sx={{ mb: '8px' }}>
                <AddNew sidebarOpen={open} />
              </Box>
            )}

            <CustomMenu
              handleNavClick={handleNavClick}
              sidebarOpen={open}
              messagesUnreadCount={messagesUnreadCount}
            />
          </Box>
          <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_BILLING]}>
            {!isActive && (
              <>
                {open ? (
                  <div style={{ position: 'relative' }}>
                    <BillingCTA
                      onClick={() =>
                        dispatch(
                          actions.setDialog({
                            dialogId: DIALOGS.BILLING.PLANS_MODAL,
                            open: true,
                            data: {
                              origin: 'greenViewPricingPlansButton',
                            },
                          }),
                        )
                      }
                    >
                      <BillingCTATextWrapper>
                        View Pricing Plans
                      </BillingCTATextWrapper>
                      <StarsIcon />
                    </BillingCTA>
                  </div>
                ) : (
                  <CustomToolTip
                    {...commonTooltipProps}
                    title={'View Pricing Plans'}
                    placement={'right'}
                    onClick={() =>
                      dispatch(
                        actions.setDialog({
                          dialogId: DIALOGS.BILLING.PLANS_MODAL,
                          open: true,
                        }),
                      )
                    }
                  >
                    <BillingCTACollapsed>
                      <StarsIcon />
                    </BillingCTACollapsed>
                  </CustomToolTip>
                )}
              </>
            )}
          </HasPermission>
        </SidebarWrapper>

        <div className="profileDropdownCnt">
          <Divider light={true} />
          <ProfileDropdown open={open} />
        </div>
      </Box>
    </Drawer>
  );
}
