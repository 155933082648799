import React, { useState, useEffect, useContext, useMemo } from 'react';
import {
  RepeatWeeklyCnt,
  WeekDaysCnt,
} from 'components/RepeatTask/Weekly/weekly.style';
import CustomButton from 'components/Button/CustomButton';
import { DatePickerDropdown } from 'components/Dropdown/DatePicker/datePicker.cmp';
import { Grid, Stack, Typography } from '@mui/material';
import { CustomSelect } from 'components/CustomSelect/CustomSelect';
import moment from 'moment';
import { TimePickerDropdown } from 'components/Dropdown/TimePicker/timePicker.cmp';
import { days, weeks } from './constants';
import { hoursArr } from 'components/Dropdown/Task/DateRangePicker/constants';
import { CreateTaskContext } from 'components/AllDialogs/tasks/createTask/context/context';
import ScheduleDatePickerDropdown from 'components/Dropdown/ScheduleDatePicker/datePicker.cmp';
import TimeListDropdown from '../components/TimeListDropdown';
import AdvancedOptions from '../components/AdvancedOptions';

function RepeatDaily(props) {
  const {
    onDaysSelect,
    onWeekSelect,
    onRecurUntilSelect,
    repeatDailySelectedValues,
    onDueTimeSelect,
    onStartTimeSelect,
    scheduleDateTimeSelect,
    isDropdown,
    newTask,
    updateTask,
  } = props;
  const {
    intervalWeek,
    recurringByEvery,
    startDate,
    endDate,
    taskTime,
    isWeekTimeEdit,
    setIsWeekTimeEdit,
    scheduledDateTime,
    disableScheduleStartingFrom,
  } = repeatDailySelectedValues;

  const [selectedDays, setSelectedDays] = useState<any[]>([]);
  const [selectedWeek, setSelectedWeek] = useState<any>(null);
  useEffect(() => {
    const preSelectedWeek = weeks.find((w) => w.id == recurringByEvery);
    setSelectedWeek(preSelectedWeek);
    setSelectedDays(intervalWeek);
  }, [repeatDailySelectedValues]);

  const handleSetSelectedDays = (e, value) => {
    if (selectedDays?.includes(value)) {
      const valuesAfterFilter = selectedDays.filter((d) => d !== value);
      //Minimum one should be selected
      if (!valuesAfterFilter?.length) {
        return;
      }
      setSelectedDays(valuesAfterFilter);
      onDaysSelect?.(valuesAfterFilter); // on Change callback to remove the value on toggle
    } else {
      onDaysSelect?.([...selectedDays, value]); // on Change callback to add the value on toggle
      setSelectedDays([...selectedDays, value]);
    }
  };

  const handleSelectStartTime = (value) => {
    onStartTimeSelect(value);
  };

  const handleSelectDueTime = (value) => {
    onDueTimeSelect(value);
  };

  //Commented for later use - do not remove
  // const handleSelectStartDate = (value) => {
  //   onStartDateSelect?.(value);
  // };

  const isStartTimeDisabled = (i) => {
    const dueTimeIndex = hoursArr?.indexOf(taskTime.dueTime);

    return taskTime.dueTime && dueTimeIndex && i > dueTimeIndex - 1
      ? true
      : false;
  };
  const isDueTimeDisabled = (i) => {
    const startTimeIndex = hoursArr?.indexOf(taskTime.startTime);

    return taskTime.startTime && startTimeIndex !== -1 && i < startTimeIndex + 1
      ? true
      : false;
  };

  const handleStartTimeClear = () => {
    handleSelectStartTime('');
  };

  const handleDueTimeClear = () => {
    handleSelectDueTime('');
  };

  const handleStartTimeEditButton = () => {
    setIsWeekTimeEdit((prev) => ({ ...prev, startTime: true }));
  };
  const handleEndTimeEditButton = () => {
    setIsWeekTimeEdit((prev) => ({ ...prev, endTime: true }));
  };

  const disableDatesFrom = useMemo(() => {
    if (scheduledDateTime) {
      return moment(scheduledDateTime)
        ?.add(1, 'days')
        ?.format('YYYY-MM-DDTHH:mm:ss');
    }
  }, [scheduledDateTime]);

  return (
    <Stack gap="8px">
      <Stack flexWrap={'wrap'} direction="row" gap="6px" alignItems={'center'}>
        <Typography
          width={isDropdown ? '100%' : 'max-content'}
          fontWeight={'500'}
          fontSize={'13px'}
          color="#212121"
        >
          Recur on:
        </Typography>
        {days.map((d) => (
          <CustomButton
            variant={selectedDays?.includes(d) ? 'contained' : 'outlined'}
            buttonType={selectedDays?.includes(d) ? 'primary' : 'grayWhite'}
            // onClick={(e) => handleSetSelectedDays(e, d)}
            sx={{ borderRadius: '6px', height: '32px', width: '62px' }}
          >
            {d}
          </CustomButton>
        ))}
      </Stack>
      <Stack direction={'row'} gap="6px" alignItems={'center'}>
        <Typography
          width="58px"
          fontWeight={'500'}
          fontSize={'13px'}
          color="#212121"
        >
          Time:
        </Typography>
        <TimeListDropdown
          label={'Start'}
          time={taskTime?.startTime}
          onChange={(time) => handleSelectStartTime(time)}
          setTime={(time) => console.log(time)}
          isTimeDisabled={isStartTimeDisabled}
          handleClearTime={handleStartTimeClear}
          buttonProps={{
            minWidth: '83px',
          }}
        />
        <Typography fontWeight={'500'} fontSize={'13px'} color="#212121">
          -
        </Typography>
        <TimeListDropdown
          label={'Due'}
          time={taskTime?.dueTime}
          onChange={(time) => handleSelectDueTime(time)}
          setTime={(time) => console.log(time)}
          isTimeDisabled={isDueTimeDisabled}
          handleClearTime={handleDueTimeClear}
          buttonProps={{
            minWidth: '83px',
          }}
        />
      </Stack>
      <Grid
        flexDirection={'row'}
        alignItems={'center'}
        direction="row"
        container
        spacing={isDropdown ? 1 : 2}
      >
        <Grid
          item
          xs={12}
          md={isDropdown ? 12 : 6}
          alignItems={'center'}
          direction={'row'}
          gap="4px"
          display={'flex'}
          flexWrap={'wrap'}
        >
          <Typography
            width={isDropdown ? '100%' : 'max-content'}
            fontWeight={'500'}
            fontSize={'13px'}
            color="#212121"
          >
            Starting from:
          </Typography>
          <ScheduleDatePickerDropdown
            datePickerProps={{
              disabled: (day: Date) => {
                return moment(day).isBefore(new Date(), 'day');
              },
            }}
            disabled={disableScheduleStartingFrom}
            buttonProps={{
              style: {
                borderRadius: '6px',
                height: '32px',
                padding: '0px 12px',
                width: '173px',
                justifyContent: 'space-between',
              },
            }}
            onDateSelect={scheduleDateTimeSelect}
            selected={scheduledDateTime}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={isDropdown ? 12 : 6}
          alignItems={'center'}
          direction="row"
          gap="4px"
          display={'flex'}
          flexWrap={'wrap'}
        >
          <Typography
            width={isDropdown ? '100%' : 'max-content'}
            fontWeight={'500'}
            fontSize={'13px'}
            color="#212121"
          >
            Until:
          </Typography>
          <ScheduleDatePickerDropdown
            datePickerProps={{
              disabled: (day: Date) => {
                return moment(day).isBefore(disableDatesFrom, 'day');
              },
            }}
            defaultLabel="Forever"
            buttonProps={{
              style: {
                borderRadius: '6px',
                height: '32px',
                padding: '0px 12px',
                width: '173px',
                justifyContent: 'space-between',
              },
            }}
            onDateSelect={onRecurUntilSelect}
            selected={endDate}
          />
        </Grid>
      </Grid>
      <AdvancedOptions newTask={newTask} updateTask={updateTask} />
      <Typography
        component={'i'}
        fontSize={'13px'}
        fontWeight={'400'}
        color="#424242"
      >
        Repeat Daily
      </Typography>
    </Stack>
  );
}
export default RepeatDaily;
