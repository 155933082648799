import CustomInput from 'components/Form/TextField/TextField.cmp';
import React, { useContext } from 'react';
import {
  FieldCnt,
  InputWithLabel,
} from 'components/AllDialogs/assets/createAsset/createAsset.style';
import { CreateAssetContext } from 'components/AllDialogs/assets/createAsset/context/context';
import LocationsDropdown from 'components/Dropdown/Task/Location/TaskLocationDropdown';

const AssetLocationDropdown = (props) => {
  const { updateAsset, asset } = props;
  const selectedValue = asset.LocationId || null;
  const handleOnChangeCallback = (option) => {
    updateAsset({ LocationId: option?.[0]?.id || null });
  };
  return (
    <InputWithLabel style={{ marginTop: 4, marginBottom: 26 }}>
      <label>Location</label>
      <FieldCnt>
        {' '}
        <LocationsDropdown
          onChangeCallback={handleOnChangeCallback}
          selected={[selectedValue]}
          viewType="select"
          isMulti={false}
        />
      </FieldCnt>
    </InputWithLabel>
  );
};

export default AssetLocationDropdown;
