import { isNumber } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import selectors from 'store/selectors';
import { PERMISSIONS } from 'utils/constants';

export const FEATURES = {
  PUBLISH_CHECKLISTS: 'Publish Checklists',
  MULTI_WORKSPACES: 'Multi Workspaces',
  SUB_LOCATIONS: 'Sub-locations',
  SUB_ASSETS: 'Sub-assets',
  LOGO_UPLOAD: 'Logo Upload',
  INAPP_NOTIFICATIONS: 'InApp Notifications',
  WORK_ORDERS_SEARCH: 'Work Orders Search',
  WORK_ORDERS_FILTERS: 'Work Orders Filters',
  CALENDER_VIEW: 'Calendar View',
  TEMPLATE_DOCUMENTS: 'Template Documents',
  TIME_TRACKING: 'Work Order Time-Tracking',
  TEMPLATE_EMAIL_NOTIFICATIONS: 'Template Email Notifications',
  REPORTING_DASHBOARD: 'Reporting Dashboard',
  PUBLISH_REPORTS: 'Publish Reports',
  TEMPLATE_REPORT_LOGO: 'Template Report Logo',
  REPORTING_DATE_FILTER: 'Reporting Date Filter',
  CONVERT_SUBMISSION_TO_WORK_ORDER: 'Convert Submission to Work Order',
  WORK_ORDER_COST: 'Track Work Order Cost',
  CAN_REQUEST_TASK: 'Work Order Request',
  SCAN_QR_CODE: 'Scan QR',
  AUTOMATIONS: 'Automations',
  PUBLIC_WORK_ORDER_REQUEST: 'Public Work Order Request',
  GEOSTAMP_STEP: 'GeoStamp Step',
  ADVANCED_LOCATION_BASED_ASSIGNMENT: 'Advanced Location Based Assignment',
  WEIGHTED_SCORING: 'Weighted Scoring',
  ASSET_SERVICE: 'Asset Service',
  AUTOMATION_AUTO_CREATE_TASK: 'Auto-Create Task from Template',
  KIOSK_MODE: 'Kiosk Mode',
  PROJECTS: 'Projects',
  SSO: 'SSO',
  CUSTOM_ROLES: 'Custom Roles',
};

const PAYWALL_LIMITS = {
  ONE_OFF_TASKS: 'Work Orders',
  RECURRING_TASKS: 'Recurring Scheduled Work',
  OPERATIONS_TEMPLATES: 'Operations Templates',
  TEMPLATE_SUBMISSIONS: 'Template Submissions',
  UPLOAD_LIMIT: 'Upload Limit',
  TEAMS_WALL: 'Teams',
  USERS: 'Users',
  ASSETS: 'Assets',
};

const PAYWALL_LIMITS_NAMES = {
  [PAYWALL_LIMITS.ONE_OFF_TASKS]: 'Work Orders',
  [PAYWALL_LIMITS.RECURRING_TASKS]: 'Recurring Tasks',
  [PAYWALL_LIMITS.OPERATIONS_TEMPLATES]: 'Operations Templates',
  [PAYWALL_LIMITS.TEMPLATE_SUBMISSIONS]: 'Template Submissions',
  [PAYWALL_LIMITS.UPLOAD_LIMIT]: 'Upload Limit',
  [PAYWALL_LIMITS.TEAMS_WALL]: 'Teams',
  [PAYWALL_LIMITS.USERS]: 'Users',
  [PAYWALL_LIMITS.ASSETS]: 'Assets',
};

export function useCustomPermission() {
  const params = useParams();
  const permissions: any = useSelector(selectors.getUserAllPermissions);
  const paywallUsage: any = useSelector(selectors.getPaywallUsage);
  const workspaceId = params.workspaceId || '';
  const featuresHash = useSelector(selectors.getUserAllHotelFeaturesHash);

  const currentWSPermissions = useMemo(() => {
    return workspaceId.length ? permissions?.[workspaceId] || {} : {};
  }, [workspaceId, permissions]);

  const currentWSFeatures = useMemo(() => {
    return featuresHash ? featuresHash[workspaceId] : [];
  }, [workspaceId, featuresHash]);

  const hasPermission = (permission, wsId = null as any) => {
    if (!wsId)
      return currentWSPermissions
        ? currentWSPermissions[permission] || false
        : false;
    const wsPermissions = permissions[wsId] || {};
    return wsPermissions[permission] || false;
  };

  const hasFeature = (feature, wsId = null as any) => {
    if (!wsId) return currentWSFeatures?.[feature] || false;
    const wsFeatures = featuresHash ? featuresHash[wsId] : [];
    return wsFeatures?.[feature] || false;
  };

  const remainingFeatureCount = (feature) => {
    return isNumber(paywallUsage?.[feature])
      ? paywallUsage?.[feature] < 0
        ? 0
        : paywallUsage?.[feature]
      : 1;
  };

  const hasFeatureLocked = (feature) => {
    return remainingFeatureCount(feature) > 0;
  };

  return {
    hasPermission,
    hasFeature,
    remainingFeatureCount,
    hasFeatureLocked,
    currentWSPermissions,
    currentWSFeatures,
    PERMISSIONS,
    FEATURES,
    PAYWALL_LIMITS,
    PAYWALL_LIMITS_NAMES,
  };
}
