import { useCallback, useEffect, useMemo, useState } from 'react';

// Redux
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';

// Custom components
import CustomListView from 'components/ListView/Listview.cmp';
import LogDrawer from '../LogDrawer';

// AG Grid
import {
  defaultColDef,
  getRowStyles,
  columns,
} from 'pages/Widgets/SubmissionsListing/SubmissionListView/constants';

// Utils
import xeniaApi from 'api/index';
import { TasksListSummaryCnt } from 'pages/Widgets/SubmissionsListing/SubmissionListView/styled';
import { WidgetHeading, WidgetLoaderCnt } from 'pages/Widgets/widgets.style';
import CircularProgress from '@mui/material/CircularProgress';
import SubmissionListWidgetEmptyState from 'pages/Widgets/SubmissionsListing/SubmissionListView/EmptyRecords/SubmissionListWidgetEmptyState.cmp';
import 'pages/Widgets/SubmissionsListing/SubmissionListView/styles.css';
import { SubmissionReportType } from 'pages/TemplateDashboard/Tabs/Submissions/context';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { getUserConfig, editUserConfig } from 'api/userConfig';

const SubmissionListSummary = ({
  filters,
  otherElementsHeight = 0,
  isLogs = false,
}) => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [activeLog, setActiveLog] = useState<SubmissionReportType>();
  const [tableConfig, setTableConfig] = useState<any>(null);
  const userId = useSelector(selectors.getUserId);
  const { workspaceId } = useWorkspaceHook();

  const fetchSubmissionsList = async () => {
    // Fetch Report data
    setLoading(true);
    const requestPayload = {
      ...filters,
    };

    if (isLogs) {
      const data = await xeniaApi.getAllChecklistLogs(requestPayload);
      setData(data?.data);
    } else {
      const data = await xeniaApi.getSubmissionList(requestPayload);
      setData(data);
    }

    setLoading(false);
  };

  const fetchTableConfig = async () => {
    const config = await getUserConfig(`templateSubmissionList:${userId}`);
    if (config?.config?.config) {
      const colConfig = config.config.config;
      setTableConfig(colConfig);
    }
  };

  useEffect(() => {
    fetchSubmissionsList();
  }, [filters]);

  useEffect(() => {
    if (userId) {
      fetchTableConfig();
    }
  }, [userId]);

  const onRowClick = async (id: string) => {
    const log = await xeniaApi.getChecklistLog({
      logId: id,
      hotelId: workspaceId,
      filtered: true,
      sectioned: true,
    });
    if (log?.data) {
      setActiveLog(log.data);
    } else {
      setActiveLog(undefined);
    }
  };

  //Hiding last 2 options for columns menu
  const getMainMenuItems = (params) => {
    const newOptions = params.defaultItems.slice(
      0,
      params.defaultItems.length - 3,
    );
    return newOptions;
  };

  const getRowHeight = useCallback((params) => {
    return 50;
  }, []);

  const handleArchiveApi = async (logs: any[]) => {
    const logIds = logs.map((log) => log.id);
    await xeniaApi.deleteChecklistLogs({
      checklistId: activeLog?.ChecklistId,
      hotelId: workspaceId,
      postData: {
        logIds,
      },
    });
    setData((prevData) => prevData.filter((log) => !logIds.includes(log.id)));
    setActiveLog(undefined);
  };

  const gridColumns = useMemo(() => {
    const staticColumns = columns({ fetchSubmissionsList });
    const dynamicColumns = staticColumns.map((col) => {
      const configCol = tableConfig?.find((c) => c.colId === col.colId);
      const index = tableConfig?.findIndex((c) => c.colId === col.colId);
      if (configCol) {
        return {
          ...col,
          ...configCol,
          order: index,
        };
      }
      return col;
    });
    const sortedColumns = dynamicColumns.sort((a, b) => a.order - b.order);
    return sortedColumns;
  }, [fetchSubmissionsList, tableConfig]);

  const updateColumns = async (columnState: any[]) => {
    const previousTableConfig = tableConfig || [];
    setTableConfig(columnState);
    const updatedConfig = await editUserConfig({
      type: `templateSubmissionList:${userId}`,
      config: columnState,
    });
    if (!updatedConfig?.config?.config) {
      setTableConfig(previousTableConfig);
    }
  };

  const onColumnResized = (event) => {
    if (event?.finished && event.source !== 'api') {
      updateColumns(event.columnApi.getColumnState());
    }
  };

  const handleUpdateColumnState = (event) => {
    updateColumns(event.columnApi.getColumnState());
  };

  return (
    <TasksListSummaryCnt
      className={'taskListSummaryCnt'}
      style={{
        height: `calc(100vh - ${otherElementsHeight}px)`,
      }}
    >
      <LogDrawer
        open={!!activeLog}
        onClose={() => setActiveLog(undefined)}
        log={activeLog as SubmissionReportType}
        handleArchiveLog={handleArchiveApi}
      />
      {loading && (
        <WidgetLoaderCnt>
          <CircularProgress />
        </WidgetLoaderCnt>
      )}
      {!loading && data?.length === 0 && <SubmissionListWidgetEmptyState />}
      {!loading && data?.length > 0 && (
        <CustomListView
          rootProps={{
            style: {
              height: 'inherit',
              boxShadow: 'none',
            },
          }}
          onRowClick={onRowClick}
          gridProps={{
            ref: (ref) => {
              globalThis.taskSummaryListGrid = ref;
            },
            onSortChanged: handleUpdateColumnState,
            onDragStopped: handleUpdateColumnState,
            onColumnResized: onColumnResized,
            onColumnVisible: handleUpdateColumnState,
            onColumnPinned: handleUpdateColumnState,
            onColumnMoved: handleUpdateColumnState,
            columnDefs: gridColumns,
            headerHeight: 30,
            rowData: data,
            suppressChangeDetection: true,
            defaultColDef: defaultColDef,
            suppressCellFocus: true,
            getMainMenuItems: getMainMenuItems,
            getRowHeight: getRowHeight,
            getRowStyle: getRowStyles,
            suppressRowClickSelection: true,
            getRowId: (params) => params.data.id,
          }}
        />
      )}
    </TasksListSummaryCnt>
  );
};

export default SubmissionListSummary;
