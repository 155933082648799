import React, { useContext, useEffect, useState } from 'react';
import { CreateTaskContext } from 'components/AllDialogs/tasks/editTask/context/context';
import { iconToComponentMapping } from 'pages/checklistV2/mappings';
import {
  AddChecklistCnt,
  AddChecklistText,
  SelectedChecklist,
  Checkbox,
} from 'components/AllDialogs/tasks/editTask/createTask.style';
import { Divider, IconButton, Stack, Typography } from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircleOutline';
import ChecklistIcon from 'components/Icons/checklistIcon';
import { useSelector, useDispatch } from 'react-redux';
import selectors from 'store/selectors';
import actions from 'store/actions';
import DIALOGS from 'utils/dialogIds';
import appConstants from 'utils/appConstants';
import { VerifyFeatureAccess } from 'components/UpSellModal/VerifyFeatureAccess';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';

const AddChecklist = () => {
  const dispatch = useDispatch();
  const checklistList = useSelector(selectors.getChecklistCompleteDataOnly);
  const { updateTask, newTask } = useContext(CreateTaskContext);

  const { PAYWALL_LIMITS } = useCustomPermission();

  const {
    hideElements,
    attachedChecklist,
    setAttachedChecklist,
    isChecklistRequired,
    setIsChecklistRequired,
  } = useContext(CreateTaskContext);

  const handleRemoveChecklist = () => {
    setAttachedChecklist(null);
    updateTask({ checklistId: null, ChecklistId: null });
  };
  useEffect(() => {
    const selectedChecklist = checklistList?.find(
      (c) => c.id == newTask.checklistId || c.id == newTask.ChecklistId,
    );
    setAttachedChecklist(selectedChecklist);
  }, [newTask.checklistId, newTask.ChecklistId]);

  useEffect(() => {
    setIsChecklistRequired(newTask.isChecklistRequired);
  }, [newTask.isChecklistRequired]);

  const MuiIcon = ({ name, ...rest }) => {
    const IconComponent = iconToComponentMapping[name];
    return IconComponent ? <IconComponent {...rest} /> : null;
  };

  const handleAttachChecklist = (checklist) => {
    setAttachedChecklist(checklist);
    updateTask({ checklistId: checklist.id });
  };

  const handleChecklistRequired = (checked) => {
    setIsChecklistRequired(checked);
    updateTask({ isChecklistRequired: checked });
  };

  const openAttachChecklistModal = () => {
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.ATTACH_CHECKLIST,
        open: true,
        data: {
          onClickAttach: (checklist) => handleAttachChecklist(checklist),
        },
      }),
    );
  };
  return (
    <>
      {attachedChecklist ? (
        <>
          <SelectedChecklist>
            <div className="leftCnt">
              <span
                className="checklistIcon"
                style={{ background: attachedChecklist?.icon?.color }}
              >
                <MuiIcon
                  name={attachedChecklist?.icon?.icon}
                  sx={{ fontSize: '28px' }}
                />
              </span>

              <div className="checklistNameCnt">
                <span className="checklistName">{attachedChecklist?.name}</span>
                <span className="checklistItemCount">
                  {`${attachedChecklist?.itemsCount} items`}
                </span>
              </div>
            </div>
            <IconButton
              className={'removeIconBtn'}
              onClick={handleRemoveChecklist}
            >
              <RemoveCircleIcon className="removeIcon" />
            </IconButton>
          </SelectedChecklist>
          {!hideElements?.isRequiredChecklistCheckbox && (
            <>
              <Stack direction={'row'} mb={'20px'}>
                <Checkbox
                  checked={isChecklistRequired}
                  disableRipple
                  onChange={(e) => handleChecklistRequired(e?.target?.checked)}
                />
                <Typography fontWeight={'500'} fontSize={'14px'} color="#000">
                  Completion of the template is required to finish this task.{' '}
                </Typography>
              </Stack>
              <Divider sx={{ marginBottom: '15px' }} />
            </>
          )}
        </>
      ) : (
        <VerifyFeatureAccess
          paywallUsageId={PAYWALL_LIMITS.OPERATIONS_TEMPLATES}
        >
          <AddChecklistCnt onClick={openAttachChecklistModal}>
            <ChecklistIcon primary="#6868FE" />
            <AddChecklistText>
              Add {appConstants.checklist.singular.base}
            </AddChecklistText>
          </AddChecklistCnt>
        </VerifyFeatureAccess>
      )}
    </>
  );
};

export default AddChecklist;
