import React, { useState, useEffect, useContext, useMemo } from 'react';
import {
  RepeatWeeklyCnt,
  WeekDaysCnt,
} from 'components/RepeatTask/Weekly/weekly.style';
import CustomButton from 'components/Button/CustomButton';
import { DatePickerDropdown } from 'components/Dropdown/DatePicker/datePicker.cmp';
import { Grid, Stack, Typography, Box } from '@mui/material';
import { CustomSelect } from 'components/CustomSelect/CustomSelect';
import moment from 'moment';
import { TimePickerDropdown } from 'components/Dropdown/TimePicker/timePicker.cmp';
import { days, weeks } from './constants';
import { hoursArr } from 'components/Dropdown/Task/DateRangePicker/constants';
import { CreateTaskContext } from 'components/AllDialogs/tasks/createTask/context/context';
import ScheduleDatePickerDropdown from 'components/Dropdown/ScheduleDatePicker/datePicker.cmp';
import TimeListDropdown from '../components/TimeListDropdown';
import AdvancedOptions from '../components/AdvancedOptions';
import RecurEveryDropdown from '../components/RecurEveryDropdown';

function RepeatWeekly(props) {
  const {
    onDaysSelect,
    onWeekSelect,
    onRecurUntilSelect,
    repeatWeeklySelectedValues,
    onDueTimeSelect,
    onStartTimeSelect,
    scheduleDateTimeSelect,
    isDropdown,
    newTask,
    updateTask,
  } = props;
  const {
    intervalWeek,
    recurringByEvery,
    startDate,
    endDate,
    taskTime,
    isWeekTimeEdit,
    setIsWeekTimeEdit,
    scheduledDateTime,
    disableScheduleStartingFrom,
  } = repeatWeeklySelectedValues;

  const [selectedDays, setSelectedDays] = useState<any[]>([]);
  const [selectedWeek, setSelectedWeek] = useState<any>(null);
  useEffect(() => {
    const preSelectedWeek = weeks.find((w) => w.id == recurringByEvery);
    setSelectedWeek(preSelectedWeek);
    setSelectedDays(intervalWeek);
  }, [repeatWeeklySelectedValues]);

  const handleSetSelectedDays = (e, value) => {
    if (selectedDays?.includes(value)) {
      const valuesAfterFilter = selectedDays.filter((d) => d !== value);
      //Minimum one should be selected
      if (!valuesAfterFilter?.length) {
        return;
      }
      setSelectedDays(valuesAfterFilter);
      onDaysSelect?.(valuesAfterFilter); // on Change callback to remove the value on toggle
    } else {
      onDaysSelect?.([...selectedDays, value]); // on Change callback to add the value on toggle
      setSelectedDays([...selectedDays, value]);
    }
  };
  const handleWeekSelect = (option) => {
    setSelectedWeek(option);
    onWeekSelect(option);
  };
  const handleSelectStartTime = (value) => {
    onStartTimeSelect(value);
  };
  const handleSelectDueTime = (value) => {
    onDueTimeSelect(value);
  };
  //Commented for later use - do not remove
  // const handleSelectStartDate = (value) => {
  //   onStartDateSelect?.(value);
  // };
  const isStartTimeDisabled = (i) => {
    const dueTimeIndex = hoursArr?.indexOf(taskTime.dueTime);

    return taskTime.dueTime && dueTimeIndex && i > dueTimeIndex - 1
      ? true
      : false;
  };
  const isDueTimeDisabled = (i) => {
    const startTimeIndex = hoursArr?.indexOf(taskTime.startTime);

    return taskTime.startTime && startTimeIndex !== -1 && i < startTimeIndex + 1
      ? true
      : false;
  };
  const handleStartTimeClear = () => {
    handleSelectStartTime('');
  };
  const handleDueTimeClear = () => {
    handleSelectDueTime('');
  };
  const handleStartTimeEditButton = () => {
    setIsWeekTimeEdit((prev) => ({ ...prev, startTime: true }));
  };
  const handleEndTimeEditButton = () => {
    setIsWeekTimeEdit((prev) => ({ ...prev, endTime: true }));
  };
  const repeatText = useMemo(
    () =>
      generateScheduleDescription(
        selectedDays,
        recurringByEvery,
        scheduledDateTime,
        endDate,
      ),
    [props],
  );

  function generateScheduleDescription(
    selectedDays: string[],
    recurrenceFrequency: string,
    startDate?: Date,
    endDate?: Date,
  ): string {
    const weekMap: { [key: string]: string } = {
      Week1: 'week',
      Weeks2: '2 weeks',
      Weeks3: '3 weeks',
      Weeks4: '4 weeks',
      Weeks5: '5 weeks',
      Weeks6: '6 weeks',
    };
    const daysMap: { [key: string]: string } = {
      Mon: 'Monday',
      Tue: 'Tuesday',
      Wed: 'Wednesday',
      Thu: 'Thursday',
      Fri: 'Friday',
      Sat: 'Saturday',
      Sun: 'Sunday',
    };

    let description = 'Repeat every';
    const daysOfWeek =
      selectedDays?.length === 7
        ? 'everyday'
        : selectedDays?.map((d) => daysMap[d]).join(', ');

    description += ` ${weekMap[recurrenceFrequency] || ''} on ${daysOfWeek}`;

    if (startDate) {
      const formattedDate = moment(startDate).format('MMM D, YYYY');
      description += `, starting from ${formattedDate}`;
    } else {
      description += `, starting from today`;
    }

    if (endDate) {
      const formattedDate = moment(endDate).format('MMM D, YYYY');
      description += `, till ${formattedDate}`;
    }

    return description;
  }

  const disableDatesFrom = useMemo(() => {
    if (scheduledDateTime) {
      return moment(scheduledDateTime)
        ?.add(1, 'days')
        ?.format('YYYY-MM-DDTHH:mm:ss');
    }
  }, [scheduledDateTime]);

  return (
    <Stack gap="8px">
      <Stack flexWrap={'wrap'} direction="row" gap="6px" alignItems={'center'}>
        <Typography
          width={isDropdown ? '100%' : 'max-content'}
          fontWeight={'500'}
          fontSize={'13px'}
          color="#212121"
        >
          Recur on:
        </Typography>
        {days.map((d) => (
          <CustomButton
            variant={selectedDays?.includes(d) ? 'contained' : 'outlined'}
            buttonType={selectedDays?.includes(d) ? 'primary' : 'grayWhite'}
            onClick={(e) => handleSetSelectedDays(e, d)}
            sx={{ borderRadius: '6px', height: '32px', width: '62px' }}
          >
            {d}
          </CustomButton>
        ))}
      </Stack>
      <Grid
        container
        spacing={isDropdown ? 1 : 2}
        direction="row"
        alignItems={'center'}
      >
        <Grid
          gap="4px"
          display={'flex'}
          alignItems={'center'}
          item
          xs={12}
          md={isDropdown ? 12 : 6}
        >
          <Typography fontWeight={'500'} fontSize={'13px'} color="#212121">
            Recur every:
          </Typography>
          <Stack flex={1}>
            <RecurEveryDropdown
              onOptionClick={handleWeekSelect}
              selectedOption={selectedWeek}
              options={weeks}
            />
          </Stack>
        </Grid>

        <Grid
          gap="4px"
          display={'flex'}
          alignItems={'center'}
          item
          xs={12}
          md={isDropdown ? 12 : 6}
        >
          <Typography fontWeight={'500'} fontSize={'13px'} color="#212121">
            Time:
          </Typography>
          <TimeListDropdown
            label={'Start'}
            time={taskTime?.startTime}
            onChange={(time) => handleSelectStartTime(time)}
            setTime={(time) => console.log(time)}
            isTimeDisabled={isStartTimeDisabled}
            handleClearTime={handleStartTimeClear}
            buttonProps={{
              width: '106.5px',
            }}
          />
          <Typography fontWeight={'500'} fontSize={'13px'} color="#212121">
            -
          </Typography>
          <TimeListDropdown
            label={'Due'}
            time={taskTime?.dueTime}
            onChange={(time) => handleSelectDueTime(time)}
            setTime={(time) => console.log(time)}
            isTimeDisabled={isDueTimeDisabled}
            handleClearTime={handleDueTimeClear}
            buttonProps={{
              width: '106.5px',
            }}
          />
        </Grid>
      </Grid>
      <Grid
        flexDirection={'row'}
        alignItems={'center'}
        direction="row"
        container
        spacing={isDropdown ? 1 : 2}
      >
        <Grid
          item
          xs={12}
          md={isDropdown ? 12 : 6}
          alignItems={'center'}
          direction={'row'}
          gap="4px"
          display={'flex'}
          flexWrap={'wrap'}
        >
          <Typography
            width={isDropdown ? '100%' : 'max-content'}
            fontWeight={'500'}
            fontSize={'13px'}
            color="#212121"
          >
            Starting from:
          </Typography>
          <ScheduleDatePickerDropdown
            datePickerProps={{
              disabled: (day: Date) => {
                return moment(day).isBefore(new Date(), 'day');
              },
            }}
            disabled={disableScheduleStartingFrom}
            buttonProps={{
              style: {
                borderRadius: '6px',
                height: '32px',
                padding: '0px 12px',
                width: '173px',
                justifyContent: 'space-between',
              },
            }}
            onDateSelect={scheduleDateTimeSelect}
            selected={scheduledDateTime}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={isDropdown ? 12 : 6}
          alignItems={'center'}
          direction="row"
          gap="4px"
          display={'flex'}
          flexWrap={'wrap'}
        >
          <Typography
            width={isDropdown ? '100%' : 'max-content'}
            fontWeight={'500'}
            fontSize={'13px'}
            color="#212121"
          >
            Until:
          </Typography>
          <ScheduleDatePickerDropdown
            datePickerProps={{
              disabled: (day: Date) => {
                return moment(day).isBefore(disableDatesFrom, 'day');
              },
            }}
            defaultLabel="Forever"
            buttonProps={{
              style: {
                borderRadius: '6px',
                height: '32px',
                padding: '0px 12px',
                width: '173px',
                justifyContent: 'space-between',
              },
            }}
            onDateSelect={onRecurUntilSelect}
            selected={endDate}
          />
        </Grid>
      </Grid>
      <AdvancedOptions newTask={newTask} updateTask={updateTask} />
      <Typography
        component={'i'}
        fontSize={'13px'}
        fontWeight={'400'}
        color="#424242"
      >
        {repeatText}
      </Typography>
    </Stack>
  );
}
export default RepeatWeekly;
