import styled from '@mui/system/styled';

export const TimeStampList = styled('div')({
  padding: '10px 14px',
  overflowY: 'auto',
  height: 200,
});
export const TimePickerSelectLabel = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  '& .datePickerLabel': {
    fontSize: 13,
    color: '#666584',
    marginBottom: 3,
  },
});
export const TimeStamp = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isSelected' || prop !== 'disabled',
})(({ theme, isSelected, disabled }) => ({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '20px',
  padding: '4px 10px',
  borderRadius: 4,
  pointerEvents: disabled && 'none',
  opacity: disabled ? 0.5 : '',
  background: isSelected && !disabled && '#EBEAFF',
  '&:hover': {
    background: !isSelected && !disabled && '#E0E0E0',
    cursor: 'pointer',
  },
}));
