import { Stack, Checkbox, Typography } from '@mui/material';

const TaskSubmissionAfterDue = ({ onCheck, newTask }) => {
  return (
    <Stack alignItems={'center'} gap="8px" direction={'row'}>
      <Checkbox
        checked={newTask.isTimeBound || false}
        onChange={(e) => onCheck(e.target.checked)}
        sx={{ p: 0, mt: '2px' }}
        size="small"
      />
      <Stack gap="2px">
        <Typography
          fontWeight={'600'}
          fontSize={'13px'}
          color="#212121"
          width={'max-content'}
        >
          Mark task as missed after due-time passes
        </Typography>
      </Stack>
    </Stack>
  );
};

export default TaskSubmissionAfterDue;
