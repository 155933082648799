import { useContext } from 'react';
import { CreateTaskContext } from 'components/AllDialogs/tasks/createTask/context/context';
import DateRangePickerDropdown from 'components/Dropdown/Task/DateRangePicker/TaskDateTimePickerDropdown';

const TaskDateTimePicker = () => {
  const state = useContext(CreateTaskContext);
  const { updateTask, newTask, taskTime, setTaskTime, showRepeatTask } = state;
  const { startTime, dueTime } = newTask;

  const handleStartTimeChange = (value) => {
    setTaskTime({ ...taskTime, startTime: value });
  };
  const handleDueTimeChange = (value) => {
    setTaskTime({ ...taskTime, dueTime: value });
  };
  const onStartDateChange = (date) => {
    updateTask({
      startTime: date,
    });
  };
  const onDueDateChange = (date) => {
    updateTask({
      dueTime: date,
    });
  };
  const onClearStartDate = () => {
    updateTask({
      startTime: '',
    });
    setTaskTime((prevState) => ({ ...prevState, startTime: '' }));
  };
  const onClearDueDate = () => {
    updateTask({
      dueTime: '',
    });
    setTaskTime((prevState) => ({ ...prevState, dueTime: '' }));
  };

  const repeatTaskProps = {
    ...state,
    isDropdown: true,
  };

  return (
    <DateRangePickerDropdown
      selectedStartDate={startTime ? startTime : ''}
      showRepeatTask={showRepeatTask}
      timePickerProps={{
        handleDueTimeChange,
        handleStartTimeChange,
      }}
      selectedDueDate={dueTime ? dueTime : ''}
      onStartDateChange={onStartDateChange}
      onDueDateChange={onDueDateChange}
      onClearDueDate={onClearDueDate}
      onClearStartDate={onClearStartDate}
      taskTime={taskTime}
      repeatTaskProps={repeatTaskProps}
      isCreate={true}
    />
  );
};

export default TaskDateTimePicker;
