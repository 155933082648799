import styled from '@mui/system/styled';
import CustomButton from 'components/Button/CustomButton';

export const DateInputCnt = styled('div', {
  shouldForwardProp: (prop) => prop !== 'activeDate',
})(({ theme, activeDate }) => ({
  display: 'flex',
  padding: '12px 8px 0 8px',
  '& .startDateInput': {
    marginRight: 8,
    marginBottom: 0,
    '& input': {
      paddingLeft: 8,
      paddingRight: 8,
      fontSize: 12,
    },
    '& :hover': {
      cursor: 'pointer',
    },
    ...(activeDate == 'startDate' && {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(104,104, 254, 1)',
        borderWidth: 2,
      },
    }),
  },
  '& .dueDateInput': {
    marginRight: 8,
    marginBottom: 0,
    ...(activeDate == 'dueDate' && {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(104,104, 254, 1)',
        borderWidth: 2,
      },
    }),
    '& input': {
      paddingLeft: 8,
      paddingRight: 8,
      fontSize: 12,
    },
    '& :hover': {
      cursor: 'pointer',
    },
  },
}));
export const TimeBtnsCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  padding: '0 5px',

  '& .MuiButton-root': {
    flex: 1,
    display: 'flex',
    justifyContent: 'start',
    '&:hover': {
      background: 'transparent',
    },
  },
});
export const EndAddormentCnt = styled('div')({
  display: 'flex',
});

export const DateTimeCnt = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isTimeActive',
})(({ theme, isTimeActive }) => ({
  display: 'flex',
  '& .dateTimeInnerCnt': {
    width: isTimeActive ? 372 : '100%',
    maxHeight: 'calc(100vh - 251px)',
    overflowY: 'auto',
  },
}));
export const TimeCnt = styled('div')({
  overflowY: 'auto',
  alignItems: 'flex-start',
  borderLeft: '1px solid #E0E0E0',
  flex: 1,
  '& .timeTypeHeading': {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '20px',
    padding: '17.5px 0',
    textAlign: 'center',
    borderBottom: '1px solid #E0E0E0',
  },
  '& .timeStampList': {
    padding: '10px 14px',
    width: 116,
    textAlign: 'center',
    overflowY: 'auto',
    height: 'calc(100% - 60px)',
    position: 'absolute',
  },
});
export const RepeatTaskCnt = styled('div')({
  padding: '0 8px',
});
export const TimeStamp = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isSelected' || prop !== 'disabled',
})(({ theme, isSelected, disabled }) => ({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '20px',
  padding: '4px 10px',
  borderRadius: 4,
  pointerEvents: disabled && 'none',
  opacity: disabled ? 0.5 : '',
  background: isSelected && !disabled && '#EBEAFF',
  '&:hover': {
    background: !isSelected && disabled && '#E0E0E0',
    cursor: 'pointer',
  },
}));

export const DateRangePickerButton = styled(CustomButton, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})(({ theme, isSelected }) => ({
  padding: isSelected ? '8px 14px' : '8px',
  borderRadius: '10px',
  minWidth: 'auto',
  lineHeight: 'normal',
  '& .MuiButton-startIcon': {
    margin: 0,
    '& svg': {
      fontSize: '18px',
    },
  },
}));
