import { useEffect, useState } from 'react';
import AssigneeDropdown from 'components/Dropdown/AssigneeDropdown/dropdown/dropdown';
import AssigneesView from 'components/AssigneesView/AssigneesView';
import useWorkspaceConfig from 'utils/CustomHooks/useWorkspaceConfig';
import { useAssigneesUtils } from 'utils/CustomHooks/useAssigneesUtil';
import useUsers from 'utils/CustomHooks/useUsers';
import { PERMISSIONS } from 'utils/constants';
import { Box, Stack, Typography } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import UsersAndTeamsIcon from 'components/Icons/sidebarIcons/usersAndTeamsIcon';

const AssignToUsersAndTeams = (props) => {
  const { getBacklistUsersByPermission } = useUsers();
  const { config } = useWorkspaceConfig();
  const { handleChangeCallback, currentSelected } = props;

  const { addNewAssignees, splitAssigneesByIds } = useAssigneesUtils();

  const [taskAssignees, setTaskAssignees] = useState([]);

  const [dropdownConfig, setDropdownConfig] = useState({
    people: true,
    teams: true,
    locations: false,
  });

  useEffect(() => {
    setDropdownConfig({
      ...dropdownConfig,
      locations: config?.advancedLocationBasedAssignment ?? false,
    });
  }, [config]);

  const [selected, setSelected] = useState({
    ...(dropdownConfig?.people && { people: [] }),
    ...(dropdownConfig?.teams && { teams: [] }),
    ...(dropdownConfig?.locations && { locations: [] }),
  });

  const handleSelect = (data: any) => {
    const mergedArray = [
      ...(data?.selectedList?.people ?? []),
      ...(data?.selectedList?.teams ?? []),
      ...(data?.selectedList?.locations ?? []),
    ];

    const updatedAssignees = addNewAssignees(mergedArray);

    setTaskAssignees(updatedAssignees);

    setSelected(data?.selectedList);
    handleChangeCallback?.(mergedArray);
  };

  useEffect(() => {
    const splittedAssignees = splitAssigneesByIds(currentSelected ?? [], {
      people: true,
      teams: true,
      locations: dropdownConfig?.locations ?? false,
    });

    const mergedArray = [
      ...(splittedAssignees?.people ?? []),
      ...(splittedAssignees?.teams ?? []),
      ...(splittedAssignees?.locations ?? []),
    ];

    const updatedAssignees = addNewAssignees(mergedArray);
    setTaskAssignees(updatedAssignees);

    setSelected(splittedAssignees);
  }, [currentSelected, dropdownConfig]);

  return (
    <AssigneeDropdown
      buttonRenderer={
        <CustomButton
          variant="outlined"
          buttonType="grayWhite"
          endIcon={
            <Box className="endIconCnt">
              <KeyboardArrowDownIcon
                style={{ fontSize: 24, color: '#00000099' }}
              />
            </Box>
          }
          style={{
            width: '100%',
            justifyContent: 'space-between',
            padding: '6px 17px 6px 10px',
            height: 44,
            borderRadius: 6,
          }}
        >
          <Stack direction="row" gap="10px" alignItems="center">
            <UsersAndTeamsIcon
              sx={{
                fill: 'none',
                height: 18,
                width: 18,
                '& path': {
                  stroke: '#323131',
                },
              }}
            />

            {taskAssignees?.length >= 1 ? (
              <Stack direction="row" alignItems="center">
                <AssigneesView
                  type="chips"
                  assigneesProps={{
                    clearOption: false,
                  }}
                  data={
                    taskAssignees?.length > 4
                      ? taskAssignees?.slice(0, 4)
                      : taskAssignees
                  }
                />

                {taskAssignees?.length > 4 && (
                  <Box
                    style={{
                      borderRadius: '50%',
                      padding: '3px',
                      border: '1px solid lightGray',
                      marginLeft: 3,
                      width: 30,
                    }}
                  >
                    + {`${taskAssignees?.length - 4}`}
                  </Box>
                )}
              </Stack>
            ) : (
              <Typography className="placeholder">
                Select people, teams or locations
              </Typography>
            )}
          </Stack>
        </CustomButton>
      }
      handleChangeCallback={handleSelect}
      showTabs={true}
      listProps={{
        all: {
          showInlineSelection: false,
          isSearchable: true,
          isMulti: true,
          hideActions: false,
          placeholder: 'Type to Search',
          showMeOnTop: true,
          partition: true,
          selected: [
            ...(selected?.people ?? []),
            ...(selected?.teams ?? []),
            ...(selected?.locations ?? []),
          ],
        },
        people: {
          showMeOnTop: true,
          showInlineSelection: false,
          isSearchable: true,
          isMulti: true,
          hideActions: false,
          partition: true,
          selected: selected?.people ?? [],
        },
        teams: {
          showInlineSelection: false,
          isSearchable: true,
          isMulti: true,
          hideActions: false,
          selected: selected?.teams ?? [],
        },
        ...(dropdownConfig?.locations && {
          locations: {
            showInlineSelection: false,
            isSearchable: true,
            isMulti: true,
            hideActions: false,
            selected: selected?.locations ?? [],
            addLocationByTag: true,
            selectOnly: true,
          },
        }),
      }}
      displayOptions={{
        all: true,
        ...dropdownConfig,
      }}
      blacklistedIds={getBacklistUsersByPermission(
        PERMISSIONS.CAN_CHANGE_TASK_STATUS,
        true,
      )}
      popperProps={{
        disablePortal: false,
        style: {
          zIndex: 1300,
          width: 605,
        },
      }}
      allSelected={false}
    />
  );
};

export default AssignToUsersAndTeams;
