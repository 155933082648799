import { RepeatTaskCnt } from 'components/RepeatTask/repeatTask.style';
import RepeatMonthly from 'components/RepeatTask/Monthly/monthly.cmp';
import RadioButton from './components/RadioButton';
import { Stack } from '@mui/material';
import OneOff from './OneOff';
import RepeatDaily from 'components/RepeatTask/Daily/daily.cmp';
import RepeatWeekly from 'components/RepeatTask/Weekly/weekly.cmp';

function RepeatTask({
  onRepeatTypeChange,
  onDaysSelect,
  onWeekSelect,
  onRecurUntilSelect,
  onMonthSelect,
  onMonthDaySelect,
  repeatOneOffSelectedValues,
  repeatDailySelectedValues,
  repeatWeeklySelectedValues,
  repeatMonthlySelectedValues,
  selectedRepeatType,
  onStartDateSelect,
  onDueTimeSelect,
  onStartTimeSelect,
  resetTimes,
  scheduleDateTimeSelect,
  isDropdown = false,
  newTask,
  updateTask,
  recurType,
  updateWatchers,
  hiddenButtons,
}) {
  const handleChange = (newValue: string) => {
    onRepeatTypeChange(newValue);
    // resetTimes();
    if (newValue === 'weekly') {
      repeatMonthlySelectedValues.setIsMonthTimeEdit({
        startTime: false,
        endTime: false,
      });
    }
    if (newValue === 'monthly') {
      repeatWeeklySelectedValues.setIsWeekTimeEdit({
        startTime: false,
        endTime: false,
      });
    }
  };
  return (
    <>
      <RepeatTaskCnt>
        <Stack gap="4px" mb="8px">
          {!hiddenButtons.some((h) => h === 'oneOff') && (
            <RadioButton
              type="oneOff"
              label="One-Off"
              checked={recurType === 'oneOff'}
              onChange={(type) => handleChange(type)}
            >
              <OneOff
                isDropdown={isDropdown}
                updateTask={updateTask}
                onDueTimeSelect={onDueTimeSelect}
                onStartTimeSelect={onStartTimeSelect}
                newTask={newTask}
              />
            </RadioButton>
          )}
          <RadioButton
            type="daily"
            label="Daily"
            checked={recurType === 'daily'}
            onChange={(type) => handleChange(type)}
          >
            <RepeatDaily
              onDaysSelect={onDaysSelect}
              onWeekSelect={onWeekSelect}
              onRecurUntilSelect={onRecurUntilSelect}
              repeatDailySelectedValues={repeatDailySelectedValues}
              onStartDateSelect={onStartDateSelect}
              onStartTimeSelect={onStartTimeSelect}
              onDueTimeSelect={onDueTimeSelect}
              scheduleDateTimeSelect={scheduleDateTimeSelect}
              isDropdown={isDropdown}
              newTask={newTask}
              updateTask={updateTask}
            />
          </RadioButton>
          <RadioButton
            type="weekly"
            label="Weekly"
            checked={recurType === 'weekly'}
            onChange={(type) => handleChange(type)}
          >
            <RepeatWeekly
              onDaysSelect={onDaysSelect}
              onWeekSelect={onWeekSelect}
              onRecurUntilSelect={onRecurUntilSelect}
              repeatWeeklySelectedValues={repeatWeeklySelectedValues}
              onStartDateSelect={onStartDateSelect}
              onStartTimeSelect={onStartTimeSelect}
              onDueTimeSelect={onDueTimeSelect}
              scheduleDateTimeSelect={scheduleDateTimeSelect}
              isDropdown={isDropdown}
              newTask={newTask}
              updateTask={updateTask}
            />
          </RadioButton>
          <RadioButton
            type="monthly"
            label="Monthly"
            checked={recurType === 'monthly'}
            onChange={(type) => handleChange(type)}
          >
            <RepeatMonthly
              onDaysSelect={onMonthDaySelect}
              onMonthSelect={onMonthSelect}
              onRecurUntilSelect={onRecurUntilSelect}
              repeatMonthlySelectedValues={repeatMonthlySelectedValues}
              onStartDateSelect={onStartDateSelect}
              onStartTimeSelect={onStartTimeSelect}
              onDueTimeSelect={onDueTimeSelect}
              scheduleDateTimeSelect={scheduleDateTimeSelect}
              newTask={newTask}
              updateTask={updateTask}
              isDropdown={isDropdown}
            />
          </RadioButton>
        </Stack>
      </RepeatTaskCnt>
    </>
  );
}

export default RepeatTask;
