// React
import { Fragment } from 'react';

// React Router
import { useLocation } from 'react-router-dom';

// Custom components
import SubItem from './SubItem';

// Icons
import SubmissionReportPieCharIcon from 'components/Icons/submissionReportPieCharIcon';
import TablesIcon from 'components/Icons/tablesIcon';

// Hooks
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import ProjectIcon from 'components/Icons/projectIcon';
import ScheduledIcon from 'components/Icons/sidebarIcons/scheduledIcon';
import AgendaViewIcon from 'components/Icons/agendaViewIcon';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { PERMISSIONS } from 'utils/constants';
import { HasPermission } from 'components/HasPermission';

interface SubItemsListPropTypes {
  reportId: any;
  onClickItemCallback?: () => void;
}

// Schedule Report
const SubItemsList = ({
  reportId,
  onClickItemCallback,
}: SubItemsListPropTypes) => {
  const currentPath = useLocation().pathname;
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const userProfile: any = useSelector(selectors.getUserProfile);

  const handleClickSubItem = (
    itemType: 'agendaView' | 'allSchedules' | 'projects',
  ) => {
    switch (itemType) {
      case 'agendaView':
        navigateWithWorkspaceUrl('/agenda-view');
        break;
      case 'allSchedules':
        navigateWithWorkspaceUrl('/scheduled-work');
        break;
      case 'projects':
        navigateWithWorkspaceUrl('/projects');
        break;
      default:
        break;
    }
    onClickItemCallback?.();
  };

  return (
    <Fragment>
      <SubItem
        title="Agenda"
        isSelected={currentPath.includes('/agenda-view')}
        icon={<AgendaViewIcon filled={currentPath.includes('/agenda-view')} />}
        onClick={() => handleClickSubItem('agendaView')}
      />
      <SubItem
        title="Schedules"
        isSelected={
          currentPath.includes('/scheduled-work') ||
          currentPath.includes('/schedule-details')
        }
        icon={
          <ScheduledIcon
            filled={
              currentPath.includes('/scheduled-work') ||
              currentPath.includes('/schedule-details')
            }
          />
        }
        onClick={() => handleClickSubItem('allSchedules')}
      />

      <SubItem
        title="Projects"
        isSelected={currentPath.includes('/projects')}
        icon={<ProjectIcon filled={currentPath.includes('/projects')} />}
        onClick={() => {
          handleClickSubItem('projects');
        }}
      />
    </Fragment>
  );
};

export default SubItemsList;
