// React
import { useState, useMemo, useEffect } from 'react';

// Custom components
import Header from 'components/TemplateDashboard/SubmissionTemplatePreview/Header';
import TemplateSubmissionPreview from 'components/TemplateSubmissionPreview';

// Styled
import { Column, PreviewWrapper } from './styled';

// Types
import { SubmissionReportType } from 'pages/TemplateDashboard/Tabs/Submissions/context';
import { LogFilterTypes } from 'components/TemplateDashboard/SubmissionTemplatePreview/types';

interface ActiveSubmissionLogPropTypes {
  activeSubmission: SubmissionReportType;
  template?: any;
  handleArchiveLogs?: (logs: any[]) => void;
}

const ActiveSubmissionLog = ({
  activeSubmission,
  template,
  handleArchiveLogs,
}: ActiveSubmissionLogPropTypes) => {
  const [activeSubmissionItemsFiltered, setActiveSubmissionItemsFiltered] =
    useState<SubmissionReportType | undefined>(undefined);
  const [activeSubmissionItemsFilter, setActiveSubmissionItemsFilter] =
    useState<LogFilterTypes>('allItems');

  const handleFilterSubmissionLogItems = (
    filterName: 'allItems' | 'passed' | 'failed' | 'remaining' | 'completed',
  ) => {
    const active = activeSubmission ? { ...activeSubmission } : null;
    let newState: SubmissionReportType | undefined = undefined;
    if (active) {
      setActiveSubmissionItemsFilter(filterName);
      switch (filterName) {
        case 'allItems':
          setActiveSubmissionItemsFiltered(undefined);
          break;
        case 'passed':
          newState = {
            ...active,
            TaskChecklistItems: active.TaskChecklistItems.filter(
              (item) =>
                (!!item.answers &&
                  ((item.type === 'passFail' &&
                    item.answers.value === 'pass') ||
                    (item.type !== 'passFail' && !!item.answers.value))) ||
                item.type === 'header',
            ),
          };
          break;
        case 'failed':
          newState = {
            ...active,
            TaskChecklistItems: active.TaskChecklistItems.filter(
              (item) =>
                (!!item.answers &&
                  ((item.type === 'passFail' &&
                    item.answers.value === 'fail') ||
                    (item.type !== 'passFail' && !item.answers.value))) ||
                item.type === 'header',
            ),
          };
          break;
        case 'remaining':
          newState = {
            ...active,
            TaskChecklistItems: active.TaskChecklistItems.filter(
              (item) => !item.answers || item.type === 'header',
            ),
          };
          break;
        case 'completed':
          newState = {
            ...active,
            TaskChecklistItems: active.TaskChecklistItems.filter(
              (item) => !!item.answers || item.type === 'header',
            ),
          };
          break;
        default:
          break;
      }
      setActiveSubmissionItemsFiltered(newState);
    }
  };

  const currentSubmission = useMemo(
    () => activeSubmissionItemsFiltered ?? activeSubmission,
    [
      activeSubmissionItemsFilter,
      activeSubmissionItemsFiltered,
      activeSubmission,
    ],
  );

  useEffect(() => {
    handleFilterSubmissionLogItems(activeSubmissionItemsFilter);
  }, [activeSubmission]);

  return (
    <Column>
      <Header
        template={template}
        handleArchiveLogs={handleArchiveLogs}
        activeSubmission={currentSubmission}
      />
      <PreviewWrapper>
        {currentSubmission?.id && (
          <TemplateSubmissionPreview
            activeSubmission={currentSubmission}
            handleFilterSubmissionLogItems={handleFilterSubmissionLogItems}
            activeSubmissionItemsFilter={activeSubmissionItemsFilter}
            log={currentSubmission}
          />
        )}
      </PreviewWrapper>
    </Column>
  );
};

export default ActiveSubmissionLog;
