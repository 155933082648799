import { Stack, Typography, Radio } from '@mui/material';
import {
  CREATE_SEPARATE_TASK_SITE_WISE,
  CREATE_SEPARATE_TASK_USER_WISE,
} from './utils';

const AssigneeRoleConfigurations = (props) => {
  const { currentSelected, handleChangeCallback } = props;

  const creationTypeHandler = (type) => {
    handleChangeCallback(type);
  };

  return (
    <Stack
      direction={'column'}
      alignItems={'start'}
      mt="12px"
      borderRadius="6px"
      padding="12px 0px 12px 10px"
      sx={{
        backgroundColor: '#F5F5F5',
        gap: '4px',
      }}
    >
      <Stack direction={'row'} alignItems={'center'} gap="8px">
        <Radio
          sx={{ padding: '0px' }}
          onChange={() => creationTypeHandler(CREATE_SEPARATE_TASK_SITE_WISE)}
          checked={currentSelected === CREATE_SEPARATE_TASK_SITE_WISE}
        />
        <Typography
          fontSize="14px"
          fontWeight="500"
          color="#000000"
          lineHeight="19.12px"
        >
          Create one task per location for the role assigned
        </Typography>
      </Stack>
      <Stack direction={'row'} alignItems={'center'} gap="8px">
        <Radio
          sx={{ padding: '0px' }}
          onChange={() => creationTypeHandler(CREATE_SEPARATE_TASK_USER_WISE)}
          checked={currentSelected === CREATE_SEPARATE_TASK_USER_WISE}
        />
        <Typography
          fontSize="14px"
          fontWeight="500"
          color="#000000"
          lineHeight="19.12px"
        >
          Create individual tasks for each user in the role
        </Typography>
      </Stack>
    </Stack>
  );
};

export default AssigneeRoleConfigurations;
