import React, { useMemo } from 'react';

// Custom components
import UserAvatar from 'components/Avatar';
import CustomToolTip from 'components/Tooltip/tooltip';
import ActionButtons from 'components/TemplateDashboard/SubmissionTemplatePreview/ActionButtons';

// Styles
import {
  Wrapper,
  MetaWrapper,
  AvatarAndDropdown,
  AvatarAndName,
  SubmitterNameAndEmail,
  SubmitterName,
  SubmitterEmail,
  DateAndIdWrapper,
  DateWrapper,
  DateDot,
  GreyText,
  Divider as CustomDivider,
  WorkOrderId,
  ReportStatusWrapper,
  ReportAndCreateWOCnt,
  StatusDot,
  CreateWorkOrder,
  AnonymousAvatar,
  AvatarWrapper,
} from './styled';
import Divider from '@mui/material/Divider';

// Icons
import GlobeIcon from 'components/Icons/globeIcon';

// Hooks
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import useDateTime from 'utils/CustomHooks/useDateTime';

// Utilities
import { isEqual, template } from 'lodash';

// Types
import { TemplateType } from 'pages/TemplateDashboard/context';
import { Stack, Typography } from '@mui/material';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import { getLogDuration } from 'utils/templates';

interface HeaderPropTypes {
  activeSubmission: any;
  template?: TemplateType;
  handleArchiveLogs?: (logs: any[]) => void;
}

const Header = ({
  activeSubmission,
  template,
  handleArchiveLogs,
}: HeaderPropTypes) => {
  const submission = activeSubmission;
  const { score } = submission || {};
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  const { getUtcToTzTime, getDateInTz } = useDateTime();

  const statusColorMap = {
    'In Progress': 'rgba(245, 133, 0, 1)',
    Submitted: 'rgba(4, 184, 108, 1)',
    Incomplete: 'rgba(4, 184, 108, 1)',
  };

  const handleClickTaskId = () => {
    navigateWithWorkspaceUrl(`/tasks/?taskId=${submission?.TaskId}`);
  };

  const duration = useMemo(() => {
    return getLogDuration(submission);
  }, [submission]);

  return (
    <Stack>
      <Wrapper
        bgcolor={
          activeSubmission?.status === 'Submitted' ? '#EAFAEB' : '#FDF4E9'
        }
      >
        <MetaWrapper>
          <AvatarAndDropdown>
            <AvatarAndName>
              {submission?.Updater ? (
                <AvatarWrapper>
                  <UserAvatar
                    width={24}
                    height={24}
                    userId={submission?.Updater?.id}
                  />
                </AvatarWrapper>
              ) : (
                <CustomToolTip title="Submitted via Public Link">
                  <AvatarWrapper>
                    <AnonymousAvatar>
                      <GlobeIcon style={{ color: '#fff', fontSize: '16px' }} />
                    </AnonymousAvatar>
                  </AvatarWrapper>
                </CustomToolTip>
              )}
              <SubmitterNameAndEmail>
                <SubmitterName>{`${
                  submission?.Updater
                    ? `${submission?.Updater.firstName} ${submission?.Updater.lastName}`
                    : submission?.submitterName ?? 'Anonymous'
                }`}</SubmitterName>
                {submission?.submitterEmail && (
                  <SubmitterEmail>{submission.submitterEmail}</SubmitterEmail>
                )}
              </SubmitterNameAndEmail>
            </AvatarAndName>
            <ActionButtons
              template={template}
              activeSubmission={activeSubmission}
              handleArchiveLogs={handleArchiveLogs}
              taskId={submission?.TaskId}
            />
          </AvatarAndDropdown>
          <DateAndIdWrapper>
            <DateWrapper>
              <GreyText>{`${getDateInTz(
                submission?.lastItemUpdatedAt as string,
                'MMM DD YYYY',
              )}`}</GreyText>
              <DateDot />
              <GreyText>{`${getUtcToTzTime(
                submission?.status === 'Submitted'
                  ? submission?.updatedAt
                  : (submission?.lastItemUpdatedAt as string),
              )}`}</GreyText>
            </DateWrapper>
            {!!submission?.TaskId && (
              <>
                <CustomDivider />
                <WorkOrderId onClick={handleClickTaskId}>
                  Work Order ID: {submission.Task?.taskNumber}
                </WorkOrderId>
              </>
            )}
          </DateAndIdWrapper>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Stack direction="row" alignItems="center" ml="32px">
              <StatusDot
                color={
                  submission?.status ? statusColorMap[submission?.status] : ''
                }
              />
              <Typography sx={{ mr: '4px' }} fontSize="14px" fontWeight={600}>
                {submission?.status === 'Incomplete'
                  ? 'Submitted'
                  : submission?.status}
              </Typography>
              <Typography color="#6868FE" fontSize="14px" fontWeight={600}>
                {`(${submission?.progress?.completedCount} of ${submission?.progress?.count} items)`}
              </Typography>
            </Stack>
            {template?.isScoring && score && (
              <Divider orientation="vertical" variant="middle" flexItem />
            )}
            {template?.isScoring && score && (
              <Stack direction="row" alignItems="center" spacing={1}>
                <StatusDot color="#6868FE" />
                <Typography fontSize="14px" fontWeight={600}>
                  Score: {score.percentage}%
                </Typography>
                <Typography color="#6868FE" fontSize="14px" fontWeight={600}>
                  ({score.earned} of {score.total} pts)
                </Typography>
              </Stack>
            )}
            {duration && (
              <Divider orientation="vertical" variant="middle" flexItem />
            )}
            {duration && (
              <Stack direction={'row'} alignItems={'center'} gap="8px">
                <AccessTimeRoundedIcon
                  sx={{ color: 'rgba(66, 66, 66, 1)', fontSize: '20px' }}
                />
                <Typography fontSize="14px" fontWeight={600} color="#212121">
                  Duration: {duration}
                </Typography>
              </Stack>
            )}
          </Stack>
        </MetaWrapper>
      </Wrapper>
      <Divider sx={{ p: '8px 0px' }} />
    </Stack>
  );
};

const arePropsEqual = (prevProps, nextProps) => isEqual(prevProps, nextProps);

export default React.memo(Header, arePropsEqual);
