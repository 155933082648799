import React, { useContext } from 'react';
import { CreateTaskContext } from 'components/AllDialogs/tasks/createTask/context/context';
import {
  ShowMoreLessCnt,
  ShowMoreLessText,
} from 'components/AllDialogs/tasks/createTask/createTask.style';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const ShowMoreLess = () => {
  const { showLess, setShowLess } = useContext(CreateTaskContext);
  const handleShowMoreLess = (e) => {
    setShowLess(!showLess);
  };
  return (
    <ShowMoreLessCnt onClick={handleShowMoreLess}>
      <ShowMoreLessText>
        {showLess ? 'Show more' : 'Show less'}
      </ShowMoreLessText>
      {showLess ? <ArrowDownIcon /> : <ArrowUpIcon />}
    </ShowMoreLessCnt>
  );
};

export default ShowMoreLess;
